import dbConnection from '../dbConnection'
import * as dayjs from 'dayjs'

const journal = {
  getJournalDay: function (date) {
    return dbConnection.get('members/journal/day/' + date)
  },
  saveNewJournalTemplate: function (obj) {
    return dbConnection.post('members/journal/saveTemplate', {
      ...obj
    })
  },
  saveJournalDay: function (items, date) {
    return dbConnection.post('members/journal/save', {
      items,
      date
    })
  },
  hideJournalTemplate: function (id) {
    return dbConnection.post('members/journal/hideTemplate', {
      id
    })
  },
  removeEntry: function (id) {
    return dbConnection.post('members/journal/removeEntry', {
      id
    })
  },
  getStats: function (templateId, type) {
    return dbConnection.get(`members/journal/stats/${templateId}?type=${type}`)
  },
  filterDays: function (templateId) {
    return dbConnection.get('members/journal/filterDays/' + templateId)
  },
  saveQuickEntry: function (obj) {
    return dbConnection.post('members/journal/save', {
      items: [obj],
      date: dayjs().format('YYYY-MM-DD')
    })
  },
  getFilledDates: function (startDate, endDate) {
    return dbConnection.post('members/journal/getFilledDates', {
      start: startDate, end: endDate
    })
  }
}
export default journal
