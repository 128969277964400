<template>
  <div class="modal-content">
    <vue-element-loading
      :active="isLoading"
      spinner="line-scale"
      color="#349beb"
    />
    <div class="card">
      <div class="smallhero" style="padding-top: 1px; margin-bottom: 10px; margin-top: 5px;">
        <h4 class="title is-4">
          {{ item.title }} <small class="headerSmall">Tips</small>
        </h4>
      </div>
      <div style="clear: both">
        <transition-group name="fade" tag="div" class=" ">
          <HowToSingleSolutionRow
            v-for="problem in problems"
            :key="problem.id"
            :item="problem"
            class=" "
            @refresh="loadData"
          />
        </transition-group>
        <span
          class="is-size-6 has-text-info has-text-weight-medium cursorPointer"
          style="padding-left: 5px"
          @click="toggleNewProblemForm"
          >Suggest new problem/issue</span
        >
        <div
          v-if="showNewForm"
          class="newFormStyle"
          style="max-width: 400px; margin: 10px auto"
        >
          <van-field
            v-model="newProblem.title"
            label="Problem/ Issue"
            placeholder="Problem/Issue eg. Broken strap.."
          />
          <div class="van-cell van-field">
            <div class="van-cell__title van-field__label">
              <span>Image (optional)</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <Uploader :max-images="1" @uploaded="uploadedFile" />
              </div>
            </div>
          </div>
          <b-button type="is-primary is-light" @click="saveProblem">Submit</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import HowToSingleSolutionRow from '@/components/HowToSingleSolutionRow'
import Uploader from '@/components/Uploader'
export default {
  name: 'HowToModal',
  components: {
    HowToSingleSolutionRow,
    Uploader,
  },
  props: {
    item: Object,
    // id: Number
  },
  data() {
    return {
      files: [],
      showNewForm: false,
      newProblem: {},
      problems: [],
      isLoading: false,
      // rawHtml: '<span>This is <b>fgd</b>a delfi</span>'
    }
  },
  computed: {
    user_id: function () {
      return 5
    },
    token: function () {
      return 'test'
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      ApiService.howto.getSingleHowto(this.item.id).then((resp) => {
        this.problems = resp.data
      })
    },
    toggleNewProblemForm() {
      this.showNewForm = !this.showNewForm
    },
    uploadedFile(file) {
      // only single image is allowed
      if (file) {
        this.newProblem.attachment_id = file.id
      }
    },
    saveProblem() {
      this.newProblem.howto_id = this.item.id
      ApiService.howto.saveProblem(this.newProblem).then(() => {
        this.newProblem = {};
        this.loadData()
        this.toggleNewProblemForm()
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.animation-content {
  width: 100% !important;
}
div.modal-content {
  margin: 0;
}
div.content {
  padding: 5px;
}
div.card {
  min-height: 90vh;
  padding: 4px;
}
h1 {
  padding-top: 10px;
  padding-left: 5px;
}
#youtubeWrapper {
  min-height: 300px;
  z-index: 9999;
}
@media screen and (max-width: 668px) {
  h1.title {
    min-height: 40px;
  }
}
.habitText {
  padding: 3px;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 13px;
  color: #3e3e3e;
}
button.noborder {
  margin-right: 4px;
  padding: 3px 7px;
  span.fa-circle {
    font-size: 10px;
  }
  i {
    color: #bbb;
  }
}
div.activeForm {
  margin-top: 10px;
}
</style>
