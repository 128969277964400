<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4">Bookmarks</h4>
    </div>
    <section class="section is-fixed-top page">
      <transition-group
        name="list"
        tag="div"
        class="columns is-centered is-multiline contentBlocks"
      >
        <Tile
          v-for="item in items"
          :key="item.id"
          :item="item"
          class="column is-half"
          @click.native="$parent.openModal(item)"
        />
      </transition-group>
    </section>
    <p v-if="items.length === 0">You have no bookmarks</p>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'
import Tile from '@/components/tiles/Tile'
export default {
  name: 'Bookmarks',
  components: {
    Tile
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getBookmarks()
  },
  methods: {
    getBookmarks() {
      ApiService.bookmarks.getBookmarks().then(resp => {
        let items = resp.data.slice(0, 20)
        items = updatePosts(items)
        this.items = items;
      })
    }
  }
}
</script>
<style scoped>
</style>
