<template>
  <div
    class="modal-cont"
    style="
      width: 100%;
      overflow-x: hidden;
      min-height: 50vh;
      max-height: 100vh;
      overflow: hidden;
    "
  >
    <div class="hero">
      <h1
        class="title is-size-5"
        style="padding-left: 5px; padding-right: 25px"
      >
        Food tracking
      </h1>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="card" style="overflow: hidden">
            <div
              class="card-contentd"
              style="overflow: scroll; height: calc(100vh - 120px)"
            >
              <h4 class="title is-size-6">Todays food</h4>
              <div class="has-background-info-light" style="padding: 10px">
                <b-button
                  v-if="editing === false"
                  class="is-info is-light"
                  @click="editing = true"
                  icon-left="f fas fa-pen"
                  style="float: right"
                >
                  Edit
                </b-button>
                <b-button
                  v-if="editing === true"
                  class="is-success is-light"
                  @click="editing = false"
                  icon-left="f fas fa-save"
                  style="float: right"
                >
                  Save
                </b-button>
                <b-field
                  label="Daily Kcal Budget"
                  style="padding: 0 20px; text-align: left"
                >
                  <b-input
                    v-if="editing === true"
                    v-model="budget"
                    placeholder="Kcal budget"
                    @change.native="onKcalBudgetChange"
                    required
                    type="number"
                    maxlength="4"
                    size="5"
                    style="width: 200px"
                  ></b-input>
                  <span
                    class="is-size-7 has-text-danger"
                    v-if="
                      this.tempSectionsBudget != 0 &&
                      this.tempSectionsBudget < this.budget
                    "
                    >Sum of sections ({{ this.tempSectionsBudget }}) smaller
                    than the budget of {{ this.budget }}</span
                  >
                  <span
                    class="is-size-7 has-text-danger"
                    v-if="
                      this.tempSectionsBudget != 0 &&
                      this.tempSectionsBudget > this.budget
                    "
                    >Sum of sections ({{ this.tempSectionsBudget }}) bigger than
                    the budget {{ this.budget }}</span
                  >
                  <span class="is-size-5" v-if="editing === false"
                    >{{ budget }} kcal</span
                  >
                </b-field>
              </div>

              <div style="">
                <div
                  class="split"
                  v-for="(item, index) in foodBlocks"
                  :key="index"
                >
                  <div v-if="editing === false">
                    {{ item.t }}
                    <span class="kcal" title="Used kcal"
                      >{{ item.kcalUsed }} kcal</span
                    >
                    <span class="kcal">/ {{ item.kcal }} kcal</span>
                    <div class="card-content">
                      <div v-for="(food, ind) in item.items" :key="ind">
                        {food.name}
                      </div>
                      <button
                        class="button is-info is-light noIconMarginTop"
                        @click="showFoodTab(index)"
                      >
                        <i class="far fa-plus"></i> Add Food items
                      </button>
                    </div>
                  </div>
                  <div class="columns editingInputs" v-if="editing === true">
                    <b-field label="Name" style="padding: 0 20px">
                      <b-input
                        v-model="item.t"
                        placeholder="name (example: lunch...)"
                        required
                        maxlength="20"
                        size="20"
                        style="width: 200px"
                      ></b-input>
                    </b-field>
                    <b-field label="Kcal Goal" style="padding: 0 20px">
                      <b-input
                        v-model="item.kcal"
                        placeholder="Kcal goal"
                        required
                        type="number"
                        @change.native="onKcalBudgetChange"
                        maxlength="4"
                        size="5"
                        style="width: 200px"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="split" v-if="editing === true">
                  <div class="card-content">
                    <b-button
                      class="is-default is-light is-small"
                      icon-left="f far fa-plus"
                      @click="addFoodBlock"
                    >
                      Add another section
                    </b-button>
                  </div>
                </div>
              </div>
              <div id="searchFood" :class="{ active: show === 'addFood' }">
                <b-tabs
                  id="nopaddingtab"
                  v-model="activeTab"
                  class="titleTabs"
                  position="is-centered"
                  animation="fade"
                >
                  <b-tab-item icon="f fas fa-arrow-left"> </b-tab-item>
                  <b-tab-item icon="f fal fa-utensils" label="Search">
                    <div style="padding: 0 20px">
                      <b-field
                        label="Search for food item"
                        label-position="on-border"
                        type="is-default"
                      >
                        <b-input
                          type="text"
                          v-model="searchFor"
                          placeholder="Search for food item"
                          maxlength="40"
                        >
                        </b-input>
                        <b-button
                          class="is-primary is-light"
                          :loading="isLoadingResults"
                          @click="searchForFood"
                        >
                          <i class="fas fa-search"></i> Search
                        </b-button>
                      </b-field>
                      <ul class="resultList">
                        <li
                          v-for="(food, index) in results"
                          :key="index"
                          @click="selectFoodItem('search', food)"
                        >
                          <figure style="float: left; padding: 8px 8px 8px 0">
                            <img src="/fastfood_29.png" style="height: 30px" />
                          </figure>
                          {{ food.food.label }}
                          <div class="info">
                            <span
                              >{{
                                Math.round(food.food.nutrients.ENERC_KCAL)
                              }}
                              kcal</span
                            >
                            <span
                              >{{
                                Math.round(food.food.nutrients.CHOCDF)
                              }}
                              carbs</span
                            >
                            <span
                              >{{
                                Math.round(food.food.nutrients.FAT)
                              }}
                              fat</span
                            >
                            <span
                              >{{
                                Math.round(food.food.nutrients.PROCNT)
                              }}
                              protein</span
                            >
                            <span
                              >{{
                                Math.round(food.food.nutrients.FIBTG)
                              }}
                              fiber</span
                            >
                            <span class="foodSize"
                              >{{ food.measures[0].label }}
                              {{ Math.round(food.measures[0].weight) }}g</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </b-tab-item>
                  <b-tab-item
                    icon="f fal fa-shopping-basket"
                    label="My foods"
                  ></b-tab-item>
                  <b-tab-item icon="f fal fa-barcode" label="Scan">
                    <StreamBarcodeReader
                      :key="'t2'"
                      v-if="activeTab === 3"
                      @decode="onDecode"
                      @loaded="onLoaded"
                      style="margin-left: 0px"
                    ></StreamBarcodeReader>
                  </b-tab-item>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-hidden-mobile">
          <div class="card" style="padding-bottom: 50px">
            <h4 class="title is-size-6 fontWeightLight">Stats</h4>
            <vc-donut
              background="white"
              foreground="#f1f1f1"
              :size="150"
              unit="px"
              :thickness="15"
              has-legend
              legend-placement="top"
              :sections="sections"
              :total="budget"
              :start-angle="0"
              :auto-adjust-text-size="true"
            >
              <h1>Budget {{ budget }}<br />Used {{ usedBudget || 0 }} kcal</h1>
            </vc-donut>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <div>
        <h3 class="smallHero title is-size-5">
          {{ selectedFoodItem.food && selectedFoodItem.food.label }}
        </h3>
        sdfsdf<br />sdfsdf<br />sdfsdfsa<br />
      </div>
      <hr />
      <van-picker
        title=""
        v-model="pickerSelection"
        show-toolbar
        item-height="33"
        :columns="pickerColumns"
        @change="onChange"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup
      v-model="showActionsPopup"
      closeable
      :overlay="true"
      close-icon="f fas fa-times"
      position="bottom"
      get-container="body"
      :style="{ height: '100px', padding: '10px', 'text-align': 'center' }"
    >
      <circular-button
        title="Add to My Habits"
        icon="far fa-grin"
        background="#FFA500"
        color="#f9f9f9"
        @click.native="action2('add_to_habits')"
      />
      <circular-button
        style="margin-left: 100px"
        title="Report Habit"
        icon="fas fa-times"
        background="#f9f9f9"
        color="#ffadad"
        @click.native="action2('report_habit')"
      />
    </van-popup>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  name: 'FoodTracking',
  components: { StreamBarcodeReader },
  props: {
    id: Number,
    title: String,
    content: String,
    options: Object,
  },
  data() {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    for (let index = 3; index < 200; index++) {
      numbers.push(index * 5)
    }

    return {
      activeTab: 1,
      show: '',
      showHabitForm: '',
      showActionsPopup: false,
      showPicker: false,
      showContent: false,
      editing: false,
      action: '',
      isLoadingResults: false,
      searchFor: '',
      budget: 2000,
      tempSectionsBudget: 0,
      usedBudget: 0,
      selectedFoodItem: {},
      pickerSelection: null,
      results: [],
      sections: [{ label: 'kcal used today', value: 0, color: '#ffbc00' }],
      pickerColumns: [
        { values: numbers, defaultIndex: 0 },
        { values: ['serving', 'grams'], defaultIndex: 0 },
      ],
      foodBlocks: [
        {
          t: 'Breakfast',
          kcal: 400,
          kcalUsed: 0,
          items: [],
        },
        {
          t: 'Lunch',
          kcal: 900,
          kcalUsed: 0,
          items: [],
        },
        {
          t: 'Dinner',
          kcal: 700,
          kcalUsed: 0,
          items: [],
        },
      ],
      // rawHtml: '<span>This is <b>fgd</b>a delfi</span>'
    }
  },
  watch: {
    activeTab(val) {
      if (val === 0) {
        this.show = ''
        this.$nextTick(() => {
          this.activeTab = 1
        })
      }
    },
  },
  computed: {},
  mounted() {
    // this.pickerColumns[0].values = [4,3,2];
    // console.log(this.pickerColumns);
  },
  methods: {
    actionShowHabitForm() {
      this.showHabitForm = true
    },
    saveNewHabit() {
      ApiService.habits.saveHabit(this.id, this.newHabit).then(() => {
        this.$notify({ type: 'success', message: 'Saved' })
      })
    },
    percToKcal(percent) {
      return parseInt((this.budget * percent) / 100)
    },
    action2(type) {
      alert(type)
    },
    showFoodTab(index) {
      this.show = 'addFood'
      this.activeTab = 1
      console.log('this is a testas' + index)
    },
    searchForFood() {
      this.isLoadingResults = true
      ApiService.food.searchFood({ ingr: this.searchFor }).then((resp) => {
        this.results = resp.data.hints
        this.isLoadingResults = false
        console.log('resp', resp.data)
      })
    },
    goBackToSummary() {
      this.show = ''
      this.activeTab = 1
    },
    addFoodBlock() {
      this.foodBlocks.push({
        t: 'Name',
        kcal: 0,
        kcalUsed: 0,
        items: [],
      })
    },
    removeFoodBlock(index) {
      this.foodBlocks = this.foodBlocks.splice(index, 1)
    },
    onKcalBudgetChange() {
      this.tempSectionsBudget = this.foodBlocks.reduce(
        (a, b) => a + parseInt(b.kcal),
        0
      )
      if (this.tempSectionsBudget > this.budget) {
        // this.$notify({ message: 'Your total sum is bigger than the budget'})
      } else if (this.tempSectionsBudget < this.budget) {
        // this.$notify({ message: 'Your total sum is smaller than the budget'})
      }
    },
    selectFoodItem(source, food) {
      console.log('FOOD', food);
      if (source === 'search') {
        food.info = food.food.nutrients;
        this.selectedFoodItem = food;
        this.pickerColumns[1].values[0] = food.measures[0].label
      }
      this.showPicker = true
    },
    onDecode() {},
    onLoaded() {},
    onChange(e, selection) {
      console.log('ham', selection)
    },
    onConfirm() {
      alert('dane')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul.resultList {
  text-align: left;
  li {
    .info span {
      padding-right: 10px;
      font-size: 12px;
      color: #aaaaaa;
    }
    cursor: pointer;
    padding: 5px;
    border-top: 1px solid #eeeeee;
  }
}
#goBackButton a {
  padding: 8px 0 9px 0;
}
div#searchFood {
  max-height: 100%;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 100%;
  position: absolute;
  background: white;
  transition: all 0.3s;
  &.active {
    top: 0;
  }
}
div.split {
  border-top: 1px solid #f1f1f1;
  padding-top: 10px;
}
.title.is-size-6 {
  padding-top: 10px;
}
i.fas.fa-pen {
  font-size: 12px;
  float: right;
  padding: 10px;
  color: darkgrey;
}
i.fas.fa-plus {
  margin-top: 2px;
}
span.kcal {
  color: lightgrey;
  font-size: 12px;
  padding-left: 5px;
}
span.kcalUsed {
  font-size: 14px;
  color: grey;
}
span.foodSize {
  color: #656565 !important;
}
div.editingInputs {
  margin-right: 20px;
  padding: 10px;
}
</style>
