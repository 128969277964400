<template>
  <div class="container">
    <div class="herosmall">
      <h4 class="title is-5">
        {{ item.title }} <van-tag v-if="!item.value" type="warning">Empty</van-tag>
      </h4>
      <i class="options fas fa-ellipsis-v" @click="action('menu')"></i>
    </div>
    <div class="columns is-center is-mobile icons">
      <div class="column">
        <Uploader :max-images="1" :images="item.images" @uploaded="uploadedFile" />
      </div>
    </div>
    <transition-group name="slide">
      <b-button key="button" size="is-dotdot" @click="toggleShowText">...</b-button>
      <div v-if="showingText || item.text" :key="1" class="optText columns">
        <div class="column is-paddingless">
          <b-input
            v-model="text"
            maxlength="400"
            type="textarea"
            rows="3"
            placeholder="Text here"
          ></b-input>
        </div>
      </div>
    </transition-group>
    <hr class="small" />
  </div>
</template>
<script>
import Uploader from '@/components/Uploader'
export default {
  name: 'JournalImage',
  components: {
    Uploader,
  },
  props: ['item'],
  data() {
    return {
      timeout: null,
      showingText: false,
      text: '',
    }
  },
  watch: {
    text(val, oldVal) {
      if (!oldVal) return
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.$emit('update', { field: 'value', value: this.text })
        // your action
        // alert('zis ist da')
      }, 500)
    },
  },
  mounted() {
    this.text = this.item.value
  },
  methods: {
    toggleShowText() {
      this.showingText = !this.showingText
    },
    uploadedFile(file) {
      if (file) {
        this.$emit('update', {
          field: 'image',
          value: file.filename,
        })
      }
    },
    action(type) {
      this.$emit('action', { title: this.item.title, type })
    },
    // update(value) {
    //   // delay
    // },
  },
}
</script>
<style lang="scss" scoped>
div.icons {
  max-width: 370px;
  margin: auto;
  i {
    font-size: 50px;
    transition: all ease 0.3s;
    opacity: 0.5;
    &.fas {
      // font-size: 60px;
      transform: scale(1.2);
      opacity: 1;
    }
  }
  .lbl {
    font-size: 12px;
    color: #7e7e7e;
  }
}
div.optText {
  max-width: 570px;
  margin: auto;
  i {
    font-size: 50px;
    transition: all ease 0.3s;
    &.fas {
      // font-size: 60px;
      transform: scale(1.2);
    }
  }
  .lbl {
    font-size: 12px;
    color: #7e7e7e;
  }
}
</style>
