<template>
  <div class="descDiv"><i class="fas fa-circle" style="font-size: 3px; vertical-align:middle"></i> <small>{{ description }}</small></div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    description: String,
  },
}
</script>
<style scoped lang="scss">
div.descDiv {
  display: inline;
  color: #2c3e50;
  // font-style: italic;
  margin-right: 5px;
}
</style>
