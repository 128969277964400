import { render, staticRenderFns } from "./Selfcare.vue?vue&type=template&id=4804c958&"
import script from "./Selfcare.vue?vue&type=script&lang=js&"
export * from "./Selfcare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports