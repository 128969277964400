<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        Breathing <small class="headerSmall">Health & Wellbeing</small>
      </h4>
    </div>
    <section>
      <b-tabs
        id="nopaddingtab"
        v-model="activeTab"
        class="titleTabs"
        position="is-centered"
      >
        <b-tab-item icon="f far fa-lungs" label="Breath!">
          <div class="container">
            <div class="columns">
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <div class="hero">
                      <h4 class="title is-6">Techniques:</h4>
                    </div>
                    <div :key="index" v-for="(item, index) in techniques">
                      <b-button
                        type="is-default"
                        class="itm"
                        :class="{ active: selectedIndex === index }"
                        @click="selectedIndex = index"
                        >{{ item.name }}</b-button
                      >
                    </div>
                  </div>
                  <div class="column">
                    <div id="circle" @click="start">
                      <h3 style="line-height: 150px">
                        <transition name="fade" mode="out-in">
                          <span :key="currentAction">
                            <span v-if="currentAction != 'lastHold'">{{
                              currentAction
                            }}</span>
                            <span v-if="currentAction == 'lastHold'">hold</span>
                          </span>
                        </transition>
                      </h3>
                    </div>
                  </div>
                  <div class="column" style="flex: 0.8">
                    <div class="hero">
                      <h4 class="title is-6">Selection:</h4>
                    </div>
                    <p v-if="selectedIndex == -1" class="has-text-grey">
                      Select a breathing technique
                    </p>
                    <div v-if="selectedIndex > -1" id="info">
                      <div>
                        <span>Inhale:</span>
                        <strong>{{ techniques[selectedIndex].inhale }}s</strong>
                      </div>
                      <div>
                        <span>Hold:</span>
                        <strong>{{ techniques[selectedIndex].hold }}s</strong>
                      </div>
                      <div>
                        <span>Exhale:</span>
                        <strong>{{ techniques[selectedIndex].exhale }}s</strong>
                      </div>
                      <div>
                        <span>Last Hold:</span>
                        <strong
                          >{{ techniques[selectedIndex].lasthold }}s</strong
                        >
                      </div>
                      <div>
                        <span>Cycles ({{ cycles }}):</span>
                        <strong> {{ cyclesSeconds }}</strong>
                      </div>
                      <b-slider
                        :min="1"
                        :max="100"
                        :step="1"
                        v-model="cycles"
                        type="is-info"
                        size="is-medium"
                        rounded
                      ></b-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item icon="f far fa-sliders-v-square" label="Custom">
          <div class="section">
            <div class="columns">
              <div class="column" v-if="selectedIndex == -1">
                Select a Technique to edit
              </div>
              <div class="column" v-if="selectedIndex >= 0">
                <b-field>
                  <b-input
                    type="text"
                    v-model="techniques[selectedIndex].name"
                    maxlength="30"
                    :disabled="techniques[selectedIndex].protected"
                  />
                </b-field>
                <b-field
                  :label="'Inhale: ' + techniques[selectedIndex].inhale + 's'"
                >
                  <b-slider
                    :min="1"
                    :max="30"
                    :step="techniques[selectedIndex].step"
                    v-model="techniques[selectedIndex].inhale"
                    type="is-info"
                    size="is-medium"
                    :disabled="techniques[selectedIndex].protected"
                    rounded
                    ticks
                  ></b-slider>
                </b-field>
                <b-field
                  :label="'Hold: ' + techniques[selectedIndex].hold + 's'"
                >
                  <b-slider
                    :min="1"
                    :max="30"
                    :step="techniques[selectedIndex].step"
                    v-model="techniques[selectedIndex].hold"
                    type="is-info"
                    size="is-medium"
                    :disabled="techniques[selectedIndex].protected"
                    rounded
                    ticks
                  ></b-slider>
                </b-field>
                <b-field
                  :label="'Exhale: ' + techniques[selectedIndex].exhale + 's'"
                >
                  <b-slider
                    :min="1"
                    :max="30"
                    :step="techniques[selectedIndex].step"
                    v-model="techniques[selectedIndex].exhale"
                    type="is-info"
                    size="is-medium"
                    :disabled="techniques[selectedIndex].protected"
                    rounded
                    ticks
                  ></b-slider>
                </b-field>
                <b-field
                  :label="'Hold: ' + techniques[selectedIndex].lasthold + 's'"
                >
                  <b-slider
                    :min="1"
                    :max="30"
                    :step="techniques[selectedIndex].step"
                    v-model="techniques[selectedIndex].lasthold"
                    type="is-info"
                    size="is-medium"
                    :disabled="techniques[selectedIndex].protected"
                    rounded
                    ticks
                  ></b-slider>
                </b-field>
                <b-field label="Description">
                  <b-input
                    type="textarea"
                    v-model="techniques[selectedIndex].description"
                    maxlength="30"
                    :disabled="techniques[selectedIndex].protected"
                  />
                </b-field>
                <b-button @click="save" :disabled="techniques[selectedIndex].protected || !techniques[selectedIndex].name" type="is-success is-light">Save</b-button>
              </div>
              <div class="column" style="flex: 0.4">
                <div class="hero">
                  <h4 class="title is-6">Techniques:</h4>
                </div>
                <div :key="index" v-for="(item, index) in techniques">
                  <b-button
                    type="is-default"
                    class="itm"
                    :class="{ active: selectedIndex === index }"
                    @click="selectedIndex = index"
                    >{{ item.name }}<i v-if="!item.protected" @click.stop="deleteBreathing(index)" class="fas fa-times"></i></b-button
                  >
                </div>
                <b-button
                  type="is-default"
                  @click="addNewTechnique"
                  class="itm"
                  style="color: #ccc"
                  ><i class="far fa-plus"></i> Add new</b-button
                >
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item icon="f fal fa-info" label="Benefits">
          <p>
            <span class="title is-6">Breathing excercises help with:</span><br><br> 
            <ul style="list-style: square;">
              <li>Reducing stress levels</li>
              <li>Enhancing cognitive function</li>
              <li>Faster recovery after physical activity</li>
              <li>Lowering heart rate</li>
              <li>Lowering blood pressure</li>
              <li>Improving diabetic symptoms</li>
              <li>Reducing depression</li>
              <li>Better managment of chronic pain</li>
              <li>Reducing depression</li>
              <li>Regulating body's reaction to stress & fatigue</li>
              <li>Better sleep</li>
              <li>Improving function of lungs</li>
            </ul>
          </p>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import anime from 'animejs'
export default {
  name: 'Breathing',
  components: {},
  data() {
    return {
      activeTab: 0,
      cycles: 10,
      techniques: [
        {
          name: 'Box Breathing',
          inhale: 4,
          hold: 4,
          exhale: 4,
          lasthold: 4,
          step: 1,
          description:
            '"Box breathing is a technique that helps you take control of your automatic breathing patterns to train your breath for optimal health and performance." ',
          sets: 5,
          protected: true,
        },
        {
          name: '4-7-8',
          inhale: 4,
          hold: 7,
          exhale: 8,
          lasthold: 0,
          step: 1,
          description:
            'The 4-7-8 breathing technique, also known as "relaxing breath"',
          sets: 5,
          protected: true,
        },
      ],
      selected: {},
      selectedIndex: -1,
      timeout: null,
      currentAction: 'start',
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.timeout) {
      clearTimeout(this.timeout)
      next()
    } else {
      next()
    }
  },
  created() {
    ApiService.breathing.getAll().then((resp) => {
      this.techniques = this.techniques.concat(resp.data)
      this.$forceUpdate()
    })
  },
  computed: {
    cyclesSeconds() {
      const itm = this.techniques[this.selectedIndex]
      const secs = itm.inhale + itm.hold + itm.exhale + itm.lasthold
      const totalSecs = secs * this.cycles
      return parseInt(totalSecs / 60) + 'min ' + (totalSecs % 60) + 's'
    },
  },
  methods: {
    start() {
      clearTimeout(this.timeout)
      if (this.selectedIndex === -1) {
        this.$notify('Please select a breathing technique')
        return
      }
      // this.timeout = null;
      anime.remove('#circle')
      anime({
        targets: '#circle',
        scale: [1, 2],
        easing: 'linear',
        duration: this.techniques[this.selectedIndex].inhale * 1000,
      })
      this.currentAction = 'inhale'
      this.setTimeout(this.techniques[this.selectedIndex].inhale)
    },
    nextAction() {
      console.log('HIT')
      if (this.currentAction == '' || this.currentAction == 'lastHold') {
        anime({
          targets: '#circle',
          scale: [1, 2],
          easing: 'linear',
          duration: this.techniques[this.selectedIndex].inhale * 1000,
        })
        this.currentAction = 'inhale'
        this.setTimeout(this.techniques[this.selectedIndex].inhale)
      } else if (this.currentAction == 'inhale') {
        this.setTimeout(this.techniques[this.selectedIndex].hold)
        this.currentAction = 'hold'
      } else if (this.currentAction == 'hold') {
        anime({
          targets: '#circle',
          scale: [2, 1],
          easing: 'linear',
          duration: this.techniques[this.selectedIndex].exhale * 1000,
        })
        this.setTimeout(this.techniques[this.selectedIndex].exhale)
        this.currentAction = 'exhale'
      } else if (this.currentAction == 'exhale') {
        this.setTimeout(this.techniques[this.selectedIndex].lasthold)
        this.currentAction = 'lastHold'
      }
    },
    addNewTechnique() {
      this.techniques.push({
        name: 'Custom',
        inhale: 1,
        hold: 1,
        exhale: 1,
        lastHold: 1,
        step: 1,
        description: '',
      })
    },
    setTimeout(timeout) {
      this.timeout = setTimeout(() => this.nextAction(), timeout * 1000)
    },
    save() {
      console.log('Save obj', this.techniques[this.selectedIndex])
      ApiService.breathing
        .saveBreathing(this.techniques[this.selectedIndex])
        .then(() => {
          this.$notify({ type: 'success', message: 'Saved' })
        })
    },
    deleteBreathing(index) {
      const currentItem = this.techniques[index]
      this.$dialog
        .confirm({
          message: 'Would you like delete this item?',
        })
        .then(() => {
          this.selectedIndex = 0;
          if (currentItem.id) {
            ApiService.breathing.deleteBreathing(currentItem.id).then(() => {
              this.techniques.splice(index, 1)
            })
          } else {
            this.techniques.splice(index, 1)
          }
          this.$notify({ type: 'success', message: 'Removed' })
        })
    },
  },
}
</script>
<style scoped lang="scss">
#info {
  div {
    /* text-align: left; */
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    span {
      display: inline-block;
      width: 120px;
    }
    strong {
      min-width: 90px;
      display: inline-block;
    }
  }
}
#circle {
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background: #5fceff;
  background: radial-gradient(
    circle,
    rgba(95, 206, 255, 1) 0%,
    rgba(103, 178, 255, 1) 61%
  );
  border: 2px solid white;
  box-shadow: 0px 0px 25px #d3d3d3;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 60px;
  h3 {
    text-shadow: 0 0 2px #65656594;
    color: white;
  }
}
.itm {
  min-width: 200px;
  max-width: 200px;
  color: #616161;
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 2px;
  font-size: 14px;
  text-align: left !important;
  justify-content: start;
  &:focus,
  &:active {
    color: #b18f1f;
  }
  &.active {
    background: #5fceff;
    color: #fff;
  }
  i.fa-times {
    position: absolute;
    right: 10px;
    padding: 5px;
    top: 10px;
    color: red;
    cursor: pointer;
  }
  /* border-top: 1px solid #ebebeb !important; */
  /* border-radius: 0px !important; */
  /* border-color: #ebebeb; */
  /* margin-bottom: -1px; */
}
ul {
  max-width: 400px;
  margin: auto;
  li {
    margin-left: 40px;
    padding-left: 20px;
    text-align: left;
  }
}
</style>