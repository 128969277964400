<template>
  <div class="home">
    <div class="Profile">
      <h4 class="title is-4">My Profile</h4>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
