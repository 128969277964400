<template>
  <div id="screenPage">
    <!-- <div class="hero">
      <h4 class="title is-4">Habbits & Tasks</h4>
    </div>-->
    <div class="hero">
      <h4 class="title is-4" style="padding-left: 10px">
        <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          @click="isHabitObjModalActive = true"
        >
          <i class="far fa-plus"></i> <b>New</b> Habit
        </b-button>
        Habits <small class="headerSmall">{{ selectedText }}</small>
      </h4>
    </div>

    <DaysWrapper
      :dates="dates"
      :selected="selected"
      @selected="selectDate"
      @loadMoreDays="loadMoreDays"
      type="pastDatesOnly"
    />
    <section class="mainSection">
      <div id="completed" v-if="showCompletedAnimation">
        <lottie-animation path="lotties/welldone.json" :loop="false" />
      </div>
      <div
        class="has-text-right has-text-grey is-size-7"
        style="margin-top: -5px"
      >
        <i class="fal fa-hand-point-up"></i> Press and hold on a habit for
        options
      </div>
      <div v-if="morning.length > 0">
        <h4 class="title is-6">Morning</h4>
        <SingleHabit
          :key="habit.id"
          v-for="habit in morningComputed"
          :item="habit"
          @click.native="() => toggle('morning', habit)"
          v-long-press="400"
          @long-press-start="() => moreInfo(habit)"
        />
      </div>
      <div v-if="afternoon.length > 0">
        <h4 class="title is-6">Afternoon</h4>
        <SingleHabit
          :key="habit.id"
          v-for="habit in afternoonComputed"
          :item="habit"
          @click.native="() => toggle('afternoon', habit)"
          v-long-press="400"
          @long-press-start="() => moreInfo(habit)"
        />
      </div>
      <div v-if="evening.length > 0">
        <h4 class="title is-6">Evening</h4>
        <SingleHabit
          :key="habit.id"
          v-for="habit in eveningComputed"
          :item="habit"
          @click.native="() => toggle('evening', habit)"
          v-long-press="400"
          @long-press-start="() => moreInfo(habit)"
        />
      </div>
      <div v-if="anytime.length > 0 || challenges.length > 0">
        <h4 class="title is-6">Anytime</h4>
        <SingleHabit
          :key="habit.id"
          v-for="habit in anytimeComputed"
          :item="habit"
          @click.native="() => toggle('anytime', habit)"
          v-long-press="400"
          @long-press-start="() => moreInfo(habit)"
        />
        <SingleChallenge
          :key="challenge.id + ' ' + index"
          v-for="(challenge, index) in challenges"
          :selectedWeekday="selectedWeekday"
          :item="challenge"
          @click.native="() => toggleChallenge(challenge)"
          v-long-press="400"
          @long-press-start="() => moreInfo(challenge)"
        />
      </div>
    </section>
    <van-popup
      v-model="showHabitInfo"
      round
      position="bottom"
      style="max-height: 90vh"
    >
      <div class="hero">
        <h3 class="title is-4" style="text-align: center">
          {{ selectedHabit.title }}
          <b-button type="is-edit" size="is-small" @click="editHabit"
            ><i class="fas fa-pen"></i>Edit</b-button
          >
        </h3>
        <HabitStats
          :habit="selectedHabit"
          @deleted="
            showHabitInfo = false
            getData(selected)
          "
        />
      </div>
      <hr />
    </van-popup>
    <b-modal
      id="Modal"
      class
      animation="fade"
      :active.sync="isHabitObjModalActive"
      :width="440"
      scroll="keep"
    >
      <HabitModal
        :habitObj="habitObj"
        @close="
          isHabitObjModalActive = false
          getData(selected)
          resetHabitObj
        "
      />
    </b-modal>
  </div>
</template>

<script>
import DateHelper from '@/services/dateHelper'
import DaysWrapper from '@/components/habits/DaysWrapper'
import HabitModal from '@/components/modals/HabitModal'
import SingleHabit from '@/components/habits/SingleHabit'
import SingleChallenge from '@/components/habits/SingleChallenge'
import HabitStats from '@/components/HabitStats'
import ApiService from '@/services/ApiService'
import SwatchesColors from '@/constants/colors.json'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'Habits',
  components: {
    DaysWrapper,
    SingleHabit,
    SingleChallenge,
    HabitStats,
    HabitModal,
    LottieAnimation,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      await this.saveHabitsDay()
    }
    next()
  },
  data() {
    return {
      anytime: [],
      morning: [],
      afternoon: [],
      evening: [],
      filledDates: [],
      habitObj: {
        options: {
          color: '#222f3d',
          background: '#f9f9f9',
          icon: 'fas fa-check',
        },
        section: 'anytime',
        schedule: 'same',
        schedule_options: 'same',
      },
      selectedHabit: {},
      habit_sections: [
        { text: 'Anytime', value: 'anytime' },
        { text: 'Morning', value: 'morning' },
        { text: 'Afternoon', value: 'afternoon' },
        { text: 'Evening', value: 'evening' },
      ],
      dates: [],
      selected: '',
      selectedWeekday: null,
      selectedText: '',
      editHabitId: 0,
      showHabitInfo: false,
      checkbox: '',
      isHabitObjModalActive: false,
      swatches: SwatchesColors,
      dirty: false,
      challenges: [],
      completed: [],
      notCompleted: [],
      completedChallenges: {},
      newTotals: {},
      showCompletedAnimation: false,
    }
  },
  computed: {
    allHabbits() {
      return this.positiveHabits.concat(this.negativeHabits)
    },
    morningComputed() {
      if (this.morning) {
        return this.morning.filter((itm) => {
          return (
            itm.frequency == 'same' ||
            (itm.frequency.weekdays &&
              itm.frequency.weekdays.indexOf(this.selectedWeekday) > -1)
          )
        })
      } else {
        return []
      }
    },
    afternoonComputed() {
      if (this.afternoon) {
        return this.afternoon.filter((itm) => {
          return (
            itm.frequency == 'same' ||
            (itm.frequency.weekdays &&
              itm.frequency.weekdays.indexOf(this.selectedWeekday) > -1)
          )
        })
      } else {
        return []
      }
    },
    eveningComputed() {
      if (this.evening) {
        return this.evening.filter((itm) => {
          return (
            itm.frequency == 'same' ||
            (itm.frequency.weekdays &&
              itm.frequency.weekdays.indexOf(this.selectedWeekday) > -1)
          )
        })
      } else {
        return []
      }
    },
    anytimeComputed() {
      if (this.anytime) {
        return this.anytime.filter((itm) => {
          return (
            itm.frequency == 'same' ||
            (itm.frequency.weekdays &&
              itm.frequency.weekdays.indexOf(this.selectedWeekday) > -1)
          )
        })
      } else {
        return []
      }
    },
  },
  created() {
    this.addDays()
  },
  mounted() {},
  methods: {
    confirmDeletetion() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove it?',
        onConfirm: () => this.$buefy.toast.open('Deleted'),
      })
    },
    addDays() {
      const dateObj = DateHelper.initialDates(21, 1)
      this.dates = dateObj.dates
      this.selected = dateObj.selected
      this.selectedText = dateObj.selectedText
      this.selectedWeekday = dateObj.selectedWeekday
      this.getData(this.selected)
      this.getFilledDates(
        this.dates[0].fullDate,
        this.dates[this.dates.length - 1].fullDate
      )
    },
    getFilledDates(beginningDate, endDate) {
      ApiService.habits.getFilledDates(beginningDate, endDate).then((resp) => {
        const filledDates = resp.data
        if (filledDates) {
          let newDates = JSON.parse(JSON.stringify(this.dates))
          newDates.map((itm) => {
            if (filledDates[itm.fullDate]) {
              itm.status = filledDates[itm.fullDate]
            }
          })
          this.dates = newDates
          // console.log(newDates)
        }
      })
    },
    containsDate(date) {
      return Object.keys(this.filledDates).includes(date)
    },
    editHabit() {
      this.habitObj = this.selectedHabit
      this.showHabitInfo = false
      this.isHabitObjModalActive = true
    },
    loadMoreDays(date) {
      const newDates = DateHelper.addDates(date, [...this.dates])
      this.dates = newDates
      this.getFilledDates(newDates[0].fullDate, date)
    },
    selectDate(dateObj) {
      if (this.dirty) {
        this.saveHabitsDay(dateObj)
      } else {
        this.setDate(dateObj)
        this.getData(dateObj.date)
      }
    },
    setDate(dateObj) {
      this.selected = dateObj.date
      this.selectedText = DateHelper.convertDateToText(dateObj.date)
      this.selectedWeekday = dateObj.wday
    },
    getData(date) {
      ApiService.habits.getHabitsDay(date).then((resp) => {
        this.completed =
          resp.data.data.length > 0
            ? resp.data.data[0].completed
                .split(',')
                .map(Number)
                .filter((itm) => itm != 0)
            : []
        this.notCompleted =
          resp.data.data.length > 0
            ? resp.data.data[0].not_completed
                .split(',')
                .map(Number)
                .filter((itm) => itm != 0)
            : []
        try {
          this.completedChallenges =
            resp.data.data.length > 0 &&
            resp.data.data[0].completed_challenges != null
              ? JSON.parse(resp.data.data[0].completed_challenges)
              : {}
        } catch (error) {
          alert(error)
        }
        const sections = ['anytime', 'morning', 'afternoon', 'evening']
        for (let index = 0; index < sections.length; index++) {
          this[sections[index]] = resp.data.habits.filter((itm) => {
            if (itm.section === sections[index]) {
              itm.completed = this.completed.indexOf(itm.id) != -1
              return itm
            }
          })
        }

        this.challenges = resp.data.challenges.map((itm) => {
          itm.completed = this.completedChallenges[itm.id] ? true : false
          if (itm.completed) {
            itm.level = this.completedChallenges[itm.id]
            itm.locked = true
          }
          itm.schedule_options = JSON.parse(itm.schedule_options)
          return itm
        })
      })
    },
    saveHabitsDay(dateObj) {
      this.$store.dispatch('loading', true)
      ApiService.habits
        .saveHabitsDay(
          this.selected,
          this.completed,
          this.getNotCompleted(),
          this.newTotals,
          this.completedChallenges
        )
        .then(() => {
          this.dirty = false
          this.$store.dispatch('loading', false)
          this.$notify({ type: 'success', message: 'Saved', duration: 1000 })
          if (dateObj) {
            this.setDate(dateObj)
            this.getData(dateObj.date)
          }
        })
        .catch(() => this.$store.dispatch('loading', false))
    },
    getDayNames(year, month) {
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      let daysInMonth = new Date(year, month, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        let d = new Date(year, month, i)
        let dayName = days[d.getDay()]
        console.log(i + ' ' + dayName)
      }
    },
    getNotCompleted() {
      const notCompleted = []
        .concat(
          this.morningComputed,
          this.afternoonComputed,
          this.eveningComputed,
          this.anytimeComputed
        )
        .filter((itm) => itm.completed == false)
        .map((itm) => itm.id)
      return notCompleted
    },
    updateWeekdays(selection) {
      const index = this.habitObj.schedule_options.weekdays.indexOf(selection)
      if (index === -1) {
        this.habitObj.schedule_options.weekdays = [
          ...this.habitObj.schedule_options.weekdays,
          selection,
        ]
      } else {
        this.habitObj.schedule_options.weekdays.splice(index, 1)
      }
      this.$forceUpdate()
    },
    toggle(section, habit) {
      this.dirty = true
      const index = this[section].findIndex((itm) => itm.id === habit.id)
      if (habit.completed) {
        habit.completed = false
        habit.streak--
        habit.level--
        this.completed = this.completed.filter((itm) => itm != habit.id)
        this.notCompleted = [...this.notCompleted, habit.id]
      } else {
        habit.completed = true
        habit.streak++
        habit.level++
        this.completed = [...this.completed, habit.id]
        this.notCompleted = this.notCompleted.filter((itm) => itm != habit.id)
      }
      const dateIndex = this.dates.findIndex(
        (itm) => itm.fullDate === this.selected
      )
      const howManyNotCompleted = this.getNotCompleted()
      if (dateIndex > -1) {
        this.$set(this.dates, dateIndex, {
          ...this.dates[dateIndex],
          status: howManyNotCompleted.length === 0 ? 3 : 1,
        })
      }
      if (howManyNotCompleted.length === 0) {
        this.showCompletedAnimation = true
        setTimeout(() => {
          this.showCompletedAnimation = false
        }, 2500)
      }
      this.newTotals[habit.id] = { level: habit.level, streak: habit.streak }
      this.$set(this[section], index, habit)
      this.$forceUpdate()
    },
    toggleChallenge(challenge) {
      if (challenge.locked) {
        this.$notify({
          type: 'primary',
          message: "You can't undo challenge day once it's done",
        })
        return
      }
      if (new Date(challenge.last_date) > new Date(this.selected)) {
        this.$notify({
          type: 'primary',
          message: "You can't toggle challenges in the past dates",
        })
        return
      }
      this.dirty = true
      const index = this.challenges.findIndex((itm) => itm.id === challenge.id)

      if (challenge.completed) {
        challenge.completed = false
        challenge.level = parseInt(challenge.level) - 1
        if (challenge.level === 0) {
          delete this.completedChallenges[challenge.id]
        } else {
          this.completedChallenges[challenge.id] = challenge.level
        }
      } else {
        challenge.completed = true
        challenge.level = parseInt(challenge.level) + 1
        this.completedChallenges[challenge.id] = challenge.level
      }
      // console.log('Challenges', this.completedChallenges)
      this.$set(this.challenges, index, challenge)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    moreInfo(habit) {
      this.showHabitInfo = true
      this.selectedHabit = habit
    },
    resetHabitObj() {
      this.habitObj = {
        options: {
          color: '#222f3d',
          background: '#f9f9f9',
          icon: 'fas fa-check',
        },
        section: 'anytime',
        schedule: 'same',
        schedule_options: 'same',
      }
    },
  },
}
</script>
<style scoped>
#completed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
section.mainSection {
  text-align: left;
  padding: 10px;
  margin: auto;
  max-width: 1000px;
}
section.mainSection h4 {
  padding: 10px;
}
section h4 {
  font-weight: 400;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
  color: #f7b875;
}
#topSelections .column label {
  text-align: center;
}
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
small.is-size-9 {
  font-size: 9px;
  color: rgb(220, 220, 220);
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
figure.image {
  margin-right: 15px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}
div.dailyDiv {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
div.dailyDiv .column span.active {
  font-weight: bold;
  color: #167df0;
}
.dailyDiv .columns .column {
  border-right: 1px solid #f2f2f2;
}
div.dailyRow {
  border-bottom: 1px solid #f2f2f2;
}
.tabs li.is-active span {
  font-weight: bold;
}
.positiveHeader,
.negativeHeader {
  color: red;
  font-weight: 500;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
  margin-bottom: 10px;
  margin-bottom: 0px;
}
.positiveHeader {
  color: green;
  border-right: 1px solid #f2f2f2;
  margin-bottom: 0px;
}
.habitsWrapper > div:first-child {
  border-top: 0;
}
#positiveBlock {
  border-right: 1px solid #f2f2f2;
}
.habbitsBlock {
  position: relative;
  border-top: 1px solid #f2f2f2;
  padding: 10px;
}
.habbitsBlock button {
  display: none;
  color: red;
  font-weight: bold;
  font-size: 15px;
  background: none;
  border: none;
}
.habbitsBlock:hover button {
  display: inline;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.habbitsBlock.active button {
  display: inline;
  position: absolute;
  right: 0;
}
.container {
  padding: 0 10px;
}
.fa-check {
  color: green;
}
@media only screen and (max-width: 500px) {
  .dailyDiv {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dailyDiv .columns .column {
    padding: 15px 0px;
  }
}
.habitsWrapper .habbitsBlock {
  padding: 5px;
}
</style>

