<template>
  <van-uploader
    id="uploader"
    v-model="fileList"
    :before-read="beforeRead"
    :after-read="afterRead"
    :max-size="500 * 1024"
    :max-count="maxImages"
    :preview-size="100"
    multiple
    upload-icon=" fas fa-camera"
    @oversize="onOversize"
  />
</template>

<script>
import ApiService from '@/services/ApiService'
export default {
  name: 'Uploader',
  components: {},
  props: {
    maxImages: Number,
    images: Array
    // id: Number
  },
  data() {
    return {
      imagesLoading: false,
      files: [],
      fileList: [],
      postAction:
        'https://londoneleganceclub.com/api/members/attachments/saveImage',
      // rawHtml: '<span>This is <b>fgd</b>a delfi</span>'
    }
  },
  computed: {
    user_id: function () {
      return 5
    },
    token: function () {
      return 'Bearer ' + this.$store.state.general.token
    },
  },
  mounted() {
    if(this.images && this.images.length > 0){
      this.fileList = this.images;
    }
  },
  methods: {
    beforeRead(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/webp') {
        this.$toast('Please upload an image in jpg format')
        return false
      }
      return true
    },
    afterRead(file) {
      console.log(file)
      file.status = 'uploading'
      file.message = 'Uploading...'
      ApiService.upload.saveImage(file.file).then((resp) => {
        file.status = 'done'
        file.id = resp.data.insertId;
        file.filename = resp.data.filename.fileName;
        this.$emit('uploaded', file)
        console.log(resp)
      })
    },
    onOversize(file) {
      console.log(file)
      this.$toast('File size cannot exceed 500kb')
    },
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
          setTimeout(() => {
            if (this.$refs.upload.active === false) {
              this.imagesLoading = false
            }
          }, 100)
        }
      }
      if (!this.$refs.upload.active) {
        console.log('auto upload...')
        this.imagesLoading = true
        this.$refs.upload.active = true
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.van-uploader__upload{
  background: blue;
  padding: 10px;
}
div.animation-content {
  width: 100% !important;
}
div.modal-content {
  margin: 0;
}
div.content {
  padding: 5px;
}
h1 {
  padding-top: 10px;
  padding-left: 5px;
}
#youtubeWrapper {
  min-height: 300px;
  z-index: 9999;
}
@media screen and (max-width: 668px) {
  h1.title {
    min-height: 40px;
  }
}
.habitText {
  padding: 3px;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 13px;
  color: #3e3e3e;
}
button.noborder {
  margin-right: 4px;
  padding: 3px 7px;
  span.fa-circle {
    font-size: 10px;
  }
  i {
    color: #bbb;
  }
}
div.activeForm {
  margin-top: 10px;
}
</style>
