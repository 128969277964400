<template>
  <div class="post">
    <div class="is-clearfix">
      <span class="is-bold">
        <strong>Which outfit do you like more ?</strong>
      </span>
      <b-button
        class="is-text is-pulled-right"
        aria-controls="competitionInformation"
        @click="isOpen = !isOpen"
      >
        <i class="fas fa-info fa-lg icon is-small is-info"></i>
      </b-button>
    </div>
    <b-collapse aria-id="competitionInformation" :open.sync="isOpen">
      <div class="notification margin10">
        <div class="content">
          <h3 class="is-size-5 has-text-info">Competitions</h3>
          <ol type="1">
            <li class="has-text-left">The aim is to choose a better represantion</li>
            <li class="has-text-left">Single-elimination principle</li>
            <li
              class="has-text-left"
            >You vote, you win. Not only the author of winning entry gets a prize. If you happened to vote for a winning entry you have a chance of winning too.</li>
          </ol>
        </div>
      </div>
    </b-collapse>
    <div class="insideCompetition">
      <img style="width: 46%" src="https://picsum.photos/id/237/400/600" />
      <img style="width:6%" src="../../assets/or.png" />
      <img style="width: 46%" src="https://picsum.photos/id/238/400/600" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostTile',
  components: {
    // MembersTemplate
  },
  props: {
    item: Object
  },
  data() {
    return {
      dropFiles: null,
      isOpen: false
    }
  },
  methods: {
    showPost(id) {
      this.$parent.openModal('post', {
        id: id,
        title: 'test',
        content: 'this <b>is</b> content'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
