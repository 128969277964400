<template>
  <div id="container">
    <div class="columns">
      <div class="column is-two-fifths has-text-left problemsColumn">
        <van-image
          width="80"
          height="80"
          :src="problemImage"
          lazy-load
          class="problImage"
          fit="contain"
        />
        <span id="problem">{{ item.title }}</span>
      </div>
      <div class="column is-three-fifths">
        <b-button
          type="is-info is-light"
          v-if="show == false"
          @click="show = true"
          >Show</b-button
        >
        <ul v-if="show">
          <li v-for="(solution, index) in item.solutions" :key="index">
            <span class="has-size-6">{{ solution.title }}</span
            ><br />
            <p class="description">{{ solution.description }}</p>
            <div
              v-for="(image, picIndex) in splitImages(solution.attachments)"
              :key="picIndex"
              style="display: inline-block"
            >
              <van-image
                v-if="image"
                :src="solutionImage(image)"
                lazy-load
                width="60"
                height="60"
                @click="showPreview(solution.attachments, picIndex)"
              ></van-image>
            </div>
          </li>
          <li>
            <span
              class="is-size-7 has-text-info cursorPointer"
              @click="toggleNewSolution"
              >Suggest new solution for {{ item.problem }}</span
            >
          </li>
          <b-button
            type="is-default is-light"
            size="is-small"
            @click="show = false"
            >Hide</b-button
          >
        </ul>
        <div v-if="showNewEntry" class="newFormStyle" style="">
          <van-field
            v-model="newSolution.solution"
            rows="2"
            autosize
            label="New Solution (short)"
            type="textarea"
            maxlength="150"
            placeholder="Text..."
            show-word-limit
          />
          <van-field
            v-model="newSolution.description"
            rows="2"
            autosize
            label="Description"
            type="textarea"
            maxlength="1000"
            placeholder="Text..."
            show-word-limit
          />
          <van-field
            v-model="newSolution.link"
            label="Link (optional)"
            placeholder="Optional Link http://..."
          />
          <div class="van-cell van-field">
            <div class="van-cell__title van-field__label">
              <span>Image (optional)</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <Uploader :max-images="4" @uploaded="uploadedFile" />
              </div>
            </div>
          </div>
          <b-button type="is-primary" @click="saveSolution">Submit</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import Uploader from '@/components/Uploader'
import { ImagePreview } from 'vant'
export default {
  name: 'HowToSingleSolutionRow',
  components: {
    Uploader,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      showNewEntry: false,
      newSolution: {},
      show: false,
    }
  },
  computed: {
    problemImage: function () {
      if (this.item.image) {
        return this.item.image
      } else {
        return null
      }
    },
  },
  methods: {
    toggleNewSolution() {
      this.showNewEntry = !this.showNewEntry
    },
    solutionImage(image) {
      if (image) {
        return image
      } else {
        return false
      }
    },
    showPreview(attachments, index) {
      const images = attachments.split(',')
      ImagePreview({
        images: images.map((item) => {
          return item
        }),
        startPosition: index,
      })
    },
    uploadedFile(image) {
      console.log('Images', image)
      if (!this.newSolution.attachments) {
        this.newSolution.attachments = image.filename
      } else {
        this.newSolution.attachments += ',' + image.filename
      }
    },
    saveSolution() {
      this.newSolution.howto_problem_id = this.item.id
      ApiService.howto.saveSolution(this.newSolution).then(() => {
        this.newSolution = {}
        this.$emit('refresh', true)
        this.toggleNewSolution()
      })
    },
    splitImages(textString) {
      if (!textString) return []
      return textString.split(',')
    },
    splitFields(combinedString) {
      let splitRows =
        combinedString.length > 15 ? combinedString.split(';;;;').reverse() : []
      let splitFields
      let objectsArray = []
      splitRows.forEach((fieldsString) => {
        splitFields = fieldsString.split('+++')
        objectsArray.push({
          title: splitFields[0],
          description: splitFields[1],
          url: splitFields[2] ? splitFields[2] : null,
        })
      })
      return objectsArray
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.problImage {
  border: 1px solid #dfe4ec;
  border-radius: 5px;
  overflow: hidden;
}
#container {
  padding: 12px;
}
#problem {
  font-weight: 400;
  vertical-align: top;
  margin: 5px;
  padding-left: 5px;
  font-size: 16px;
  display: inline-block;
  width: calc(100% - 100px);
}
div.problemsColumn {
  padding-top: 10px !important;
  padding-left: 10px !important;
  background: #f3f7ff;
}
div.is-three-fifths {
  padding-left: 10px !important;
}
div.column {
  -webkit-box-shadow: 0px 0px 1px #ccc;
  box-shadow: 0px 0px 1px #ccc;
  padding: 5px;
}
p.description {
  color: #888888;
}
h3 {
  margin: 40px 0 0;
}
ul {
  margin-left: 5px;
  list-style-type: disc;
}
ul {
  /* list-style-type: none; */
  padding: 0;
}
li {
  // display: inline-block;
  margin: 0 10px;
  font-size: 14px;
}
a {
  color: #42b983;
}
</style>
