<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4" style="text-transform: capitalize">
        <BackButton /> {{ category }}
      </h4>
    </div>
    <section class="section">
      <transition-group
        name="fade"
        mode="out-in"
        tag="div"
        class="columns is-centered is-multiline contentBlocks"
      >
        <SingleBlock
          :image="workout.image"
          :text="workout.title"
          :key="index"
          @click.native="gotoWorkout(workout.id)"
          v-for="(workout, index) in workouts"
        />
      </transition-group>
    </section>
  </div>
</template>
<script>
import SingleBlock from '@/components/SingleSmallBlock'
import ApiService from '@/services/ApiService'
import BackButton from '@/components/BackButton'

export default {
  name: 'WorkoutCategory',
  components: {
    SingleBlock,
    BackButton,
  },
  data() {
    return {
      workouts: [],
      currentPage: 0,
      subcategory: 'category',
    }
  },
  created() {
    this.category = decodeURI(this.$route.params.category)

    this.getWorkouts()
  },
  methods: {
    getWorkouts() {
      ApiService.workouts
        .getWorkoutsCategory(this.$route.params.category)
        .then((response) => {
          var workouts = response.data
          this.workouts = workouts
        })
    },
    gotoWorkout(workoutId) {
      this.$router.push('/members/health_wellbeing/workout/' + workoutId)
    },
  },
}
</script>
