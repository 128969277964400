import Vue from 'vue'
import Vuex from 'vuex'
import general from './general'
import admin from './admin'
import beauty from './beauty'
import health from './health'
import selfdev from './selfdev'

Vue.use(Vuex)

export default new Vuex.Store({
  // namespaced: true,
  strict: true,
  modules: {
    general,
    admin,
    beauty,
    health,
    selfdev,

  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
