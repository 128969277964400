<template>
  <div class="wrap">
    <div class="profileImage">
      <van-image
        width="40"
        height="40"
        fit="cover"
        lazy-load
        :src="item.avatar"
      />
    </div>
    <div class="author">
      {{ item.username }}
      <span class="timeago is-pulled-right darkGrey">
        <timeago :datetime="item.created_at"></timeago> &nbsp;by
        <strong>{{ item.username || 'user' }}</strong>
      </span>
    </div>
    <div class="normalText">{{ item.message }}</div>

    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: 'SingleForumTopicMessage',
  props: {
    item: Object,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profileImage {
  width: 50px;
  height: 50px;
  float: left;
  text-align: center;
}
div.clearfix {
  clear: both;
}
.timeago {
  font-size: 11px;
  font-weight: 400;
  strong {
    color: #bbbbbb !important;
  }
}
.author {
  font-weight: bold;
  .timeago {
    line-height: 23px !important;
  }
}
div.wrap {
  box-shadow: 0px 0px 1px #ccc;
  padding: 5px;
  padding-top: 10px;
}
.normalText {
  white-space: pre-line;
}
</style>
