<template>
  <div class="post" @click="showPost(4)">
    <!-- <figure class="image is-64x64 is-vcentered">
      <img class="is-rounded" src="https://bulma.io/images/placeholders/64x64.png" />
    </figure> -->
  </div>
</template>

<script>
export default {
  name: 'PostTile',
  components: {
    // MembersTemplate
  },
  props: ['likes', "id"],
  data() {
    return {
      disableLike: false,
      bookmarked: false
    }
  },
  methods: {
    showPost(id) {
      this.$root.$emit('showPost', {id})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.far b{
  padding-left: 5px;
  padding-right: 5px;
}
</style>
