import dbConnection from '../dbConnection'
const todo_planner = {
  getDay: function (date) {
    return dbConnection.get('members/todo/day/' + date)
  },
  save: function (payload) {
    return dbConnection.post('members/todo/save', {
      payload
    })
  },
  removeEntry: function (id) {
    return dbConnection.post('members/todo/removeEntry', {
      id
    })
  },
  completeTodoItem: function (id) {
    return dbConnection.post('members/todo/completeTodoItem', {
      id
    })
  },
  getArchiveTodos: function () {
    return dbConnection.get('members/todo/getArchiveTodos')
  },
  getFilledDates: function (startDate, endDate) {
    return dbConnection.post('members/todo/getFilledDates', {
      start: startDate, end: endDate
    })
  }
}
export default todo_planner
