// function calc (form) {
// var W, T
// W=form.Weight.value*form.WeightUnit.options[form.WeightUnit.selectedIndex].value
// T=form.Time.value/form.TimeUnit.options[form.TimeUnit.selectedIndex].value
// form.Calories.value = Math.round(form.Activity.options[form.Activity.selectedIndex].value*W*T)
// }
<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">Self-care</h4>
    </div>
    <section class="section">
      <transition-group
        name="fade"
        mode="out-in"
        tag="div"
        class="columns is-centered is-multiline contentBlocks"
      >
        <Tile
          v-for="item in items"
          :key="item.id"
          :item="item"
          class="column is-one-third"
          @click.native="$parent.openModal(item)"
        />
      </transition-group>
    </section>
  </div>
</template>
<script>
import Tile from '@/components/tiles/Tile'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'

export default {
  name: 'Selfcare',
  components: {
    Tile,
  },
  data() {
    return {
      items: [],
      currentPage: 0,
      subcategory: 'selfcare',
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    getPosts() {
      ApiService.posts
        .getPostsSubCategory(this.subcategory, this.currentPage)
        .then((response) => {
          var items = response.data.slice(0, 20)
          items = updatePosts(items)
          this.items = items
        })
    },
  },
}
</script>
