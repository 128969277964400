import { render, staticRenderFns } from "./Journal.vue?vue&type=template&id=39a3f5eb&scoped=true&"
import script from "./Journal.vue?vue&type=script&lang=js&"
export * from "./Journal.vue?vue&type=script&lang=js&"
import style0 from "./Journal.vue?vue&type=style&index=0&id=39a3f5eb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a3f5eb",
  null
  
)

export default component.exports