<template>
  <div class="comment">
    <i
      class="fas fa-circle"
      style="font-size: 3px; vertical-align: middle; padding-right: 5px"
    ></i><i class="far fa-comment"></i> <small>{{ commentsCount }}</small>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    commentsCount: Number,
  },
}
</script>
<style scoped lang="scss">
div.comment {
  display: inline-block;
  color: #2c3e50;
}
</style>
