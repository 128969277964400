<template>
  <div class="home">
    <div class="Profile">
      <h4 class="title is-4"></h4>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch('logout', true)
    this.$notify({
      type: 'success',
      message: 'You have been logged out',
      duration: 3000,
    })
    setTimeout(() => {
      this.$router.push('/')
    }, 50)
  },
}
</script>
