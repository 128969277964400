import dbConnection from '../dbConnection'
const food = {
  getJournalDay: function(date) {
    return dbConnection.get('members/journal/day/' + date)
  },
  saveNewJournalTemplate: function(obj) {
    return dbConnection.post('members/journal/saveTemplate', {
      ...obj
    })
  },
  saveJournalDay: function(items, date) {
    return dbConnection.post('members/journal/save', {
      items,
      date
    })
  },
  searchFood: function(params) {
    return dbConnection.post('members/food/search', {
      params
    })
  },
  autocomplete: function(obj){
    return dbConnection.post('members/food/autocomplete', obj);
  }
}
export default food;
