<template>
  <div class="box">
    <img
      v-if="image"
      v-lazy="image"
      :style="{ filter: `hue-rotate(${hue}deg)` }"
    />
    <div class="bg" v-if="background" :style="{ background: background }"></div>
    <i v-if="icon" :class="icon" :style="{ color: iconColor }"></i>
    <div class="text" v-if="!label">{{ text || '' }}</div>
    <div class="labelText" v-if="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'SingleBlock',
  props: {
    image: String,
    label: String,
    text: String,
    icon: String,
    iconColor: String,
    background: String,
    hue: Number,
  },
  computed: {
    src() {
      return 'https://via.placeholder.com/170'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box {
  padding: 0px;
  position: relative;
  margin-right: 14px;
  width: 170px;
  max-width: 170px;
  max-height: 170px;
  min-height: 170px;
  vertical-align: top;
  display: inline-block;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
      0 0px 0 1px rgb(10 10 10 / 2%);
    box-shadow: 0 0.1em 1em -0.125em rgb(10 10 10 / 5%),
      0 0px 0 1px rgb(10 10 10 / 2%);
  }
  img {
    height: 170px;
  }
  .text {
    white-space: break-spaces;
    /* text-align: left; */
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 0;
    font-size: 14px;
    min-height: 40px;
    width: 100%;
    padding: 2px;
    font-weight: 500;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .labelText {
    position: absolute;
    bottom: 10px;
    color: white;
    padding-left: 20px;
    text-align: center;
  }
  div.bg {
    height: 170px;
  }
  i {
    font-size: 50px;
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0;
  }
}
</style>
