<template>
  <div class="post">
    
  </div>
</template>

<script>
export default {
  name: 'postTile',
  components: {
    // MembersTemplate
  },
  props:{
    data: Object
  },
  data() {
    return {
      dropFiles: null,
      isOpen: false
    }
  },
  methods: {
    showPost(id) {
      this.$parent.openModal('post', {
        id: id,
        title: 'test',
        content: 'this <b>is</b> content'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
