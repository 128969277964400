<template>
  <div class="post">
    <div class="subtitle">Contest time!</div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-half">
        <b-upload v-model="dropFiles" multiple drag-drop>
          <section class="section">
            <div class="content has-text-centered" v-if="!dropFiles">
              <p>
                <b-icon pack="fas" icon="camera" size="is-large"></b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
            <div class></div>
          </section>
        </b-upload>
      </div>
      <div class="column is-two-thirds is-half is-paddingless" style="padding-right: 5px;">
        <p class="is-info-text is-size-7">
          <span class="has-text-danger">Invitation</span> that you've used contained a free entry to our monthly competition where you can win great prizes!
        </p>
        <h5 class="is-size-6 has-text-left">
          <strong>Rules:</strong>
        </h5>
        <ul>
          <li
            class="is-size-7 has-text-left"
          >- Take a picture of your new pair with matching outfilt</li>
          <li class="is-size-7 has-text-left">- Face, hair not required, it's about style!</li>
          <li
            class="is-size-7 has-text-left"
          >- Clean background, no cute dogs or extras allowed :). Be fair.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'postTile',
  components: {
    // MembersTemplate
  },
  props: {
    item: Object
  },
  data() {
    return {
      dropFiles: null,
      isOpen: false
    }
  },
  methods: {
    showPost(id) {
      this.$parent.openModal('post', {
        id: id,
        title: 'test',
        content: 'this <b>is</b> content'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
