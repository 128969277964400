<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        <!-- <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          @click="() => showWorkoutCreation('new')"
        >
          <i class="far fa-settings"></i> <b>Create</b> Workout
        </b-button> -->
        <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          @click="() => showWorkoutCreation('new')"
        >
          <i class="far fa-plus"></i> <b>Create</b> Workout
        </b-button>
        Workouts
      </h4>
    </div>
    <section class="section">
      <h4 class="title is-5">Quick workouts</h4>
      <div class="horizontalSlider">
        <SingleBlock
          :image="workout.image"
          :text="workout.title"
          :key="index"
          @click.native="gotoWorkout(workout.id)"
          v-for="(workout, index) in quickWorkouts"
        />
      </div>
      <h4 class="title is-5">Focus</h4>
      <div class="horizontalSlider">
        <SingleBlock
          label="Abs"
          image="https://elegance.eu-central-1.linodeobjects.com/abs.webp"
          @click.native="gotoCategory('abs')"
        />
        <SingleBlock
          label="Back"
          image="https://elegance.eu-central-1.linodeobjects.com/back.webp"
          @click.native="gotoCategory('back')"
        />
        <SingleBlock
          label="Arms"
          image="https://elegance.eu-central-1.linodeobjects.com/arms.webp"
          @click.native="gotoCategory('arms')"
        />
        <SingleBlock
          label="Glutes"
          image="https://elegance.eu-central-1.linodeobjects.com/butt.webp"
          @click.native="gotoCategory('glutes')"
        />
        <SingleBlock
          label="Legs"
          image="https://elegance.eu-central-1.linodeobjects.com/legs.webp"
          @click.native="gotoCategory('legs')"
        />
      </div>
      <h4 class="title is-5">Programs</h4>
      <div class="horizontalSlider">
        <SingleBlock
          :image="workout.image"
          :text="workout.title"
          :key="index"
          @click.native="gotoWorkout(workout.id)"
          v-for="(workout, index) in programsWorkouts"
        />
      </div>
      <h4 class="title is-5">My created workouts</h4>
      <div class="horizontalSlider">
        <SingleBlock
          text="Create workout"
          image="https://elegance.eu-central-1.linodeobjects.com/new_workout.jpg"
          @click.native="showWorkoutCreation('new')"
        />
        <SingleBlock
          :image="workout.image"
          :text="workout.title"
          :key="index"
          @click.native="gotoWorkout(workout.id)"
          v-for="(workout, index) in myWorkouts"
        />
      </div>
      <b-modal
        id="Modal"
        class
        animation="fade"
        :active.sync="isNewWorkoutModalActive"
        :width="440"
        scroll="keep"
        :full-screen="true"
      >
        <WorkoutCreation :id="workoutCreationId" />
      </b-modal>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import SingleBlock from '@/components/SingleSmallBlock'
import WorkoutCreation from '@/components/modals/WorkoutCreation'
export default {
  name: 'Workouts',
  data() {
    return {
      isNewWorkoutModalActive: false,
      workoutCreationId: 'new',
      workouts: [],
      workout: {
        name: 'Testas',
        parts: [
          {
            name: 'Shoulder pad',
            reps: 4,
            weight: 2,
            time: 20,
            exercise_id: 2,
            rest: 20,
            comment: '',
          },
        ],
      },
      // exercises: [],
    }
  },
  components: { SingleBlock, WorkoutCreation },
  created() {
    ApiService.workouts
      .getWorkouts()
      .then((resp) => (this.workouts = resp.data))
  },
  computed: {
    quickWorkouts: function () {
      return this.workouts.filter((itm) => itm.section === 'quick')
    },
    programsWorkouts: function () {
      return this.workouts.filter((itm) => itm.section === 'program')
    },
    focusWorkouts: function () {
      return this.workouts.filter((itm) => itm.section === 'focus')
    },
    myWorkouts: function () {
      return this.workouts.filter((itm) => itm.my_workout)
    },
  },
  methods: {
    showWorkoutCreation(id) {
      this.workoutCreationId = id
      this.isNewWorkoutModalActive = true
    },
    gotoWorkout(workoutId) {
      this.$router.push('/members/health_wellbeing/workout/' + workoutId)
    },
    gotoCategory(path) {
      this.$router.push(
        '/members/health_wellbeing/workout/category/' + encodeURI(path)
      )
    },
  },
}
</script>