<template>
  <div class="wrap" :style="{ background: item.background }">
    <div class="profileImage">
      <i
        :class="item.icon"
        :style="{ color: item.color }"
        style="font-size: 25px; color: grey; line-height: 60px"
      ></i>
    </div>
    <i class="absoluteIcon" :class="item.icon"></i>
    <div class="challengeTitle" :style="{ color: item.color }">
      {{ item.title }} <span class="in_progress" v-if="item.level === 0 || item.level > 0">In progress</span>
    </div>
    <div class="normalText">
      {{ item.description }}
    </div>
    <div class="bottom" :style="{ color: item.color }">
      <!-- <span v-if="item.title">
        <i class="fas fa-user fa-lg icon is-small"></i>
        {{ item.users || 0 }}
      </span> -->
      <span v-if="item.title">
        <i class="fas fa-comments fa-lg icon is-small"></i>
        {{ item.messages || 0 }}
      </span>
      <smalL>{{ item.days }} days</smalL>
      <span v-if="item.updated_by" class="timeago is-pulled-right">
        <timeago :datetime="item.updated_at"></timeago>&nbsp;by
        <b>{{ item.updated_by }}</b>
      </span>
      <!-- <span style="float: right">min {{ item.days }} days</span> -->
    </div>
    <div class="days"></div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: 'SingleChallenge',
  props: {
    item: Object,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profileImage {
  width: 60px;
  height: 60px;
  margin-right: 5px;
  float: left;
  text-align: center;
}
span.in_progress{
  color: white;
  background: green;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
}
div.clearfix {
  clear: both;
}
div.bottom {
  text-align: left;
  padding-left: 10px;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  span {
    padding-right: 10px;
    opacity: 0.8;
    /* opacity: 0.7; */
    font-size: 0.75rem;
    i.fa-comments {
      padding-right: 4px;
    }
  }
}
.timeago {
  font-size: 11px;
}
.challengeTitle {
  font-weight: bold;
  color: white;
  text-align: left;
  line-height: 20px;
  small {
    opacity: 0.5;
  }
}
.normalText {
  color: #b1b1b1;
  text-align: left;
}
div.wrap {
  /* box-shadow: 0px 0px 1px #ccc; */
  padding: 10px 5px;
  padding-bottom: 0px;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #ccc;
  /* border-radius: 5px; */
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: inset 0px 0px 38px 5px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 38px 5px rgba(0, 0, 0, 0.05);
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    filter: saturate(2);
  }
  smalL{
    font-size: 12px;
  }
}
i.absoluteIcon {
  color: black;
  position: absolute;
  right: 30px;
  font-size: 105px;
  top: -20px;
  opacity: 0.03;
}
div.days {
  position: absolute;
  color: white;
  font-size: 12px;
  right: 10px;
  bottom: 5px;
  opacity: 0.7;
}
</style>
