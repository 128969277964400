<template>
  <div class="wrap" :class="{ completed: item.completed }">
    <div
      class="middleContainer"
      :style="{
        background: item.background,
      }"
    >
      <div class="iconContainer">
        <i :class="item.icon" :style="{ color: item.color }"></i>
      </div>
      <div class="circleAbs">
        <van-circle
          v-model="currentRate"
          :class="{ active: showCircle }"
          :rate="progress"
          :speed="40"
          size="45px"
          :color="item.color"
          layer-color="#d6d6d6"
          style="margin: 3px"
          :text="text"
        />
      </div>
      <div class="todayText">
        <span v-for="(item, index) in habits" :key="index"
          ><i class="fas fa-circle"></i> {{ item.text }}</span
        >
      </div>
    </div>
    <div class="chalTitle">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'SingleChallenge',
  data() {
    return {
      currentRate: 0,
      showCircle: false,
      progress: 0,
      habits: [],
    }
  },
  props: {
    item: Object,
    selectedWeekday: Number,
  },
  watch: {
    'item.completed': function () {
      this.progress = parseInt(this.item.level) * 2

      if (this.item.completed) {
        this.showCircle = true
      }
      this.getCurrentHabits()
    },
    'item.level': function () {
      this.getCurrentHabits()
    },
  },
  computed: {
    text() {
      return this.item.level + ' lvl'
    },
  },
  methods: {
    getCurrentHabits() {
      switch (this.item.schedule_type) {
        case 'same':
          this.habits = this.item.schedule_options[0].habits
          break
        case 'days':
          this.habits = this.item.schedule_options.filter((block) => {
            const day =
              parseInt(this.item.level) +
              (this.item.locked || this.item.completed ? 0 : 1)
            return block.days.indexOf(day) > -1
          })[0].habits
          break
        case 'weekday':
          this.habits = this.item.schedule_options.filter(
            (block) => block.weekday.indexOf(this.selectedWeekday) > -1
          )[0].habits
          break
      }
    },
  },
  mounted() {
    if (this.item.completed) {
      this.showCircle = true
      this.streak = parseInt(this.item.streak)
    }
    this.getCurrentHabits()
  },
}
</script>
<style scoped lang="scss">
div.wrap {
  padding: 5px;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  /* background: yellow; */
  text-align: center;
  width: 210px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.2s border cubic-bezier(0.39, 0.575, 0.565, 1);
}
.middleContainer {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px #d6d6d6;
  border-radius: 10px;
  transition: 0.35s all cubic-bezier(0.39, 0.575, 0.565, 1);
  padding: 2px 0;
}
.iconContainer {
  width: 70px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  transition: 0.3s all ease-in-out;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  /* filter: grayscale(0); */
  opacity: 1;
  i {
    font-size: 25px;
    line-height: 50px;
  }
}
.circleAbs {
  position: absolute;
  top: 5px;
  left: 5px;
  transition: 0.35s all cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: scale(0.5) rotate(180deg);
  opacity: 0;
}
.wrap.completed {
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f5fff5;
  border: 1px solid #c2ecc2;
  .iconContainer {
    transform: scale(1.1) rotate(-180deg);
    /* filter: grayscale(1); */
    opacity: 0;
    border-radius: 25px !important;
  }
  .circleAbs {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  .middleContainer {
    box-shadow: 0px 0px 0px #d6d6d6;
    background: none !important;
  }
}
.chalTitle {
  font-weight: bold;
  color: #ffbb1f;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  max-width: 200px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.normalText {
  color: #d6d6d6;
  text-align: left;
}
div.todayText {
  font-size: 12px;
  text-align: left;
  margin-left: 12px;
  line-height: 15px;
  span {
    display: block;
  }
  width: 100%;
  i.fa-circle {
    font-size: 3px;
    vertical-align: middle;
    margin-right: 3px;
    margin-left: -9px;
  }
}
</style>
