<template>
  <div class="wrap">
    <div class="mainTitle">
      {{ item.title }}
      <i
        class="options is-pulled-right fas fa-ellipsis-v"
        @click="showOptions"
      ></i>
    </div>
    <div class="bottomText">
      {{item.time_from}} <i class="far fa-long-arrow-alt-right"></i> {{ item.time_to }}
      <DueTime v-if="item.due_time" :due_time="item.due_time" />
      <Category v-if="item.category" :category="item.category" />
      <Tag v-if="item.tag" :tag="item.tag" />
      <Comment v-if="item.comment" :comments-count="item.comments.length" />
      <Description v-if="item.description" :description="item.description" />
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import Comment from './small/Comment'
import Description from './small/Description'
import Category from './small/Category'
import Tag from './small/Tag'
export default {
  name: 'TodoSingleEvent',
  components: {
    Comment,
    Description,
    Category,
    Tag,
  },
  props: {
    item: Object,
  },
  methods: {
    showOptions() {
      this.$emit('selectItem', { type: 'event', id: this.item.id })
    },
  },
}
</script>
<style scoped lang="scss">
.checkBox {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  float: left;
  text-align: center;
}
div.clearfix {
  clear: both;
}
.timeago {
  font-size: 11px;
}
.author {
  font-weight: bold;
}
div.mainTitle {
  text-align: left;
  letter-spacing: 0.5px;
}
.bottomText {
  font-weight: 300;
  text-align: left;
  font-size: 15px;
}
div.wrap {
  box-shadow: 0px 0px 2px #e0e0e0;
  padding: 4px 15px;
  /* background: #f2fbff; */
  border-radius: 0px;
  margin-top: 4px;
  color: #444444;
  /* background: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(243,243,243,1) 100%); */
}
i.options {
  color: #ccc;
  min-width: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
