<template>
  <div id="screenPage">
    <van-empty image="error" description="Sorry, This page does not exist." />
    <b-button type="is-info is-light" class="mt-6" @click="goHome">Go back</b-button>
  </div>
</template>

<script>
export default {
  name: '404',
  components: {},
  methods: {
    goHome(){
      this.$router.push('/members/main')
    }
  }
}
</script>
<style scoped>

</style>
