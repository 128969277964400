import axiosConnection from '@/services/dbConnection'

export default {
  state: {
    invitation_code: '',
    token: null,
    quotes: [],
    temp_feed_items: [],
    temp_challenges_items: [],
    temp_forum_items: [],
    habits_dates_used: [],
    journals_dates_used: [],
    todo_dates_used: [],
  },
  getters: {},
  mutations: {
    ['SET_INVITATION_CODE'] (state, invitation_code) {
      state.invitation_code = invitation_code
    },
    ['SET_TOKEN'] (state, token) {
      state.token = token
      localStorage.setItem('token', token)
      axiosConnection.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`
    },
    ['LOGOUT'] (state) {
      state.token = null;
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
    },
    ['SET_LOADING'] (state, bool) {
      state.loading = bool;
    },
    ['SET_QUOTES'] (state, items) {
      state.quotes = items
    },
    ['SET_TEMP_FEED'] (state, items) {
      state.temp_feed_items = items
    },
    ['SET_TEMP_FORUM'] (state, items) {
      state.temp_forum_items = items
    },
    ['SET_TEMP_CHALLENGES'] (state, items) {
      state.temp_challenges_items = items
    },
    initialiseStore (state) {
      if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token')
        state.token = token
        axiosConnection.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`
      }
    }
  },

  actions: {
    setInvitationCode ({ commit }, code) {
      commit('SET_INVITATION_CODE', code)
    },
    setToken ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    logout ({ commit }) {
      commit('LOGOUT', true)
    },
    loading ({ commit }, bool) {
      commit('SET_LOADING', bool)
    },
    addQuotes ({ commit }, items) {
      commit('SET_QUOTES', items)
    },
    setTempFeed ({ commit }, items) {
      commit('SET_TEMP_FEED', items)
    },
    setTempForum ({ commit }, items) {
      commit('SET_TEMP_FORUM', items)
    },
    setTempChallenges ({ commit }, items) {
      commit('SET_TEMP_CHALLENGES', items)
    }
  }
}
