<template>
  <div class="wrap">
    <div class="checkBox" @click="toggle">
      <transition name="fade" mode="out-in">
        <div :key="item.completed">
          <i v-if="!item.completed" class="fal fa-circle"></i>
          <i v-if="item.completed" class="fal fa-check-circle"></i>
        </div>
      </transition>
    </div>
    <div class="mainTitle">
      {{ item.title }}
      <i
        class="options is-pulled-right fas fa-ellipsis-v"
        @click="showOptions"
      ></i>
    </div>
    <div class="bottomText">
      <DueTime v-if="item.due_time" :due_time="item.due_time" />
      <Category v-if="item.category" :category="item.category" />
      <Tag v-if="item.tag" :tag="item.tag" />
      <Comment v-if="item.comment" :comments-count="item.comments.length" />
      <Description v-if="item.description" :description="item.description" />
    </div>
    <div class="abs" :class="{ active: item.completed }"></div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import DueTime from './small/DueTime'
import Comment from './small/Comment'
import Description from './small/Description'
import Category from './small/Category'
import Tag from './small/Tag'
export default {
  name: 'TodoSingleTask',
  components: {
    DueTime,
    Comment,
    Description,
    Category,
    Tag,
  },
  props: {
    item: Object,
  },
  // watch: {
  //   'item.watch': function () {},
  // },
  methods: {
    toggle() {
      this.$emit('toggleTask', true)
    },
    showOptions() {
      this.$emit('selectItem', { type: 'task', id: this.item.id })
    },
  },
}
</script>
<style scoped lang="scss">
.checkBox {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  float: left;
  text-align: center;
  height: 100%;
  position: relative;
  z-index: 5;
  i {
    font-size: 25px;
    margin-top: 10px;
    color: #676767;
  }
}
div.clearfix {
  clear: both;
}
.timeago {
  font-size: 11px;
}
.author {
  font-weight: bold;
}
div.mainTitle {
  text-align: left;
  z-index: 4;
  position: relative;
}
.bottomText {
  font-weight: 300;
  text-align: left;
  line-height: 17px;
  padding-left: 4px;
}
div.wrap {
  //   box-shadow: 0px 0px 1px #ccc;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  padding-top: 8px;
  position: relative;
}
.fa-check-circle {
  color: green !important;
}
div.abs {
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #e7fff6;
  position: absolute;
  z-index: 0;
  transition: 0.3s ease;
  &.active {
    right: 0;
  }
}
i.options {
  color: #ccc;
  min-width: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
