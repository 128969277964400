import dbConnection from '../dbConnection'
const challenges = {
  getChallenges: function () {
    return dbConnection.get('members/challenges/')
  },
  getChallenge: function (id) {
    return dbConnection.get('members/challenges/single/' + id)
  },
  saveNewChallenge: function (obj) {
    return dbConnection.post('members/challenges/save', obj)
  },
  saveMessage: function (obj) {
    return dbConnection.post('members/challenges/saveMessage', obj)
  },
  challengeParticipation: function (obj) {
    return dbConnection.post('members/challenges/challengeParticipation', obj);
  }
}
export default challenges
