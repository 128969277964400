<template>
  <div class="tagDiv"><i class="fas fa-circle" style="font-size: 3px; vertical-align:middle"></i> <small>{{ tag }}</small></div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    tag: String,
  },
}
</script>
<style scoped lang="scss">
div.tagDiv {
  display: inline-block;
  color: #c19f6d;
  margin-right: 5px;
}
</style>
