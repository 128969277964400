<template>
  <div
    class="modal-content"
    style="width: 100%; overflow-x: hidden; padding: 0 10px"
  >
    <div
      class="hero"
      style="padding-top: 1px; margin-bottom: 15px; margin-top: 5px"
    >
      <h4 class="title is-4">New <small class="headerSmall">Workout</small></h4>
    </div>
    <b-tabs
      class="is-hidden-tablet"
      v-model="mobileView"
      style="margin-top: -15px; margin-bottom: -24px"
    >
      <b-tab-item
        label="General"
        value="General"
        icon="f fas fa-info"
      ></b-tab-item>
      <b-tab-item
        label="Workout"
        value="Workout"
        icon="f fas fa-running"
      ></b-tab-item>
      <b-tab-item
        label="Library"
        value="Library"
        icon="f fas fa-list-ol"
      ></b-tab-item>
    </b-tabs>
    <div class="columns is-1">
      <div
        class="column is-one-quarter noTopPadding is-hidden-mobile"
        :class="{ active: mobileView === 'General' }"
      >
        <div class="card">
          <div class="sectionLabel is-hidden-mobile">Info</div>
          <b-field label="Workout Name" style="margin-bottom: 0px">
            <b-input
              type="text"
              v-model="workout.title"
              placeholder="Workout name"
              maxlength="40"
            >
            </b-input>
          </b-field>
          <b-field label="Workout Description" style="margin-bottom: 0px">
            <b-input
              type="textarea"
              v-model="workout.description"
              placeholder="Description"
              maxlength="200"
            >
            </b-input>
          </b-field>
          <b-field label="Workout Body Group" style="margin-bottom: -5px">
            <b-input
              v-model="workout.body_group"
              type="text"
              @click.native="showWorkoutBodyTypePicker = true"
              placeholder="Workout body group"
              readonly
              maxlength="40"
            >
            </b-input>
          </b-field>
          <b-field label="Workout Equipment" style="margin-bottom: -5px">
            <b-input
              v-model="workout.equipment"
              type="text"
              @click.native="showWorkoutEquipmentPicker = true"
              placeholder="Workout Equipment required"
              readonly
              maxlength="40"
            >
            </b-input>
          </b-field>
          <b-field label="Default rest after exercise in seconds">
            <b-input
              v-model="workout.default_rest"
              type="number"
              placeholder="Default rest (seconds)"
              maxlength="4"
              max="1000"
            ></b-input>
          </b-field>
          <b-field label="Image">
            <Uploader
              :max-images="1"
              :images="workout.image ? [workout.image] : []"
              @uploaded="uploadedFile"
            />
          </b-field>
          <b-field label="Multiple difficulty levels">
            <van-switch
              v-model="levels"
              active-color="#07c160"
              inactive-color="#ccc"
              :active-value="1"
              type="is-info"
              :inactive-value="0"
            ></van-switch>
          </b-field>
          <div class="has-text-info is-size-7" v-if="levels">
            Example: beginner 50% would be half of normal% reps or time.
            Expert's 200% would mean double reps or time, etc.
          </div>
          <div class="columns" v-if="levels">
            <div class="column">
              <small class="is-size-7">Beginner %</small>
              <b-input
                type="number"
                v-model="workout.beginner"
                placeholder="<100%"
                max="99"
                min="1"
                maxlength="3"
                size="is-small"
              >
              </b-input>
            </div>
            <div class="column">
              <small class="is-size-7">Normal %</small>
              <b-input
                type="text"
                value="100%"
                placeholder="<100%"
                maxlength="3"
                :disabled="true"
                size="is-small"
              >
              </b-input>
            </div>
            <div class="column">
              <small class="is-size-7">Expert %</small>
              <b-input
                type="number"
                v-model="workout.expert"
                placeholder=">100%"
                maxlength="3"
                min="101"
                size="is-small"
              >
              </b-input>
            </div>
          </div>
          <div class="has-text-centered mt-3 mb-5">
            <b-button
              type="is-primary"
              icon-left="a far fa-check"
              :disabled="
                !workout.title ||
                !workout.description ||
                workout.items.length == 0
              "
              @click="submit"
              >Save</b-button
            >
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div
        class="column noTopPadding is-hidden-mobile"
        :class="{ active: mobileView === 'Workout' }"
      >
        <div class="card" style="padding: 5px 0">
          <div class="sectionLabel is-hidden-mobile pl-1">
            Workout
            <van-switch
              v-model="showSets"
              active-color="#07c160"
              inactive-color="#ee0a24"
              :active-value="1"
              type="is-info"
              size="17px"
              style="float: right; margin: 1px 3px 0px 3px"
              :inactive-value="0"
            ></van-switch>
            <small class="is-pulled-right has-text-grey">Show sets</small>
          </div>
          <div v-if="workout.items.length === 0" class="has-text-centered">
            <van-empty description="" />
            <small class="has-text-grey has-text-centered"
              >Add Exercises from a Library</small
            >
          </div>
          <div
            class="is-hidden-tablet"
            v-if="workout.items.length != 0"
            style="clear: both; height: 24px"
          >
            <van-switch
              v-model="showSets"
              active-color="#07c160"
              inactive-color="#ee0a24"
              :active-value="1"
              type="is-info"
              size="17px"
              style="float: right; margin: 1px 3px 0px 3px"
              :inactive-value="0"
            ></van-switch>
            <small class="is-pulled-right has-text-grey">Show sets</small>
          </div>
          <div>
            <draggable
              v-model="workout.items"
              group="list"
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              handle=".handle"
              class="listGroup"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <li
                  class="list-group-item workoutItem"
                  v-for="(element, index) in workout.items"
                  :key="index"
                >
                  <div style="display: flex">
                    <i class="far fa-arrows-alt-v handle firstLevelHandle"></i>
                    <div
                      v-if="element.multiple"
                      style="flex: 1"
                      class="superset"
                    >
                      <div class="supersetLabel">
                        {{ element.items.length > 3 ? 'Circuit' : 'Superset'
                        }}<i></i>
                      </div>
                      <draggable
                        v-model="element.items"
                        group="list2"
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                        handle=".handle"
                        class="listGroup superset"
                      >
                        <transition-group
                          type="transition"
                          :name="!drag ? 'flip-list' : null"
                        >
                          <li
                            class="list-group-item"
                            v-for="(supersetElement, index2) in element.items"
                            :key="index + '-' + index2"
                            style="
                              display: flex;
                              align-content: center;
                              align-items: center;
                              flex-wrap: wrap;
                            "
                          >
                            <i
                              class="
                                far
                                fa-arrows-alt-v
                                handle
                                secondLevelHandle
                              "
                            ></i>
                            <van-image
                              @click.stop="
                                showExercise(supersetElement, $event)
                              "
                              width="60"
                              height="60"
                              fit="cover"
                              lazy-load
                              :src="supersetElement.image"
                            /><span class="nameLabel">{{
                              supersetElement.name
                            }}</span>
                            <b-button
                              @click="
                                showOptions(supersetElement.name, index, index2)
                              "
                              class="repButton optionsBtn"
                              ><i class="fas fa-ellipsis-v"></i
                            ></b-button>
                            <div style="flex-basis: 100%">
                              <table v-if="showSets" class="table">
                                <thead>
                                  <tr>
                                    <td>
                                      Set
                                      <b-button
                                        class="repButton"
                                        @click="addSet(index, index2)"
                                        >+</b-button
                                      >
                                    </td>
                                    <td style="width: 40%">
                                      <b-button
                                        class="repButton"
                                        @click="changeSetType(index, index2)"
                                        ><span
                                          v-if="
                                            supersetElement.set_type !== 'time'
                                          "
                                          >Reps</span
                                        ><span
                                          v-if="
                                            supersetElement.set_type === 'time'
                                          "
                                          >Time</span
                                        ><i class="fas fa-exchange-alt"></i
                                      ></b-button>
                                    </td>
                                    <td style="max-width: 80px">
                                      Weight
                                      <b-button
                                        @click="addWeight(index, index2)"
                                        class="repButton addWeight"
                                        ><span
                                          v-if="
                                            supersetElement.show_weight === true
                                          "
                                          >-</span
                                        ><span
                                          v-if="
                                            supersetElement.show_weight !== true
                                          "
                                          >+</span
                                        ></b-button
                                      >
                                    </td>
                                    <td style="width: 20px"></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    :key="index + '-' + index2 + '-' + setIndex"
                                    v-for="(
                                      itm, setIndex
                                    ) in supersetElement.sets"
                                  >
                                    <td>
                                      <van-tag round type="primary">{{
                                        setIndex + 1
                                      }}</van-tag>
                                    </td>
                                    <td>
                                      <b-field>
                                        <b-input
                                          size="is-small"
                                          style="width: 50px"
                                          v-model="itm.value"
                                          expanded
                                        />
                                        <p class="control">
                                          <span
                                            v-if="
                                              supersetElement.set_type != 'time'
                                            "
                                            >reps</span
                                          ><span
                                            v-if="
                                              supersetElement.set_type ===
                                              'time'
                                            "
                                            >secs</span
                                          >
                                        </p>
                                      </b-field>
                                    </td>
                                    <td class="has-text-centered">
                                      <b-field
                                        v-if="
                                          supersetElement.show_weight === true
                                        "
                                      >
                                        <b-input
                                          type="text"
                                          size="is-small"
                                          style="max-width: 60px"
                                          v-model="itm.weight"
                                          expanded
                                        />
                                        <p class="control">g</p>
                                      </b-field>
                                    </td>
                                    <td>
                                      <b-button
                                        @click="
                                          removeSet(index, index2, setIndex)
                                        "
                                        class="repButton removeSet"
                                        >-</b-button
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </li></transition-group
                        ></draggable
                      >
                    </div>
                    <div
                      v-if="!element.multiple"
                      style="display: flex; align-items: center; flex: 1"
                    >
                      <van-image
                        @click.stop="showExercise(item, $event)"
                        width="70"
                        height="70"
                        fit="cover"
                        lazy-load
                        :src="element.image"
                        style="width: 70px"
                      />
                      <span class="nameLabel">{{ element.name }}</span>
                      <b-button
                        @click="showOptions(element.name, index)"
                        class="repButton optionsBtn"
                        ><i class="fas fa-ellipsis-v"></i
                      ></b-button>
                    </div>
                  </div>
                  <div v-if="!element.multiple">
                    <table class="table" v-if="showSets">
                      <thead>
                        <tr>
                          <td>
                            Set
                            <b-button class="repButton" @click="addSet(index)"
                              >+</b-button
                            >
                          </td>
                          <td>
                            <b-button
                              class="repButton"
                              @click="changeSetType(index)"
                              ><span v-if="element.set_type !== 'time'"
                                >Reps</span
                              ><span v-if="element.set_type === 'time'"
                                >Time</span
                              ><i class="fas fa-exchange-alt"></i
                            ></b-button>
                          </td>
                          <td style="max-width: ">
                            Weight
                            <b-button
                              @click="addWeight(index)"
                              class="repButton addWeight"
                              ><span v-if="element.show_weight === true">-</span
                              ><span v-if="element.show_weight != true"
                                >+</span
                              ></b-button
                            >
                          </td>
                          <td style="width: 20px"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          :key="index + '-' + setIndex"
                          v-for="(itm, setIndex) in element.sets"
                        >
                          <td>
                            <van-tag round type="primary">{{
                              setIndex + 1
                            }}</van-tag>
                          </td>
                          <td>
                            <b-field>
                              <b-input
                                size="is-small"
                                style="width: 50px"
                                v-model="itm.value"
                                expanded
                              />
                              <p class="control">
                                <span v-if="element.set_type != 'time'"
                                  >reps</span
                                ><span v-if="element.set_type === 'time'"
                                  >secs</span
                                >
                              </p>
                            </b-field>
                          </td>
                          <td>
                            <b-field v-if="element.show_weight === true">
                              <b-input
                                type="text"
                                v-model="itm.weight"
                                size="is-small"
                                style="max-width: 60px"
                                expanded
                              />
                              <p class="control">g</p>
                            </b-field>
                          </td>
                          <td>
                            <b-button
                              @click="removeSet(index, undefined, setIndex)"
                              class="repButton removeSet"
                              >-</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
      <div
        class="column noTopPadding is-hidden-mobile"
        :class="{ active: mobileView === 'Library' }"
      >
        <div class="card pl-0 pr-0" style="padding-bottom: 0px">
          <div
            class="sectionLabel is-hidden-mobile pl-1"
            style="margin-bottom: 0px"
          >
            Exercises Library
          </div>
          <div class="topcolumns">
            <div class="third">
              <b-input
                type="text"
                v-model="filterExercise"
                placeholder="Filter"
                icon-right="f fas fa-times"
                icon-right-clickable
                @icon-right-click="filterExercise = null"
              />
            </div>
            <div class="third">
              <van-popover
                v-model="showBodyTypes"
                trigger="click"
                :actions="bodyTypes"
                @select="onSelectBodyType"
                style="vertical-align: text-top; line-height: 35px"
              >
                <template #reference style="vertical-align: text-top">
                  <small class="headerSmall" style="padding: 5px">
                    {{ filterBody || 'All Body' }}
                    <i class="fas fa-caret-down"></i>
                  </small>
                </template>
              </van-popover>
            </div>
            <div class="third">
              <van-popover
                v-model="showTypes"
                trigger="click"
                :actions="types"
                @select="onSelectEquipment"
                style="vertical-align: text-top; line-height: 35px"
              >
                <template #reference style="vertical-align: text-top">
                  <small class="headerSmall" style="padding: 5px">
                    {{ filterEquipment || 'All Equipm' }}
                    <i class="fas fa-caret-down"></i>
                  </small>
                </template>
              </van-popover>
            </div>
          </div>
          <div style="max-height: 74vh; min-height: 74vh; overflow-y: scroll">
            <van-empty
              class="has-text-centered"
              v-if="filteredExercises.length === 0"
              description="No matches to your query"
            />
            <van-checkbox-group
              v-model="tempSelectedExercises"
              class="exercSelection"
            >
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in filteredExercises"
                  clickable
                  :key="index + 'e'"
                  :title="item.name"
                  @click="toggle(index)"
                >
                  <template #icon>
                    <van-image
                      @click.stop="showExercise(item, $event)"
                      width="70"
                      height="70"
                      fit="cover"
                      lazy-load
                      :src="item.image"
                    />
                  </template>
                  <template #right-icon>
                    <van-checkbox :name="item" ref="checkboxes" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
          </div>
          <div id="bottomDiv">
            <b-button
              v-if="tempActionOptions.action != 'add_to_superset'"
              type="is-light is-success"
              style="width: 40%"
              @click="addExercises"
              :disabled="tempSelectedExercises.length == 0"
              >+Add exercises<van-tag
                v-if="tempSelectedExercises.length > 0"
                type="success"
                style="margin-left: 2px"
              >
                {{ tempSelectedExercises.length }}</van-tag
              ></b-button
            >
            <b-button
              v-if="tempActionOptions.action != 'add_to_superset'"
              type="is-light is-success"
              style="width: 40%"
              @click="addAsSuperset"
              :disabled="tempSelectedExercises.length < 2"
              >Add as
              <span v-if="tempSelectedExercises.length < 4">Superset</span
              ><span v-if="tempSelectedExercises.length > 3">Circuit</span
              ><van-tag
                v-if="tempSelectedExercises.length > 1"
                type="success"
                style="margin-left: 2px"
              >
                {{ tempSelectedExercises.length }}</van-tag
              ></b-button
            >
            <b-button
              v-if="tempActionOptions.action != 'add_to_superset'"
              type="is-light is-danger"
              style="width: 20%"
              @click="confirmClear"
              :disabled="tempSelectedExercises.length == 0"
              ><i class="fal fa-trash"></i
              ><van-tag
                v-if="tempSelectedExercises.length > 0"
                type="danger"
                style="margin-left: 2px"
              >
                {{ tempSelectedExercises.length }}</van-tag
              ></b-button
            >
            <b-button
              v-if="tempActionOptions.action === 'add_to_superset'"
              type="is-success"
              style="width: 60%"
              @click="addExercises"
              :disabled="tempSelectedExercises.length == 0"
              >+Add to superset<van-tag
                v-if="tempSelectedExercises.length > 0"
                type="success"
                style="margin-left: 2px"
              >
                {{ tempSelectedExercises.length }}</van-tag
              ></b-button
            >
            <b-button
              v-if="tempActionOptions.action === 'add_to_superset'"
              type="is-light is-danger"
              style="width: 40%"
              @click="tempActionOptions = {}"
              >Cancel</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showWorkoutBodyTypePicker" round position="bottom">
      <van-picker
        title="Workout Muscle Group"
        show-toolbar
        :columns="bodyTypes"
        @cancel="showWorkoutBodyTypePicker = false"
        :default-index="
          bodyTypes.findIndex((i) => i.text === workout.body_group)
        "
        visible-item-count="9"
        @confirm="confirmMuscleGroup"
      />
    </van-popup>
    <van-popup v-model="showWorkoutEquipmentPicker" round position="bottom">
      <van-picker
        title="Workout Equipment required"
        show-toolbar
        :columns="types"
        :default-index="types.indexOf(workout.equipment)"
        visible-item-count="9"
        @cancel="showWorkoutEquipmentPicker = false"
        @confirm="confirmWorkoutEquipment"
      />
    </van-popup>
    <van-popup
      v-model="showActionsPopup"
      closeable
      :overlay="true"
      close-icon="f fas fa-times"
      position="bottom"
      get-container="body"
      :style="{ height: '120px', padding: '10px', 'text-align': 'center' }"
    >
      <div class="smallhero">
        <h4 class="title is-6 has-text-left" style="color: #f7b875">
          {{ tempActionOptions.title }}
        </h4>
      </div>
      <circular-button
        title="Replace"
        icon="fal fa-exchange"
        background="#f9f9f9"
        color="#808080"
        @click.native="action('replace')"
      />
      <circular-button
        v-if="tempActionOptions.isSuperset"
        title="Add to superset"
        icon="fal fa-plus"
        background="#f9f9f9"
        color="#8bbeff"
        @click.native="action('add_to_superset')"
      />
      <circular-button
        v-if="tempActionOptions.isSuperset"
        title="Break superset"
        icon="fas fa-stream"
        background="#f9f9f9"
        color="#d5adff"
        @click.native="action('break_superset')"
      />
      <circular-button
        v-if="!tempActionOptions.isSuperset"
        title="Make superset"
        icon="fas fa-layer-group"
        background="#f9f9f9"
        color="#8bbeff"
        @click.native="action('make_superset')"
      />
      <circular-button
        title="Remove"
        icon="fas fa-times"
        background="#f9f9f9"
        color="#ffadad"
        @click.native="action('remove')"
      />
    </van-popup>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import Uploader from '@/components/Uploader'
import CircularButton from '@/components/CircularButton'
import draggable from 'vuedraggable'
export default {
  name: 'WorkoutCreation',
  components: {
    CircularButton,
    draggable,
    Uploader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      workout: {
        default_rest: 60,
        items: [],
        body_group: 'Full body',
        equipment: 'None',
      },
      mobileView: 'General',
      tempActionOptions: {},
      youtubeId: false,
      drag: null,
      showSets: 1,
      tempSelectedExercises: [],
      myArray: [],
      filterExercise: null,
      filterBody: null,
      filterEquipment: null,
      showActionsPopup: false,
      showContent: false,
      levels: false,
      exercises: [],
      showBodyTypes: false,
      showTypes: false,
      showWorkoutBodyTypePicker: false,
      showWorkoutEquipmentPicker: false,
      bodyTypes: [
        { text: 'All Body' },
        { text: 'Abs' },
        { text: 'Arms' },
        { text: 'Back' },
        { text: 'Chest' },
        { text: 'Core' },
        { text: 'Legs' },
        { text: 'Shoulders' },
        { text: 'Upper body' },
        { text: 'Lower body' },
        { text: 'Full body' },
        { text: 'Calves' },
        { text: 'Glutes' },
      ],
      types: [
        { text: 'All Equipm' },
        { text: 'None' },
        { text: 'Barbell' },
        { text: 'Dumbbell' },
        { text: 'Kettlebell' },
        { text: 'Gym Equipm.' },
      ],
      // rawHtml: '<span>This is <b>fgd</b>a delfi</span>'
    }
  },
  computed: {
    user_id: function () {
      return 5
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    filteredExercises: function () {
      return this.exercises
        .filter((itm) => {
          if (this.filterBody && this.filterBody != 'All Body') {
            return itm.body_part.includes(this.filterBody)
          } else {
            return itm
          }
        })
        .filter((itm) => {
          if (this.filterEquipment && this.filterEquipment != 'All Equipm') {
            return itm.body_part.includes(this.filterEquipment)
          } else {
            return itm
          }
        })
        .filter((itm) => {
          if (this.filterExercise) {
            return itm.name.toLowerCase().includes(this.filterExercise)
          } else {
            return itm
          }
        })
    },
  },
  mounted() {
    this.loadExercises()
    this.container = this.$refs.container
  },
  methods: {
    addExercises() {
      if (this.tempActionOptions.action === 'add_to_superset') {
        this.workout.items[this.tempActionOptions.firstIndex].items = [
          ...JSON.parse(
            JSON.stringify(
              this.workout.items[this.tempActionOptions.firstIndex].items
            )
          ),
          ...JSON.parse(JSON.stringify(this.tempSelectedExercises)),
        ]
      } else {
        this.workout.items = [
          ...JSON.parse(JSON.stringify(this.workout.items)),
          ...JSON.parse(JSON.stringify(this.tempSelectedExercises)),
        ]
      }

      this.$nextTick(() => {
        this.tempSelectedExercises = []
        this.tempActionOptions = {}
        this.mobileView = 'Workout'
      })
    },
    addAsSuperset() {
      console.log('this', this.tempSelectedExercises)
      this.workout.items = [
        ...this.workout.items,
        {
          name: this.tempSelectedExercises.length > 3 ? 'Circuit' : 'Superset',
          multiple: true,
          items: [...JSON.parse(JSON.stringify(this.tempSelectedExercises))],
        },
      ]
      this.$nextTick(() => {
        this.tempSelectedExercises = []
      })
    },
    loadPostExtraInfo() {
      ApiService.posts.getPostExtraInfo(this.id).then((resp) => {
        this.habits = resp.data.habits
        this.comments = resp.data.comments
      })
    },
    loadExercises() {
      ApiService.workouts.getExercises().then((resp) => {
        const exercises = resp.data.exercises

        this.exercises = exercises.map((itm) => {
          itm.sets = [{}]
          return itm
        })
      })
    },
    toggle(index) {
      if (this.tempActionOptions.action === 'replace') {
        if ('supersetIndex' in this.tempActionOptions) {
          console.log('TempActions', this.tempActionOptions)
          this.workout.items[this.tempActionOptions.firstIndex].items[
            this.tempActionOptions.supersetIndex
          ] = this.filteredExercises[index]
        } else {
          this.workout.items[this.tempActionOptions.firstIndex] =
            this.filteredExercises[index]
        }
        this.$notify({ type: 'success', message: 'Replaced', duration: 1000 })
        this.mobileView = 'Workout'
      } else {
        this.$refs.checkboxes[index].toggle()
      }
    },
    showExercise(item, event) {
      event.preventDefault()
      ApiService.workouts.getSignedVideo(item.id)
    },
    onSelectBodyType(value) {
      this.filterBody = value.text
    },
    onSelectEquipment(value) {
      this.filterEquipment = value.text
    },
    confirmMuscleGroup(value) {
      this.workout.body_group = value.text
      this.showWorkoutBodyTypePicker = false
    },
    confirmWorkoutEquipment(value) {
      this.workout.equipment = value.text
      this.showWorkoutEquipmentPicker = false
    },
    changeSetType(firstIndex, supersetIndex) {
      if (supersetIndex === undefined) {
        let newSetType =
          this.workout.items[firstIndex].set_type == 'time' ? 'reps' : 'time'
        this.$set(this.workout.items[firstIndex], 'set_type', newSetType)
      } else {
        let newSetType =
          this.workout.items[firstIndex].items[supersetIndex].set_type == 'time'
            ? 'reps'
            : 'time'
        this.$set(
          this.workout.items[firstIndex].items[supersetIndex],
          'set_type',
          newSetType
        )
      }
    },
    addWeight(firstIndex, supersetIndex) {
      if (supersetIndex === undefined) {
        let showWeight =
          this.workout.items[firstIndex].show_weight == true ? false : true
        this.$set(this.workout.items[firstIndex], 'show_weight', showWeight)
      } else {
        let showWeight =
          this.workout.items[firstIndex].items[supersetIndex].show_weight ==
          true
            ? false
            : true
        this.$set(
          this.workout.items[firstIndex].items[supersetIndex],
          'show_weight',
          showWeight
        )
      }
    },
    addSet(firstIndex, supersetIndex) {
      if (supersetIndex === undefined) {
        this.workout.items[firstIndex].sets.push({})
      } else {
        this.workout.items[firstIndex].items.forEach((itm, supersetIndx) => {
          this.workout.items[firstIndex].items[supersetIndx].sets.push({})
        })
      }
    },
    removeSet(firstIndex, supersetIndex, setIndex) {
      if (supersetIndex === undefined) {
        this.workout.items[firstIndex].sets.splice(setIndex, 1)
      } else {
        this.workout.items[firstIndex].items.forEach((itm, supersetIndx) => {
          this.workout.items[firstIndex].items[supersetIndx].sets.splice(
            setIndex,
            1
          )
        })
      }
    },
    removeExercise(firstIndex, supersetIndex) {
      if (supersetIndex === undefined) {
        this.workout.items.splice(firstIndex, 1)
      } else {
        this.workout.items[firstIndex].items.splice(supersetIndex, 1)
      }
      this.$notify({ type: 'success', message: 'Removed', duration: 1000 })
    },
    showOptions(title, firstIndex, supersetIndex) {
      this.showActionsPopup = true
      if (supersetIndex === undefined) {
        this.tempActionOptions = {
          title: title,
          isSuperset: false,
          firstIndex: firstIndex,
        }
      } else {
        this.tempActionOptions = {
          title: title,
          isSuperset: true,
          firstIndex: firstIndex,
          supersetIndex: supersetIndex,
        }
      }
      console.log(firstIndex, supersetIndex)
      // firstIndex, supersetIndex
    },
    action(action) {
      switch (action) {
        case 'replace':
          // console.log('ham', this.tempActionOptions)
          this.tempActionOptions.action = 'replace'
          this.mobileView = 'Library'
          break
        case 'add_to_superset':
          this.tempActionOptions.action = 'add_to_superset'
          this.mobileView = 'Library'
          break
        case 'break_superset':
          break
        case 'make_superset':
          {
            const item = this.workout.items[this.tempActionOptions.firstIndex]
            this.workout.items[this.tempActionOptions.firstIndex] = {
              multiple: true,
              items: [item],
            }
          }
          break
        case 'remove':
          if (this.tempActionOptions.supersetIndex) {
            this.removeExercise(
              this.tempActionOptions.firstIndex,
              this.tempActionOptions.supersetIndex
            )
          } else {
            this.removeExercise(this.tempActionOptions.firstIndex)
          }
          break
      }
      if (action != 'add_to_superset' && action != 'replace') {
        this.tempActionOptions = {}
      }
      this.showActionsPopup = false
    },
    confirmClear() {
      this.$dialog
        .confirm({
          message: 'Would you like de-select exercises ?',
        })
        .then(() => {
          this.tempSelectedExercises = []
        })
    },
    uploadedFile(image) {
      console.log('Images', image)
      this.workout.image = image.filename
      this.workout.image_id = image.id
    },
    submit() {
      const obj = {
        title: this.workout.title,
        description: this.workout.description,
        body_group: this.workout.body_group,
        equipment: this.workout.equipment,
        data: this.workout.items,
        options: {
          default_rest: this.workout.default_rest,
          beginner: this.levels ? this.workout.beginner : null,
          expert: this.levels ? this.workout.expert : null,
        },
        image_id: this.workout.image_id ? this.workout.image_id : null,
      }
      ApiService.workouts.save(obj).then((resp) => {
        console.log('Resp', resp)
        this.$notify({ type: 'success', message: 'Saved', duration: 2000 })
        this.$parent.close()
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table.table {
  width: 95%;
  margin-left: 5%;
  background: none;
  thead {
    font-size: 14px !important;
    td {
      border-width: 0 0 1px;
    }
  }
  td {
    padding: 4px;
    border-color: #e2e2e2;
  }
  span.van-tag {
    margin-top: 8px;
    background-color: #91bd93;
  }
}
li.workoutItem {
  &:nth-child(odd) {
    background: #f3fcff;
  }
  /* background: red; */
  .list-group-item {
    /* background: white; */
  }
}

span.nameLabel {
  flex: 1;
  padding-left: 5px;
  font-weight: 200;
  font-size: 14px;
}
div.control.is-expanded {
  max-width: 80px;
}
p.control {
  background: #eeeeee;
  line-height: 28px;
  font-size: 12px;
  min-width: 30px;
  text-align: center;
  font-weight: 200;
}
.handle {
  padding: 5px;
  line-height: 70px;
  color: grey;
}
.column.noTopPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.listGroup {
  list-style: none;
}
div.sectionLabel {
  border-bottom: 1px solid #e4e4e4;
  color: #616161;
  font-weight: 500;
  margin-bottom: 15px;
}
div.columns div.card {
  padding: 5px;
  /* padding-left: 0px; */
  padding-top: 5px;
  padding-bottom: 0px;
  text-align: left;

  /* background: #f0f0f0; */
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #adcedd;
}
.list-group {
  min-height: 20px;
  list-style: none;
}
.list-group-item {
  text-align: left;
  min-height: 50px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  /* background: #ffffff; */
  /* margin-left: 10px; */
  /* cursor: move; */
}
.list-group-item i {
  cursor: pointer;
  vertical-align: top;
}
.superset .list-group-item {
  padding-top: 4px;
}
#bottomDiv {
  background: #ececec;
  height: 34px;
  margin-left: -5px;
  margin-right: -5px;
  /* position: fixed; */
  button {
    font-size: 14px !important;
  }
}
div.exerciseItem {
  height: 80px;
  border-bottom: 1px solid #f1f1f1;
  span {
    vertical-align: top;
    line-height: 80px;
    font-size: 14px;
    padding-left: 5px;
  }
}
div.exercSelection .van-cell {
  padding: 1px 10px 1px 0 !important;
  &:nth-child(odd) {
    background-color: #f3fcff;
  }
  &.van-cell--clickable:active {
    background-color: #f2f3f5 !important;
  }
}
div.exercSelection .van-cell__title {
  line-height: 20px;
  padding-left: 5px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  font-weight: 200;
}
div.topcolumns {
  margin-bottom: 5px;
  background: #f9f9f9;
  padding: 2px 0;
  border-bottom: 1px solid #f0f0f0;
  div.third {
    width: 33.3%;
    display: inline-block;
    text-align: center;
  }
}
div.superset {
  /* padding-left: 5px; */

  i.handle {
    font-size: 12px;
    line-height: 50px;
    color: #ffd280;
  }
  .list-group-item {
    border-color: #ffdc9c;
  }
}
div.is-hidden-mobile.active {
  display: block !important;
}
i.firstLevelHandle {
  /* position: absolute; */
  /* left: 0px; */
  /* width: 20px; */
  /* z-index: 9; */
  /* float: left; */
}
div.supersetLabel {
  font-weight: bold;
  font-size: 12px;
}
button.repButton {
  padding: 0 8px;
  height: auto;
  background: none;
  font-size: 14px;
  border: 1px solid #ececec !important;
  i {
    line-height: 22px;
    margin-top: 0px;
    margin-left: 3px;
    color: #b6b6b6;
  }
  &.addWeight {
    opacity: 0.5;
  }
  &.optionsBtn {
    border: none !important;
  }
}
</style>
