import dbConnection from './dbConnection'
import admin from './api/admin'
import posts from './api/posts'
import journal from './api/journal'
import challenges from './api/challenges'
import howto from './api/howto'
import forum from './api/forum'
import todo from './api/todo'
import food from './api/food'
import meditations from './api/meditations'
import workouts from './api/workouts'

const bookmarks = {
  getBookmarks: function () {
    return dbConnection.get('members/bookmarks/all')
  },
  saveBookmark: function (id, type) {
    return dbConnection.post('members/bookmarks/save', { id, type })
  }
}

const registration = {
  checkInvitation: function (code) {
    return dbConnection.post('register/checkInvitation', { code })
  },
  register: function (object) {
    return dbConnection.post('register', object)
  },
  getUserData: function () {
    return dbConnection.get('members/account/');
  },
  saveUserData: function (obj) {
    return dbConnection.post('members/account/save', obj);
  }
}

const authentication = {
  login: function (object) {
    return dbConnection.post('login', object)
  },
  forgotPassword: function(object){
    return dbConnection.post('guest/forgotPassword', object);
  },
  checkForgotPasswordCode: function(code){
    return dbConnection.get('guest/passwordResetCodeCheck/' + code);
  },
  resetPassword: function(obj){
    return dbConnection.post('guest/resetForgotPassword', obj);
  }
}

const feedback = {
  submitFeedback: function (object) {
    return dbConnection.post('/members/feedback/save', object)
  }
}

const comments = {
  saveComment: function (id, comment) {
    return dbConnection.post('members/comments/save', { id, comment })
  },
  register: function (object) {
    return dbConnection.post('register', object)
  }
}

const quotes = {
  getQuotes: function () {
    return dbConnection.get('members/quotes/')
  }
}

const breathing = {
  getAll: function () {
    return dbConnection.get('members/breathing/getAll')
  },
  saveBreathing: function (obj) {
    return dbConnection.post('members/breathing/save', obj)
  },
  deleteBreathing: function (id) {
    return dbConnection.delete('members/breathing/' + id)
  }
}

const habits = {
  savePostHabit: function (id, habitObj) {
    return dbConnection.post('members/habits/save', {
      id, ...habitObj
    })
  },
  saveUserHabit: function (habitObj) {
    // habitObj.options = {
    //   icon: habitObj.icon,
    //   background: habitObj.background,
    //   color: habitObj.color
    // }
    return dbConnection.post('members/habits/saveHabit', habitObj)
  },
  saveHabitsDay: function (date, completed, notCompleted, newTotals, completedChallenges) {
    return dbConnection.post('members/habits/saveHabitsDay', { date, completed, notCompleted, newTotals, completedChallenges })
  },
  getHabitsDay: function (date) {
    return dbConnection.get('members/habits/day/' + date)
  },
  getHabitStats: function (habitId) {
    return dbConnection.get('members/habits/stats/' + habitId)
  },
  delete: function (habitId) {
    return dbConnection.delete('members/habits/' + habitId);
  },
  getFilledDates: function (startDate, endDate) {
    return dbConnection.post('members/habits/getFilledDates', {
      start: startDate, end: endDate
    })
  }
}

const upload = {
  saveImage: function (image) {
    let fd = new FormData()
    fd.append('file', image, image.name)
    return dbConnection.post('members/attachments/saveImage', fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  saveAvatar: function (object) {
    return dbConnection.post('/members/attachments/saveAvatar', object);
  }
}

export default {
  posts,
  registration,
  quotes,
  comments,
  bookmarks,
  habits,
  journal,
  authentication,
  forum,
  challenges,
  howto,
  upload,
  admin,
  todo,
  food,
  meditations,
  workouts,
  feedback,
  breathing
}

// A 1 year and a bit ago
// an interesting company was born.
// Instead of doing the same old story,
// Idea was to change it as a new story.
// With more people looking what if,
// the world that we love is changing a bit.
// Too much new, too much waste
// and one day we might not find a way.

// We like what we do
// and we do our best,
// since we don't want to have any regrets.
// It's not just about a great pair or two,
// you'll see if you want for yourself aswell.
// Pre-loved, new or used,
// designer, high-street, quality too.
// If you're going to a special event,
// or you just want to impress.
// we only sell classy,
// but that's just a start,
// you'll see it yourself.
