<template>
  <div id="daysWrapper" :class="{ filter: filter }">
    <div id="prevButton" @click="loadMoreDays">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div
      v-for="(item, index) in dates"
      :key="index"
      class="animateOpacity"
      :class="{ active: item.fullDate == selected, today: item.today }"
      @click="selectDay(item.fullDate, item.wday)"
    >
      <span class="lbl">{{ item.label }}</span>
      <span v-if="item.day === 1" class="month">{{
        monthLabel(item.month)
      }}</span>
      <span class="day" :class="'status' + item.status">{{ item.day }}</span>
      <!-- <span v-if="item.status" >.</span> -->
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
export default {
  name: 'DaysWrapper',
  components: {},
  props: {
    dates: Array,
    selected: String,
    type: String,
    filter: String
  },
  data() {
    return {}
  },
  watch: {
    dates(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.animateDays()
        }, 10)
      }
    },
  },
  mounted() {
    this.animateDays()
  },
  methods: {
    selectDay(date, wday) {
      this.$emit('selected', { date, wday })
    },
    loadMoreDays() {
      this.$emit('loadMoreDays', this.dates[0].fullDate)
    },
    monthLabel(monthNumber) {
      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'june',
        'july',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ]
      return months[monthNumber]
    },
    animateDays() {
      anime({
        targets: '.animateOpacity',
        // translateX: 250,
        // rotate: '1turn',
        // backgroundColor: '#FFF',
        opacity: 1,
        delay: anime.stagger(10),
      })

      const screenWidth = window.innerWidth
      let scrollLeftValue = 370
      if (screenWidth > 800 && this.type != 'pastDatesOnly') {
        scrollLeftValue = 260
      } else if (this.type === 'pastDatesOnly') {
        scrollLeftValue = 9999
      } else if (screenWidth > 600) {
        scrollLeftValue = 150
      } else if (screenWidth > 475) {
        scrollLeftValue = 470
      }

      setTimeout(() => {
        document.getElementById('daysWrapper').scrollLeft = scrollLeftValue
      }, 10)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.animateOpacity {
  opacity: 0;
}
#prevButton {
  vertical-align: top;
  line-height: 40px;
  opacity: 1 !important;
  color: black !important;
}
#daysWrapper {
  &.filter{
    background: #e5f2ff;
  }
  /* overflow: hidden; */
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 7px;
  padding-top: 6px;
  div {
    min-width: 35px;
    padding: 5px 5px;
    margin: 0 4px;
    max-width: 20px !important;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.5s ease;
    border-radius: 10px;
    border: 2px solid transparent;
    position: relative;
    cursor: pointer;
    color: #bbb;
    &.today {
      border-radius: 20px;
      // border: 1px solid #d8d8d8;
      background: #f2f4ff !important;
    }
    &.active {
      color: black;
      background: rgb(237, 237, 237);
      border-radius: 20px;
      // box-shadow: 0px 0px 4px #bbb;
      border: 2px solid #d8d8d8;
    }
    span.lbl {
      display: block;
      padding-bottom: 4px;
    }
    span.month {
      display: block;
      position: absolute;
      color: orange;
      top: 17px;
      width: 22px;
      font-size: 10px;
      opacity: 0.8;
    }
    span.day {
      padding-top: 15px;
      font-weight: 400;
      font-size: 13px;
    }
    span.status1 {
      color: #eb5705;
      font-weight: 500;
    }
    span.status3 {
      color: #4eac01;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 600px) {
  #daysWrapper {
    div {
      margin: 0 6px;
    }
  }
}
@media only screen and (min-width: 800px) {
  #daysWrapper {
    div {
      margin: 0 6px;
    }
  }
}
</style>
