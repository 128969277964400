<template>
  <div class="cat"><i class="fas fa-circle" style="font-size: 3px; vertical-align:middle"></i> <small>Work</small></div>
</template>

<script>
export default {
  name: 'Category',
  props: {
    category: String,
  },
}
</script>
<style scoped lang="scss">
div.cat {
  display: inline-block;
  color: #0080ff;
  // font-weight: bold;
  margin-right: 5px;
}
</style>
