<template>
  <div
    class="card is-clearfix modalBg"
    style="padding-top: 1px; padding-bottom: 20px; min-height: 100vh"
  >
    <div class="pl-2">
      <div class="hero" style="padding-top: 1px">
        <h4 class="title is-4">
          New <small class="headerSmall">Task/Event</small>
        </h4>
      </div>
      <hr />
      <div class="is-clearfix">
        <form>
          <b-field label="">
            <b-button
              class="noborder is-small"
              :type="{ 'is-info': taskData.is_task === 1 }"
              @click="setAs('task')"
              ><i class="far fa-thumbtack"></i> Task</b-button
            >
            <b-button
              class="noborder is-small"
              :type="{ 'is-info': taskData.is_task === 0 }"
              style="margin-left: 10px"
              @click="setAs('event')"
              ><i class="far fa-calendar-plus"></i> Event</b-button
            >
          </b-field>
          <b-field label="Title" :type="!taskData.title? 'is-danger' : 'is-success'">
            <b-input
              v-model="taskData.title"
              placeholder="Title"
              class="is-danger"
              required
              maxlength="60"
            ></b-input>
          </b-field>
          <div style="text-align: left">
            <b-field id="datePicker" label="Date" class="displayInline">
              <b-button id="datePickerButton" @click="showCalendar = true"
                ><i class="far fa-calendar-week"></i> {{ taskData.date
                }}<span v-if="!taskData.date" class="has-text-grey-dark"
                  >Select date</span
                ></b-button
              >
              <van-calendar
                v-model="showCalendar"
                id="dateCalendar"
                color="#1989fa"
                @confirm="onConfirm"
                first-day-of-week="1"
              />
            </b-field>
            <transition name="fade">
              <b-field
                v-if="!taskData.is_task"
                id="displayTime"
                label="Time"
                class="displayInline"
              >
                <div id="timeDisplay">
                  <span>{{ taskData.time_from }}</span>
                  <i class="fas fa-long-arrow-right"></i>
                  <span>{{ taskData.time_to }}</span>
                </div>
              </b-field> </transition
            ><transition name="fade">
              <b-field
                v-if="!taskData.is_task"
                label="Zoom"
                class="displayInline"
                style="float: right"
              >
                <b-slider
                  id="zoomSlider"
                  v-model="timeZoomLevel"
                  style="max-width: 100px"
                  :min="1"
                  :max="5"
                  rounded
                  sized="is-large"
                  type="is-warning"
                  :custom-formatter="zoomFormatter"
                  @input="zoomDragging"
                ></b-slider>
              </b-field>
            </transition>
          </div>
          <transition name="fade">
            <b-field v-if="!taskData.is_task">
              <div
                id="timeSliderWrapper"
                style="
                  overflow-x: scroll;
                  width: 100%;
                  padding-top: 25px;
                  padding-bottom: 15px;
                "
              >
                <b-slider
                  id="timeSlider"
                  v-model="rangeValue"
                  :style="{ 'min-width': 100 * timeZoomLevel + '%' }"
                  tooltip-always
                  rounded
                  size="is-large"
                  type="is-info"
                  :min="0"
                  :max="288"
                  :step="1"
                  :custom-formatter="custFormatter"
                  @dragging="dragging"
                  @dragstart="dragStart"
                  @dragend="dragEnd"
                >
                  <template v-for="val in timeIntervals">
                    <b-slider-tick :key="val" :value="val"
                      >{{ val / 12 }}:00</b-slider-tick
                    >
                  </template>
                </b-slider>
              </div>
            </b-field>
          </transition>
          <!-- <b-field label="Category/Tags (Optional)" style="margin-top: 10px">
          <van-popover
            v-model="showCategoryPopover"
            trigger="click"
            :actions="categories"
            @select="selectCategory"
          >
            <template #reference>
              <b-button class="noborder noUnderline darkgrey" type="is-text">{{
                selectedCategory
              }}</b-button>
            </template>
          </van-popover>
          <van-popover
            v-model="showTagPopover"
            trigger="click"
            :actions="tags"
            @select="selectTag"
          >
            <template #reference>
              <b-button
                class="noborder noUnderline darkgrey"
                type="is-text"
                >{{ selectedTag }}</b-button
              >
            </template>
          </van-popover>
        </b-field> -->
          <b-field label="Description" style="margin-top: 10px">
          <b-input
            style="width: 100%"
            v-model="taskData.description"
            type="textarea"
            minlength="5"
            rows="2"
            maxlength="250"
            placeholder="Short description optional"
          ></b-input>
        </b-field>

          <hr />
          <div>
            <b-button
              type="is-primary is-light"
              @click="save"
              :disabled="!taskData.title"
              ><i class="far fa-check"></i> Save Task/Event</b-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
export default {
  name: 'TodoEventModal',
  data() {
    return {
      taskData: {
        title: '',
        is_task: 0,
      },
      selected: '',
      rangeValue: [84, 96],
      dragValue: [84, 96],
      sliderDragged: null,
      timeZoomLevel: 1,
      editHabitId: 0,
      checkbox: '',
      showCalendar: false,
      showCategoryPopover: false,
      showTagPopover: false,
      // actions: [
      //   { text: 'Option 1' },
      //   { text: 'Option 2' },
      //   { text: 'Option 3' },
      // ],
      timeIntervals: [
        0, 24, 48, 72, 96, 120, 144, 168, 192, 216, 240, 264, 288,
      ],
    }
  },
  props: ['item', 'categories', 'tags'],
  computed: {
    selectedCategory() {
      return this.taskData.category_id
        ? this.categories.filter((i) => i.id === this.taskData.category_id)[0]
            .text
        : 'Select Category'
    },
    selectedTag() {
      return this.taskData.tag_id
        ? this.tags.filter((i) => i.id === this.taskData.tag_id)[0].text
        : 'Select Tag'
    },
  },
  methods: {
    save() {
      ApiService.todo.save(this.taskData).then(() => {
        this.$notify({ type: 'success', message: 'Saved', duration: 1000 })
        this.$emit('close')
      })
    },
    setAs(type) {
      this.taskData.is_task = type === 'event' ? 0 : 1
      this.$forceUpdate()
    },
    custFormatter(totalMinutes) {
      const currentH = Math.floor(totalMinutes / 12)
      let minutes = totalMinutes - currentH * 12
      if (minutes != 0) {
        minutes = String(minutes * 5)
        if (minutes === '5') {
          minutes = '05'
        }
      } else {
        minutes = '00'
      }
      return String(`${currentH}:${minutes}`)
    },
    zoomFormatter(value) {
      return value + 'x'
    },
    dragging(value) {
      if (!this.sliderDragged) {
        if (value[0] != this.dragValue[0]) {
          this.sliderDragged = 'first'
        } else {
          this.sliderDragged = 'second'
        }
      }
      if (this.sliderDragged == 'first') {
        const diff = this.dragValue[1] - this.dragValue[0]
        this.rangeValue = [value[0], value[0] + diff]
      }
      this.$set(
        this.taskData,
        'time_from',
        this.custFormatter(this.rangeValue[0])
      )
      this.$set(
        this.taskData,
        'time_to',
        this.custFormatter(this.rangeValue[1])
      )
    },
    dragStart() {
      this.dragValue = this.rangeValue
    },
    zoomDragging() {
      setTimeout(() => {
        const leftElOffset = parseInt(el.style.left) / 100
        const scrollEl = document.querySelector('#timeSliderWrapper')
        const width = document.querySelector('#timeSlider').offsetWidth
        scrollEl.scrollLeft = width * (leftElOffset - 0.02)
      }, 50)
      const el = document.querySelector('#timeSlider .b-slider-thumb-wrapper')
    },
    dragEnd() {
      this.sliderDragged = null
    },
    onConfirm(value) {
      this.$set(
        this.taskData,
        'date',
        value.toLocaleDateString('en-CA').slice(0, 10)
      )
      this.taskData.timezone_offset = new Date().getTimezoneOffset()
      // alert(this.taskData.timezone_offset)
      this.showCalendar = false
    },
    selectCategory(obj) {
      this.$set(this.taskData, 'category_id', obj.id)
    },
    selectTag(obj) {
      this.$set(this.taskData, 'tag_id', obj.id)
    },
  },
  mounted() {
    if (this.item && this.item.id) {
      this.taskData = this.item
      // this.rangeValue = [d.getHours() * 12, toHour * 12]
    } else {
      const d = new Date()
      const toHour = d.getHours() == 24 ? 24 : d.getHours() + 1
      this.taskData = {
        title: '',
        is_task: 0,
        time_from: d.getHours() + ':00',
        time_to: toHour + ':00',
        date: d.toISOString().split('T')[0],
      }
      this.rangeValue = [d.getHours() * 12, toHour * 12]
      this.taskData.title = ''
      setTimeout(() => {
        this.$forceUpdate()
      }, 150)
    }
  },
}
</script>

<style scoped>
.title small {
  font-weight: 200;
}
div#timeDisplay {
  line-height: 35px;
}
div#timeDisplay span {
  font-weight: 200;
  font-size: 20px;
}
div#timeDisplay i {
  padding-left: 5px;
  padding-right: 5px;
}
label.label {
  opacity: 0.5;
}
.displayInline {
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
  min-width: 100px;
  text-align: left;
}
#dateCalendar {
  max-width: 500px;
}
.textarea,
.input {
  border-width: 0px;
}
</style>
<style>
#timeSliderWrapper {
  scroll-behavior: smooth;
}
#timeSlider .b-slider-thumb {
  height: 2.2rem;
  width: 1.3rem;
  line-height: 2.2rem;
  background: rgba(255, 255, 255, 0.85);
  border-width: 0px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #9a9a9a;

  margin-bottom: 20px;
}
/* #timeSlider .b-slider-thumb:after {
    z-index: 9;
    position: absolute;
    top: 31px;
    left: 6px;
    content: '';
    width: 0;
    height: 0;
    border-top: solid 5px #167df0;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
} */
#timeSlider .b-slider-track > .b-slider-thumb-wrapper .b-slider-thumb::before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f337';
}
#timeSlider > div > div:nth-child(15) .b-slider-thumb::before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f017' !important;
}
#timeSlider .tooltip-content {
  padding: 0.2rem 0.2rem;
  font-size: 10px;
  margin-bottom: -8px;
}
#timeSlider {
  min-width: 700px;
}
#timeSliderWrapper .b-slider.is-large .b-slider-track {
  height: 1rem;
  background: #ffffff !important;
}
#zoomSlider .b-slider-thumb::before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f055' !important;
}
#zoomSlider .b-slider-thumb {
  text-align: center;
  /* color: #167df0; */
  /* padding: 5px; */
  width: 30px;
  font-size: 20px;
  height: 30px;
}
#datePickerButton {
  /* background: none; */
  border: none;
  height: 2rem;
  /* padding-left: 0; */
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  #timeSlider .b-slider-tick:nth-child(odd) {
    visibility: hidden;
  }
  div#timeDisplay {
    line-height: 15px !important;
  }
  #datePicker {
    width: 100%;
  }
  .displayInline,
  .displayInline span {
    /* min-width: 50px !important;
    font-size: 14px !important; */
  }
}
</style>