<template>
  <div class="miniCalendar">
    <section style="max-width: 300px; margin: auto; min-height: 290px">
      <hr class="small" />
      <div class="is-center calendarHeader">
        <b-button @click="prevMonth"
          ><i class="fas fa-chevron-left"></i></b-button
        ><span>{{ months[currentMonth] }}, {{ currentYear }}</span>
        <b-button @click="nextMonth"
          ><i class="fas fa-chevron-right"></i
        ></b-button>
      </div>
      <div class="weekdays columns is-mobile">
        <div class="column">Mo</div>
        <div class="column">Tu</div>
        <div class="column">We</div>
        <div class="column">Th</div>
        <div class="column">Fr</div>
        <div class="column">Sa</div>
        <div class="column">Su</div>
      </div>
      <div class="days">
        <div :key="index" v-for="(day, index) in monthDays">
          <span :class="day.status">{{ day.day }}</span>
        </div>
      </div>
      <hr class="small" />
      <div class="info">
        <!-- <h3 class="title is-size-6">Streak: {{ habit.streak || 1 }}</h3> -->
        <h3 class="title is-size-6">Level: {{ habit.level || 1 }}</h3>
        <h3 class="title is-size-6">
          Progress:
          <van-progress
            :percentage="habit.level"
            stroke-width="8"
            style="margin-top: 8px"
          />
        </h3>

        <div class="columns">
          <div class="column">
            <b-button
              @click="confirmDeletion"
              type="is-danger is-light"
              expanded
              >Delete</b-button
            >
          </div>
          <div class="column">
            <b-button type="is-warning is-light" expanded
              >Don't show in future</b-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import ApiService from '@/services/ApiService'
export default {
  name: 'HabitStats',
  props: {
    habit: Object,
  },
  data() {
    return {
      day: '',
      completedDays: [],
      currentMonth: null,
      currentYear: null,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthDays: [],
    }
  },
  computed: {},
  watch: {
    habit: function () {
      ApiService.habits.getHabitStats(this.habit.id)
    },
  },
  mounted() {
    this.currentMonth = dayjs().get('month')
    this.currentYear = dayjs().get('year')
    this.generateDays()
    ApiService.habits.getHabitStats(this.habit.id).then((resp) => {
      this.completedDays = resp.data
      this.generateDays()
    })
  },
  methods: {
    generateDays() {
      this.monthDays = []
      const totalDays = dayjs()
        .year(this.currentYear)
        .month(this.currentMonth)
        .daysInMonth()

      const formatedMonth =
        this.currentMonth < 9
          ? '0' + (this.currentMonth + 1)
          : this.currentMonth + 1

      let loopDay = dayjs()
        .year(this.currentYear)
        .month(this.currentMonth)
        .day()
      if (loopDay == 0) {
        // Sunday
        for (let index = 0; index < 6; index++) {
          this.monthDays.push({ day: '' })
        }
      } else if (loopDay > 0) {
        for (let index = 1; index < loopDay; index++) {
          this.monthDays.push({ day: '' })
        }
      }
      for (let index = 1; index <= totalDays; index++) {
        let date =
          this.currentYear +
          '-' +
          formatedMonth +
          '-' +
          (index < 10 ? '0' : '') +
          index
        this.monthDays.push({
          day: index,
          fullDate: date,
          status: this.completedDays.indexOf(date) > -1 ? 'completed' : '',
        })
      }
      console.log('s', this.monthDays)
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0
        this.currentYear = this.currentYear + 1
      } else {
        this.currentMonth = this.currentMonth + 1
      }
      this.generateDays()
    },
    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11
        this.currentYear = this.currentYear - 1
      } else {
        this.currentMonth = this.currentMonth - 1
      }
      this.generateDays()
    },
    confirmDeletion() {
      this.$dialog
        .confirm({
          message:
            'Would you like to delete this Habit and it\'s data?',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
        .then(() => {
          ApiService.habits.delete(this.habit.id)
          this.$emit('deleted', true);
          // on cancel
        })
      return
    },
    // selectIcon(name, pack) {

    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.weekdays div {
  color: #7a7a7a;
  font-size: 14px;
}
.calendarHeader {
  line-height: 50px;
  button {
    border: 0px;
    color: #058aff;
    margin-top: 5px;
    i {
      font-size: 16px;
    }
  }
}
.days {
  max-width: 324px;
  margin: auto;
  text-align: left;
  margin-left: -12px;
  margin-right: -12px;
  div {
    display: inline-block;
    text-align: center;
    width: 14.285%;
    margin-bottom: 5px;
    span {
      display: inline-block;
      width: 27px;
      height: 27px;
      padding: 2px;
      border: 1px solid transparent;
      border-radius: 13px;
      font-size: 14px;
      &.completed {
        background: #afffae;
        border: 1px solid #89ef88;
      }
      &.missed {
        border: 1px solid #ffbebe;
      }
    }
  }
}

div.info {
  text-align: left;
  margin-top: 15px;
  h3 {
    border-bottom: 1px solid #f9f9f9;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}

.previewButton {
  padding: 5px;
  height: auto;
  border-radius: 10px !important;
  width: 42px;
  height: 42px;
}
.autocomplete .dropdown-content {
  max-height: 300px;
}
</style>
<style>
.autocomplete .dropdown-content {
  max-height: 300px;
  min-height: 290px !important;
}
</style>
