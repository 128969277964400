<template>
  <div class="wrap">
    <van-image
      @click.stop="showExercise(item, $event)"
      width="70"
      height="70"
      fit="cover"
      lazy-load
      :src="item.image"
    />
    <div class="middle" :class="{completed: completed}" style="flex: 1">
       <van-checkbox v-if="showCheckbox" @click="toggleComplete" :value="completed" checked-color="#00b147" class="checkbox"></van-checkbox>
      <div>{{ item.name }}</div>
      <div class="sets">
        <div class="set" :key="indx" v-for="(set, indx) in item.sets">
          <span v-if="item.set_type != 'time'"><i class="fal fa-repeat"></i> {{ set.value }}</span>
          <span v-if="item.set_type == 'time'"><i class="fal fa-clock"></i> {{ set.value }}s</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleExercise',
  data() {
    return {
      currentRate: 0,
      showCircle: false,
      progress: 0,
    }
  },
  props: {
    item: Object,
    showCheckbox: Boolean,
    completed: Boolean,
  },
  methods: {
    showExercise(item, $event){
      this.$emit('showExercise', item);
      console.log('ev', $event);
    },
    toggleComplete(){
      this.$emit('toggleComplete', this.item.completed ? false : true)
    }
  }
}
</script>
<style scoped lang="scss">
div.wrap {
  display: flex;
  border: 1px solid #ececec;
  border-bottom: none;
  position: relative;
  div{
    line-height: 20px;
    padding-right: 4px;
    color: #505050;
    font-size: 14px;
  }
  div.middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s all;
    &.completed{
      background:  #edffeb;
    }
  }
  div.set{
    display: inline-block;
    background: #cce5ff;
    border: 1px solid #b5d9ff;
    border-radius: 15px;
    padding: 1px 6px;
    font-size: 12px;
    margin: 2px;
    margin-right: 4px;
    min-width: 24px;
    text-align: center;
  }
  .checkbox{
    position: absolute;
    right: 0px;
    top: 4px;
  }
}
</style>
