<template>
  <div class="modal-content">
    <div class="card">
      <h1 class="title is-size-5" style="padding-left: 10px">
        Challenge: {{ item.title }}
      </h1>
      <div class="section" style="padding-top: 0px">
        <div id="topIcons">
          <!-- <i class="fas fa-users"> {{ usersCount }}</i> -->
          <i class="fas fa-comments"> {{ challenge.messages }}</i>
        </div>
        <ChallengeCalendarView
          :type="item.schedule_type"
          :data="item.schedule_options"
          :totalDays="item.days"
          :currentDay="item.level"
        />
        <div
          class="card mt-3 p-3 has-text-success	"
          v-if="(challenge.level === 0 || challenge.level > 0)"
        >
          You're participating in this challenge!
          <b-button
            type="is-info is-light"
            class="is-pulled-right"
            style="padding: 10px"
            @click="challengeParticipation('leave')"
            ><i class="fas fa-sign-out-alt" style="margin-top: 2px"></i> Leave this Challenge!</b-button
          >
        </div>
        <div
          class="card mt-3 p-3"
          v-if="challenge.level == null"
        >
          <b-button
            type="is-success is-light"
            style="padding: 10px"
            @click="challengeParticipation('join')"
            >Join this Challenge!</b-button
          >
        </div>
        <div>
          <h3 class="title is-size-6">Conversations:</h3>
          <transition-group name="slide">
            <SingleTopicMessage
              v-for="item in messages"
              :key="item.id"
              :item="item"
            />
          </transition-group>
        </div>
        <div>
          <hr />
          <b-input
            maxlength="500"
            type="textarea"
            v-model="newMessage"
            placeholder="Type here"
          ></b-input>
          <b-button
            type="is-primary is-light"
            @click="save"
            :disabled="!newMessage"
            ><i class="far fa-check"></i> Send Message</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import SingleTopicMessage from '@/components/SingleTopicMessage'
import ChallengeCalendarView from '@/components/ChallengeCalendarView'
export default {
  name: 'ChallengeModal',
  components: {
    SingleTopicMessage,
    ChallengeCalendarView,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      messages: [],
      challenge: {},
      newMessage: '',
    }
  },
  mounted() {
    console.log('PROPS', this.item)
    this.loadChallenge()
  },
  methods: {
    loadChallenge() {
      ApiService.challenges.getChallenge(this.item.id).then((resp) => {
        this.challenge = resp.data.challenge
        this.messages = resp.data.messages
      })
    },
    save() {
      const obj = {
        challenge_id: this.item.id,
        message: this.newMessage,
      }
      ApiService.challenges.saveMessage(obj).then(() => {
        this.newMessage = ''
        this.loadChallenge()
      })
    },
    challengeParticipation(newStatus) {
      ApiService.challenges
        .challengeParticipation({
          challenge_id: this.item.id,
          status: newStatus,
        })
        .then(() => {
          this.loadChallenge();
          // this.$notify({ type: 'success', message: 'Updated' })
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
h1 {
  padding-top: 10px;
  padding-left: 5px;
  margin-bottom: 1.5rem;
}
div.modal-content {
  margin: 0;
}
#topIcons {
  padding: 5px;
  i {
    color: #ffbf4a;
    padding: 5px;
  }
}
</style>
