<template>
  <div class="container" style="overflow: hidden">
    <div class="hero">
      <h4 class="title is-4">
        Add new section <small class="headerSmall">Journal</small>
      </h4>
    </div>

    <div class="columns is-centered addSection">
      <div class="column">
        <div
          class="inner noTabletHighlight"
          :class="{ active: selected === 'text' }"
          @click.prevent="select('text')"
        >
          <h3>Text</h3>
          <div class="inputDiv" v-if="selected === 'text'">
            <div class="titleLabel">Enter Title</div>
            <input
              v-model="newTitle"
              type="text"
              class="input"
              :disabled="selected != 'text'"
              placeholder="Type here"
            />
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="!newTitle"
              @click="addSection"
            >Add Section</b-button>
          </div>
          <p>
            For text content, for example: 'I'm grateful for, What I've learned
            today, Today was beautiful because etc...'<br />
            <textarea
              disabled
              placeholder="Text content"
              style="padding: 3px; user"
            ></textarea>
          </p>
        </div>
      </div>
      <div class="column">
        <div
          class="inner noTabletHighlight"
          :class="{ active: selected === 'emoji' }"
          @click.prevent="select('emoji')"
        >
          <h3>Emoji (mood)</h3>
          <div class="inputDiv" v-if="selected === 'emoji'">
            <div class="titleLabel">Enter Title</div>
            <input
              v-model="newTitle"
              type="text"
              class="input"
              :disabled="selected != 'emoji'"
              placeholder="Type here"
            />
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="!newTitle"
              @click="addSection"
            >Add Section</b-button>
          </div>
          <p>
            For emoji/mood/rating content, for example: 'How are you feeling,
            How was your progress etc...' Can be used for stats.<br />
            <span class="icon">
              <i class="far fa-laugh-beam" style="color: green"></i>
              <span class="lbl" style="color: green">great</span>
            </span>
            <span class="icon">
              <i class="far fa-smile-beam" style="color: #e0d434"></i>
              <span class="lbl" style="color: #e0d434">good</span>
            </span>
            <span class="icon">
              <i class="far fa-meh" style="color: #f7c44d"></i>
              <span class="lbl" style="color: #f7c44d">meh</span>
            </span>
            <span class="icon">
              <i class="far fa-frown-open" style="color: #f77d4d"></i>
              <span class="lbl" style="color: #f77d4d">bad</span>
            </span>
            <span class="icon">
              <i class="far fa-tired" style="color: #2c3e50"></i>
              <span class="lbl" style="color: #2c3e50">awful</span>
            </span>
          </p>
        </div>
      </div>
      <div class="column">
        <div
          class="inner noTabletHighlight"
          :class="{ active: selected === 'yes/no' }"
          @click.prevent="select('yes/no')"
        >
          <h3>Yes/No</h3>
          <div class="inputDiv" v-if="selected === 'yes/no'">
            <div class="titleLabel">Enter Title</div>
            <input
              v-model="newTitle"
              type="text"
              class="input"
              :disabled="selected != 'yes/no'"
              placeholder="Type here"
            />
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="!newTitle"
              @click="addSection"
            >Add Section</b-button>
          </div>
          <p>
            For simple Yes/No questions, for example: 'Did you finish your
            tasks, Did you have a productive day etc...' Can be used for stats.
            <br />
            <span class="radio"><i class="fas fa-check-circle"></i> Yes</span>
            <span class="radio"><i class="far fa-circle"></i> No</span>
          </p>
        </div>
      </div>
      <div class="column">
        <div
          class="inner noTabletHighlight"
          :class="{ active: selected === 'number' }"
          @click.prevent="select('number')"
        >
          <h3>Number</h3>
          <div class="inputDiv" v-if="selected === 'number'">
            <div class="titleLabel">Enter Title</div>
            <input
              v-model="newTitle"
              type="text"
              class="input"
              :disabled="selected != 'number'"
              placeholder="Type here"
            />
            <b-button
              v-show="selected === 'number'"
              type="is-primary"
              size="is-small"
              :disabled="!newTitle"
              @click="addSection"
            >Add Section</b-button>
          </div>
          <p>
            For number values questions, for example: 'My weight, saved money,
            workouts done etc...' Can be used for stats.<br />
            <input
              type="number"
              disabled
              class="input"
              size="5"
              maxlength="5"
              placeholder="Number"
              style="font-size: 14px; width: 100px"
            />
          </p>
        </div>
      </div>
      <div class="column">
        <div
          class="inner noTabletHighlight"
          :class="{ active: selected === 'image' }"
          @click.prevent="select('image')"
        >
          <h3>Image</h3>
          <div class="inputDiv" v-if="selected === 'image'">
            <div class="titleLabel">Enter Title</div>
            <input
              v-model="newTitle"
              type="text"
              class="input"
              :disabled="selected != 'image'"
              placeholder="Type here"
            />
            <b-button
              type="is-primary"
              size="is-small"
              :disabled="!newTitle"
              @click="addSection"
            >Add Section</b-button>
          </div>
          <p>
            For tracking your progress, on yourself, or other goals: 'My weight
            loss/gain in progress, visual task progress, etc...'<br />
          </p>
          <div class="van-uploader__upload" style="">
            <i
              class="van-icon van-icon- fas fa-camera van-uploader__upload-icon"
            ><!----></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JournalAddSection',
  data() {
    return {
      selected: null,
      newTitle: '',
    }
  },
  mounted() {},
  methods: {
    select(type) {
      this.selected = type
      this.newTitle = '';
    },
    addSection() {
      this.$emit('addSection', { type: this.selected, title: this.newTitle })
      this.newTitle = ''
    },
  },
}
</script>
<style lang="scss" scoped>
div.addSection {
  min-height: 175px;
  h3 {
    // font-size: 16px;
    // font-weight: 300;
    font-weight: bold;
    font-size: 14px;
    // text-decoration: underline;
  }
  div.column {
    padding-bottom: 0px;
    padding: 4px 2px;
  }
  div.titleLabel {
    color: #87919d;
    font-size: 12px;
    margin-top: 10px;
  }
  div.inner {
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: all 0.35s;
    position: relative;
    min-height: 140px;
    cursor: pointer;
    p {
      transition: all 0.35s;
    }
    span.radio {
      color: #058aff;
      padding-right: 10px;
      font-size: 18px;
    }
    div.van-uploader__upload {
      width: 30px;
      height: 30px;
      margin: auto;
      transition: all 0.35s;
    }
    div.inputDiv {
      position: absolute;
      opacity: 0;
      transition: all 0.35s;
      width: 100%;
      transform: translateY(-10px);
      .input {
        padding: 4px;
        font-size: 12px;
      }
      button {
        margin-top: 10px;
      }
    }
    &.active {
      border-color: #c7e7f4;
      background-color: #e4f7ff;
      p {
        opacity: 0;
        transform: translateY(10px);
      }
      div.inputDiv {
        opacity: 1;
        transform: translateY(0px);
        z-index: 999;
      }
      div.van-uploader__upload {
        opacity: 0;
      }
    }
    p {
      font-size: 12px;
      color: #7b7b7b;
      padding: 5px;
    }
  }
  span.icon {
    display: inline-block;
    margin: 0 3px;
    i {
      font-size: 20px;
    }
    .lbl {
      display: block;
      font-size: 11px;
    }
  }
}
</style>
