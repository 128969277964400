<template>
  <div class="caledarWrap">
    <div
      class="singleDay"
      v-for="(day, index) in totalDays"
      :key="day"
      :class="{ completed: currentDay >= day }"
      :style="{
        background: index > 25 ? colorArray[index - 25] : colorArray[index],
      }"
    >
      <span class="dayLabel">Day {{ day }} </span>
      <i class="fas fa-check-circle completedDay"></i>
      <div class="habits">
        <div
          class="singleHabit"
          v-for="(habit, index2) in habits(day)"
          :key="index2"
        >
          <i class="fas fa-circle"></i> {{ habit.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeCalendarView',
  props: {
    type: String,
    data: Array,
    totalDays: Number,
    currentDay: Number,
  },
  data() {
    return {
      convertedData: {},
      colorArray: [
        '#daf4e9',
        '#fce8fd',
        '#f2fee0',
        '#ffeafc',
        '#defbe4',
        '#efecff',
        '#f2f0d1',
        '#d2f5ff',
        '#fff8db',
        '#cdfcf9',
        '#ffecf2',
        '#cdf8ec',
        '#f9edd5',
        '#d5fbeb',
        '#faf7ff',
        '#def5db',
        '#fbffff',
        '#daf5de',
        '#fffef5',
        '#d6f5e9',
        '#f6eed4',
        '#e7ffff',
        '#ecf1d6',
        '#e5fffa',
        '#f8ffe6',
        '#e3f1f0',
        '#e7f2df',
        '#e2fff7',
        '#edf0e3',
        '#f8fff3',
      ],
      //   type: this.initial,
    }
  },
  computed: {
    // habits() {
    //   // console.log(day)
    //   return [{ text: 'ham' }]
    // },
  },
  mounted() {},
  methods: {
    habits(day) {
      if (this.type === 'days') {
        const habits = this.data.filter((hab) => hab.days.indexOf(day) > -1)
        console.log(habits)
        return habits.length > 0 ? habits[0].habits : []
      }
    },
  },
}
</script>
<style scoped lang="scss">
div.caledarWrap {
  display: flex;
  flex-wrap: wrap;
  span.dayLabel {
    font-weight: bold;
    font-size: 14px;
  }
  div.singleDay {
    border: 1px solid #f1f1f1;
    background: #f2fbff;
    display: inline-block;
    width: 190px;
    min-height: 65px;
    padding: 0 0px 10px 10px;
    i.completedDay {
      display: none;
    }
    &.completed {
      background: #dbffdb !important;
      i.completedDay {
        display: inline-block;
        /* float: right; */
        padding: 3px;
        color: #0fb62b;
      }
    }
    &:nth-child(odd) {
      background: #fdfdfd;
    }
    div.singleHabit {
      font-size: 11px;
      i {
        font-size: 4px;
        vertical-align: middle;
      }
    }
  }
}
</style>
