<template>
  <div id="limitedMainDiv">
    <div class="hero">
      <h4 class="title is-4">
        Courses <small class="headerSmall">(Coming soon)</small>
      </h4>
    </div>
    <!-- <h4 class="title is-6">Coaches</h4> -->
    <div id="coaches" style="display: none">
      <b-carousel-list
        v-model="carousel"
        :data="coaches"
        :items-to-show="itemsToShow"
      >
        <template slot="item" slot-scope="props">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <a @click="info(props.index)">
                  <img :src="props.list.image" />
                </a>
              </figure>
              <!-- <van-tag type="is-danger" rounded style="position: absolute; top: 0;">
                <b>50%</b>
              </van-tag>-->
            </div>
            <div class="card-content">
              <div class="content">
                <p class="title is-6">{{ props.list.title }}</p>
                <p class="subtitle is-7">@{{ props.list.social_handle }}</p>
                <div class="field is-grouped">
                  <span class="is-size-7">{{ props.list.specialist }}</span>
                  <p class="control" style="margin-left: auto">
                    <!-- <button class="button is-small is-danger is-outlined">
                      <b-icon size="is-small" icon="heart" />
                    </button>-->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-list>
    </div>
    <!-- <hr /> -->
    <!-- <h4 class="title is-6">Courses</h4> -->
    <div
      v-for="item in items"
      :key="item.id"
      ref="content"
      class="card-content"
    >
      <!-- style content how ever you like -->
      <h2 class="has-text-left entryTitle is-size-6">{{ item.title }}</h2>
      <p class="has-text-left bottomIcons">
        <span>
          <b-icon pack="fas" icon="th-list" size="is-small"></b-icon>
          {{ item.lessons }} lessons
        </span>
        <span class="is-pulled-right">
          <b-icon pack="far" icon="clock" size="is-small"></b-icon>
          {{ item.time }}
        </span>
        <!-- <span>
          <b-icon pack="fas" icon="comments" size="is-small"></b-icon>5
        </span>-->
      </p>
      <van-progress
        :percentage="0"
        color="#6ab8f2"
        style="margin-top: 5px"
      ></van-progress>
    </div>
    <hr style="margin-top:0px">
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    // SwipeOut,
    // SwipeList
  },
  data() {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: '',
      itemsToShow: window.innerWidth > 600 ? 4 : 2,
      carousel: 0,
      items: [
        {
          id: 'a3',
          title: 'Time management fundamentals',
          description: 'some description',
          disabled: false,
          lessons: 12,
        },
        {
          id: 'a23',
          title: 'Self defense for ladies',
          description: 'some description',
          disabled: false,
          lessons: 15,
        },
        {
          id: 'b',
          title: 'How to meditate?',
          description: 'some description',
          disabled: false,
          lessons: 12,
        },
        // {
        //   id: 'Don',
        //   title: 'Stylist tips & tricks to dress',
        //   description: 'some description',
        //   disabled: false,
        //   lessons: 15,
        // },
        {
          id: 'a',
          title: 'How to wear high heels?',
          description: 'some description',
          disabled: false,
        },
      ],
      coaches: [
        {
          title: 'Loren Name',
          image: 'https://placeimg.com/300/300/people',
          social_handle: 'loren',
          specialist: 'Style',
        },
        {
          title: 'Dania Olburn',
          image: 'https://placeimg.com/300/305/people',
          social_handle: 'daniaO',
          specialist: 'Meditation',
        },
        {
          title: 'Kelly Mittow',
          image: 'https://placeimg.com/300/310/people',
          social_handle: 'kellyM',
          specialist: 'Style',
        },
        {
          title: 'Robby Harward',
          image: 'https://placeimg.com/300/325/people',
          social_handle: 'RobbyH',
          specialist: 'Fitness',
        },
        {
          title: 'Susan Johnson',
          image: 'https://placeimg.com/300/315/people',
          social_handle: 'susan',
          specialist: 'Relationship',
        },
      ],
    }
  },
  mounted() {
    // ideally should be in some global handler/store
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    if (window.innerWidth > 600) {
      this.itemsToShow = 4
      this.$forceUpdate()
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    dd(v) {
      console.log(v.target)
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0)
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0)
    },
    closeFirst() {
      this.$refs.list.closeActions(0)
    },
    closeAll() {
      this.$refs.list.closeActions()
    },
    remove(item) {
      this.$set(
        this.mockSwipeList,
        this.page,
        this.mockSwipeList[this.page].filter((i) => i !== item)
      )
    },
    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id,
      }
    },
    itemClick(e) {
      console.log(e, 'item click')
      alert('yeeha')
    },
    fbClick(e) {
      console.log(e, 'First Button Click')
    },
    sbClick(e) {
      console.log(e, 'Second Button Click')
    },
    // keyboard
    onKeyDown(e) {
      if (e.keyCode !== 16) return
      this.enabled = false
    },
    onKeyUp(e) {
      if (e.keyCode !== 16) return
      this.enabled = true
    },
  },
}
</script>
<style scoped>
#screenPage {
  max-width: 800px;
  margin: auto;
}
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  border-bottom: 0px;
  /* margin-bottom: 10px; */
}
.card-content.small {
  max-width: 200px;
  padding: 0px;
}
.bottomIcons {
  color: #bbbbbb !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
#coaches div.card-content {
  background: #f9f9f9;
  text-align: left;
}
figure {
  margin: 0px;
}
</style>
