import dbConnection from '../dbConnection'
const howto = {
  getHowto: function() {
    return dbConnection.get('members/howto/all')
  },
  getSingleHowto: function(id) {
    return dbConnection.get('members/howto/single/' + id)
  },
  saveHowto: function(json) {
    return dbConnection.post('members/howto/save', json)
  },
  getHowtoTopic: function(id) {
    return dbConnection.get('members/howto/' + id)
  },
  saveProblem: function(obj) {
    return dbConnection.post('members/howto/saveProblem', obj)
  },
  saveSolution: function(obj) {
    return dbConnection.post('members/howto/saveSolution', obj)
  }
}
export default howto