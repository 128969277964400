<template>
  <div class="wrap">
    <div class="profileImage">
      <i
        v-if="!item.image"
        class="fas fa-image"
        style="font-size: 30px; color: grey; line-height: 50px"
      ></i>
      <img v-if="item.image" :src="item.image" />
    </div>
    <div class="mainTitle">
      {{ item.title }}
    </div>
    <div class="normalText">{{ item.section }}</div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: 'SingleHowTo',
  props: {
    item: Object,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profileImage {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  float: left;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
div.clearfix {
  clear: both;
}
.timeago {
  font-size: 11px;
}
.author {
  font-weight: bold;
}
div.mainTitle {
  text-align: left;
}
.normalText {
  font-weight: 300;
  text-align: left;
}
div.wrap {
  box-shadow: 0px 0px 1px #ccc;
  // padding: 3px;
}
</style>
