<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">Healthy Food</h4>
    </div>
    <section class="section2">
      <div class="columns is-mobile">
        <div class="column">
          <div class="box" id="foodTrackingButton">
            <b-button
              rounded
              class="circButton"
              @click="
                showFoodTracking = true
                lockBodyScroll = true
              "
              ><i class="fas fa-barcode" style="font-size: 30px"></i> Food<br />
              Tracking</b-button
            >
          </div>
        </div>
        <div class="column">
          <div class="box" id="goalsButton" style="opacity: 0.3">
            <b-button rounded class="circButton" :disabled="true"
              ><i
                class="fal fa-trophy"
                style="font-size: 30px; color: gold"
              ></i>
              Insights<br />
              Goals</b-button
            >
          </div>
        </div>
        <div class="column">
          <div class="box" id="helpfulHabitsButton">
            <b-button
              rounded
              class="circButton"
              @click="goto('healthy_eating/habits')"
              ><i
                class="fal fa-lightbulb"
                style="font-size: 30px; color: green"
              ></i>
              Helpful<br />
              Habits
            </b-button>
          </div>
        </div>
        <div class="column">
          <div class="box" id="easyWinsButton">
            <b-button
              rounded
              class="circButton"
              @click="goto('healthy_eating/easy_wins')"
              ><i
                class="fal fa-thumbs-up"
                style="font-size: 30px; color: darkblue"
              ></i>
              Easy<br />
              Wins
            </b-button>
          </div>
        </div>
      </div>
      <transition-group
        name="fade"
        mode="out-in"
        tag="div"
        class="columns is-centered is-multiline contentBlocks"
      >
        <Tile
          v-for="item in items"
          :key="item.id"
          :item="item"
          class="column is-one-third"
          @click.native="$parent.openModal(item)"
        />
      </transition-group>
      <!-- <h4 class="title is-5 fontWeight400">Meals</h4>
      <div id="selectDiet"></div>
      <div id="meals" class="horizontalSlider">
        <SingleBlock
          @click.native="gotoMeal(5)"
          image="https://placekitten.com/g/300/300"
          text="This is a test2"
        />
        <SingleBlock />
      </div>
      <h4 class="title is-5 fontWeight400">Interesting to know</h4>
      <div id="goodtoknow" class="horizontalSlider">
        <SingleBlock
          @click.native="gotoKnowledge(5)"
          image="https://placekitten.com/g/382/382"
          text="This is a test2"
        />
        <SingleBlock />
      </div> -->
      <van-popup
        v-model="showFoodTracking"
        closeable
        close-icon="f fas fa-times"
        position="bottom"
        style="min-height: 95%; max-height: 95vh; overflow: hidden"
        ><FoodTracking v-scroll-lock="lockBodyScroll"
      /></van-popup>
      <van-popup
        v-model="show"
        closeable
        close-icon="f fas fa-times"
        position="bottom"
        style="height: 90%"
        >Content here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsf<br />Content here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />Content
        here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsf sadfasdf dsfContent here<br />dfsdf<br />dfsdfs sdfsdf<br />
        sadfasdf dsf</van-popup
      >
      <!-- <h4 class="title is-5 fontWeight400">Healthier alternatives</h4>
      <div id="alternatives" class="horizontalSlider">
        <SingleBlock />
        <SingleBlock />
      </div>
      <h4 class="title is-5 fontWeight400">Deals</h4>
      <div id="deals" class="horizontalSlider">
        <SingleBlock />
        <SingleBlock />
        <SingleBlock />
      </div> -->
    </section>
  </div>
</template>
<script>
// import SingleBlock from '@/components/SingleSmallBlock'
import FoodTracking from '@/components/modals/FoodTracking'
import Tile from '@/components/tiles/Tile'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'
// import ApiService from '@/services/ApiService'
export default {
  name: 'HealthyEating',
  data() {
    return {
      show: false,
      showFoodTracking: false,
      lockBodyScroll: false,
      currentPage: 0,
      items: [],
    }
  },
  components: { FoodTracking, Tile },
  methods: {
    gotoMeal(id) {
      this.show = true
      console.log('id', id)
    },
    goto(page) {
      this.$router.push(page)
    },
    getPosts() {
      ApiService.posts
        .getPostsSubCategory('healthy_eating', this.currentPage)
        .then((response) => {
          var items = response.data.slice(0, 20)
          items = updatePosts(items)
          this.items = items
        })
    },
  },
  mounted() {
    this.getPosts()
  },
}
</script>
<style scoped lang="scss">
div.box {
  /* padding: 15px; */
  background: #fdfdfd;
  transition: 0.2s all;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  &:hover,
  &:focus {
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
      0 0px 0 1px rgb(10 10 10 / 2%);
    box-shadow: 0 0.1em 1em -0.125em rgb(10 10 10 / 5%),
      0 0px 0 1px rgb(10 10 10 / 2%);
  }
  i {
    display: block;
    margin-bottom: 5px;
  }
  button {
    background: none;
    border: none;
    width: 100%;
    height: 100px;
    font-size: 12px;
    padding: 15px;
  }
}
@media screen and (max-width: 768px) {
  .columns.is-mobile {
    margin-left: 0px;
    margin-right: 0px;
    .column {
      padding: 0px;
      .box {
        box-shadow: none;
      }
    }
  }
}

/* #foodTrackingButton {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, #fff3e6 0%, #fff8f0 100%);
} */
#foodTrackingButton,
#helpfulHabitsButton,
#goalsButton,
#easyWinsButton {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, #ffffff 0%, #fff8f0 100%);
}
</style>