<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4">Liked Items</h4>
    </div>
    <p>You have not liked any posts yet.</p>
  </div>
</template>

<script>
export default {
  name: 'Likes',
  components: {},
}
</script>
<style scoped>

</style>
