<template>
  <div class="container">
    <div class="herosmall">
      <h4 class="title is-5">
        {{ item.title }} <van-tag v-if="!item.value" type="warning">Empty</van-tag>
      </h4>
      <i class="stats far fa-chart-bar" @click="action('stats')"></i>
      <i class="options fas fa-ellipsis-v" @click="action('menu')"></i>
    </div>
    <div class="columns is-center is-mobile icons">
      <div class="column cursorPointer">
        <i
          class="fa-laugh-beam"
          style="color: green"
          :class="{ far: item.value != '5', fas: item.value === '5' }"
          @click="select(5)"
        ></i>
        <span class="lbl" style="color: green">great</span>
      </div>
      <div class="column cursorPointer">
        <i
          class="far fa-smile-beam"
          style="color: #e0d434"
          :class="{ far: item.value != '4', fas: item.value === '4' }"
          @click="select(4)"
        ></i>
        <span class="lbl" style="color: #e0d434">good</span>
      </div>
      <div class="column cursorPointer">
        <i
          class="far fa-meh"
          :class="{ far: item.value != '3', fas: item.value === '3' }"
          style="color: #f7c44d"
          @click="select(3)"
        ></i>
        <span class="lbl" style="color: #f7c44d">meh</span>
      </div>
      <div class="column cursorPointer">
        <i
          class="far fa-frown-open"
          :class="{ far: item.value != '2', fas: item.value === '2' }"
          style="color: #f77d4d"
          @click="select(2)"
        ></i>
        <span class="lbl" style="color: #f77d4d">bad</span>
      </div>
      <div class="column cursorPointer">
        <i
          class="far fa-tired"
          :class="{ far: item.value != '1', fas: item.value === '1' }"
          @click="select(1)"
        ></i>
        <span class="lbl">awful</span>
      </div>
    </div>
    <transition-group name="slide">
      <b-button key="button" size="is-dotdot" @click="toggleShowText">...</b-button>
      <div v-if="showingText || item.text || item.signedFile" :key="1" class="optText columns">
        <div class="column is-paddingless">
          <b-input
            v-model="text"
            maxlength="200"
            type="textarea"
            rows="2"
            placeholder="Type here"
          ></b-input>
        </div>
        <div class="column is-paddingless" style="flex: 0.5">
          <Uploader :max-images="1" :images="item.images" @uploaded="uploadedFile" />
        </div>
      </div>
    </transition-group>
    <hr />
  </div>
</template>
<script>
import Uploader from '@/components/Uploader'
export default {
  name: 'JournalMood',
  components: { Uploader },
  props: ['item'],
  data() {
    return {
      selected: null,
      showingText: false,
      text: '',
    }
  },
  watch: {
    text(val, oldVal) {
      if (!oldVal) return
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.$emit('update', { field: 'value', value: this.text })
      }, 500)
    },
  },
  methods: {
    select(value) {
      value = String(value)
      this.$emit('update', { field: 'value', value })
    },
    toggleShowText() {
      this.showingText = !this.showingText
    },
    uploadedFile(file) {
      // only single image is allowed
      if (file) {
        this.$emit('update', { field: 'image', value: file.filename })
      }
    },
    action(type) {
      this.$emit('action', { title: this.item.title, type })
    },
  },
}
</script>
<style lang="scss" scoped>
div.icons {
  max-width: 370px;
  margin: auto;
  div.column {
    padding: 5px;
  }
  i {
    font-size: 50px;
    transition: all ease 0.3s;
    opacity: 0.5;
    &.fas {
      // font-size: 60px;
      transform: scale(1.2);
      opacity: 1;
    }
  }
  .lbl {
    font-size: 12px;
    color: #7e7e7e;
  }
}
div.radioGroup {
  color: #058aff;
  transform: scale(1.15);
  div {
    margin: auto;
    max-width: 200px;
  }
  span {
    padding-right: 20px;
  }
}
div.optText {
  max-width: 570px;
  margin: auto;
  i {
    font-size: 50px;
    transition: all ease 0.3s;
    &.fas {
      // font-size: 60px;
      transform: scale(1.2);
    }
  }
  .lbl {
    font-size: 12px;
    color: #7e7e7e;
  }
}
</style>
