<template>
  <div id="groups" style="position: relative">
    <div class="hero">
      <h4 class="title is-4">
        <div>
          <b-button
            type="is-text"
            class="is-pulled-right newButton"
            size="is-small"
            @click="openNewChallengeModal"
          >
            <i class="far fa-plus"></i> <b>New</b> Challenge
          </b-button>
        </div>
        Challenges
        <van-popover
          v-model="showPicker"
          trigger="click"
          :actions="sections"
          @select="onConfirm"
        >
          <template #reference>
            <small class="headerSmall">
              {{ selection || 'All' }}
              <i class="fas fa-caret-down"></i>
            </small>
          </template>
        </van-popover>
      </h4>
    </div>
    <!-- <van-loading class="loadingAbsolute" color="#1989fa" /> -->
    <section class="section2">
      <div v-for="item in items" :key="item.id">
        <SingleChallenge @click.native="showChallenge(item)" :item="item" />
      </div>
    </section>

    <b-modal
      id="Modal"
      class
      animation="fade"
      :active.sync="isNewChallengeModalActive"
      :width="540"
      :full-screen="true"
      scroll="keep"
    >
      <ChallengeCreationModal
        @close="
          isNewChallengeModalActive = false
          getChallenges()
        "
      />
    </b-modal>
    <!-- <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        :columns="options"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup> -->
  </div>
</template>

<script>
// import { SwipeList } from 'vue-swipe-actions'
// import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import ApiService from '@/services/ApiService'
// import ChallengeModal from '@/components/modals/ChallengeModal.vue'
import ChallengeCreationModal from '@/components/modals/ChallengeCreationModal.vue'
import SingleChallenge from '@/components/SingleChallenge.vue'
import ChallengeModal from '@/components/modals/ChallengeModal.vue'
export default {
  name: 'Challenges',
  components: {
    SingleChallenge,
    ChallengeCreationModal,
    // ChallengeModal,
  },
  data() {
    return {
      group_challenge_id: 0,
      showPicker: false,
      iconSelected: 'fas fa-check',
      selection: 'All',
      options: ['All', 'General', 'Health', 'Meditation'],
      sections: [
        { text: 'All', value: 'all' },
        { text: 'General', value: 'general' },
        { text: 'Style', value: 'style' },
        { text: 'Health', value: 'health' },
        { text: 'Workouts', value: 'workouts' },
        { text: 'Meditation', value: 'meditation' },
        { text: 'Self Development', value: 'self_development' },
      ],
      groupChallenges: [[]],
      page: 0,
      revealed: {},
      newChallenge: {
        tab: 0,
        title: '',
        section: 'general',
        description: '',
        public: 1,
        color: '#CCCCCC',
        background: '#222f3d',
        icon: 'fas fa-check',
        schedule: '',
        days: 7,
        schedule_options: [],
      },
      isNewChallengeModalActive: false,
      items: [],
      u: undefined,
    }
  },
  computed: {},
  mounted() {
    this.getChallenges()
    this.items = this.$store.state.general.temp_challenges_items
  },
  methods: {
    getChallenges() {
      ApiService.challenges.getChallenges().then((resp) => {
        this.items = resp.data
        this.$store.dispatch('setTempChallenges', resp.data.slice(0, 20))
      })
    },
    onConfirm(value) {
      this.selection = value.text
    },
    openNewChallengeModal() {
      this.isNewChallengeModalActive = true
    },
    showChallenge(item) {
      let challenge = JSON.parse(JSON.stringify(item))
      if (typeof challenge.schedule_options === 'string') {
        challenge.schedule_options = JSON.parse(challenge.schedule_options)
      }
      let modalOptions = {
        parent: this,
        hasModalCard: true,
        customClass: 'custom-class',
        width: 1070,
        props: { item: challenge },
        animation: 'fade',
        canCancel: ['escape', 'x'],
        component: ChallengeModal,
        events: {
          refresh: () => {
            this.getChallenges()
          },
        },
      }
      this.$buefy.modal.open(modalOptions)
    },
  },
}
</script>
<style scoped>
.contentBlock {
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 2px;
}
.bottomIcons span {
  padding-right: 10px;
  color: #5c95d2;
  font-size: 0.75rem;
}
.card-content {
  padding: 0.5rem;
  border: 1px solid #f4f4f4;
  /* margin-bottom: 5px; */
}
.entryTitle {
  font-weight: 400 !important;
  font-size: 14.5px !important;
}
.timeago {
  color: #bbbbbb !important;
  line-height: 36px;
}
div.image {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
hr {
  height: 1px;
  opacity: 0.5;
}
.previewButton {
  padding: 5px;
  height: auto;
  border-radius: 8px !important;
}
div.column .label {
  text-align: center;
}
#topSelections,
#topSelections .column {
  padding: 0px;
  margin: 0px;
}
</style>
