<template>
  <div id="limitedMainDiv">
    <div class="hero">
      <h4 class="title is-4">Competitions <small class="headerSmall">(Coming soon)</small></h4>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    // SwipeOut,
    // SwipeList
  },
  data() {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: '',
      mockSwipeList: [
        [
          {
            id: 'a',
            title: 'Ronald mcdonald',
            description: 'some description',
            disabled: false
          },
          {
            id: 'b',
            title: 'Steve Jobs',
            description: 'some description',
            disabled: false
          },
          {
            id: 'c',
            title: 'Jake',
            description: 'some description',
            disabled: false
          },
          {
            id: 'Don',
            title: 'Some title',
            description: 'some description',
            disabled: false
          },
          {
            id: 'e',
            title: 'Some title',
            description: 'some description',
            disabled: false
          },
          {
            id: 'f',
            title: 'Some title',
            description: 'some description',
            disabled: false
          },
          {
            id: 'g',
            title: 'Some title',
            description: 'some description',
            disabled: false
          },
          {
            id: 'h',
            title: 'Some title',
            description: 'some description',
            disabled: false
          }
        ]
      ]
    }
  },
  mounted() {
    // ideally should be in some global handler/store
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    dd(v) {
      console.log(v.target)
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0)
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0)
    },
    closeFirst() {
      this.$refs.list.closeActions(0)
    },
    closeAll() {
      this.$refs.list.closeActions()
    },
    remove(item) {
      this.$set(
        this.mockSwipeList,
        this.page,
        this.mockSwipeList[this.page].filter(i => i !== item)
      )
    },
    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id
      }
    },
    itemClick(e) {
      console.log(e, 'item click')
      alert('yeeha')
    },
    fbClick(e) {
      console.log(e, 'First Button Click')
    },
    sbClick(e) {
      console.log(e, 'Second Button Click')
    },
    // keyboard
    onKeyDown(e) {
      if (e.keyCode !== 16) return
      this.enabled = false
    },
    onKeyUp(e) {
      if (e.keyCode !== 16) return
      this.enabled = true
    }
  }
}
</script>
<style scoped>
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
</style>
