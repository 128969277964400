import axios from "axios";
import router from "../router";

// You can use your own logic to set your local or production domain
const baseDomain = `https://${process.env.VUE_APP_DOMAIN}/api`;
// const baseDomain = "https://dev.londoneleganceclub.com/api";
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;

const connection = axios.create({
  baseURL
});

connection.interceptors.request.use(async (request) => {
  // eslint-disable-next-line no-param-reassign

  const token = localStorage.getItem('token');
  console.log('TOKEN', token);

  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`;
  }

  request.config = {
    showToast: false, // may be overwritten in next line
    ...(request.config || {}),
    start: Date.now(),
  };

  if (request.config.showToast) {
    // eslint-disable-next-line no-param-reassign
    // request.config.requestToastId = Vue.$toast(
    //   request.config.requestToast.title
    // );
  }
  return request;
});

connection.interceptors.response.use(
  (response) => {
    const now = Date.now();
    const request = response.config;
    console.info(`Api Call ${request.url} took ${now - request.config.start}ms`);

    return response;
  },
  async (error) => {
    // const originalRequest = error.config;
    console.log(error);
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          router.push({ path: '/' });
          break;
        case 403:
          router.replace({
            path: '/',
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        case 404:
          router.replace({
            path: '/404'
          });
          break;
        case 502:
        // setTimeout(() => {
        //   router.replace({
        //     path: '/login',
        //     query: {
        //       redirect: router.currentRoute.fullPath,
        //     },
        //   });
        // }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);

export default connection;