<template>
  <div
    class="card is-clearfix modalInside modalBg"
    style="padding-top: 1px; padding-bottom: 20px"
  >
    <div style="max-width: 700px; margin: auto">
      <div class="hero" style="padding-top: 1px">
        <h4 class="title is-4">
          New <small class="headerSmall">Challenge</small>
        </h4>
      </div>
      <b-tabs v-model="newChallenge.tab">
        <b-tab-item label="About Challenge">
          <div id="topSelections" class="columns is-mobile">
            <div class="column">
              <label class="label">Icon color</label>
              <v-swatches
                v-model="newChallenge.color"
                popover-x="right"
              ></v-swatches>
            </div>
            <div class="column">
              <label class="label">Icon</label>
              <IconPicker
                :current-icon="newChallenge.icon"
                @selectIcon="selectIcon"
              />
            </div>
            <div class="column">
              <label class="label">Background</label>
              <v-swatches
                v-model="newChallenge.background"
                :swatches="swatches"
                popover-x="left"
              ></v-swatches>
            </div>
          </div>

          <div class="container is-clearfix">
            <b-field label="Challenge Name" style="display: block">
              <b-input
                v-model="newChallenge.title"
                placeholder="Challenge name"
                required
                maxlength="40"
              ></b-input>
            </b-field>
            <b-field
              label="About the Challenge"
              style="display: block; position: relative"
            >
              <b-input
                v-model="newChallenge.description"
                type="text"
                minlength="10"
                maxlength="50"
                placeholder="Short Description"
              ></b-input>
              <!-- <b-input
                v-model="newChallenge.description"
                type="textarea"
                minlength="50"
                maxlength="50"
                placeholder="Description"
                style="min-height: 50px;"
                  ></b-input>-->
            </b-field>
            <label class="label">Section</label>
            <van-dropdown-menu
              class="height42dropdown"
              style="margin-bottom: 15px"
            >
              <van-dropdown-item
                v-model="newChallenge.section"
                :options="sections"
              />
            </van-dropdown-menu>
            <div>
              <label class="label">Preview</label>
              <SingleChallenge :item="newChallenge" />
            </div>
            <label class="label">Visibility</label>
            <div style="text-align: left">
              <van-switch
                v-model="newChallenge.public"
                active-color="#07c160"
                inactive-color="#ccc"
                :active-value="1"
                type="is-info"
                :inactive-value="0"
              ></van-switch>
              <span
                class="is-size-7"
                style="
                  line-height: 20px;
                  line-height: 35px;
                  vertical-align: top;
                  padding-left: 10px;
                "
                >{{
                  !newChallenge.public
                    ? 'Show only to my Friends'
                    : 'Visible to everyone'
                }}</span
              >
            </div>

            <hr />
            <div>
              <b-button
                type="is-primary is-light"
                :disabled="
                  newChallenge.title == '' || newChallenge.description == ''
                "
                @click="newChallenge.tab = 1"
              >
                <i class="fas fa-arrow-alt-circle-right"></i> Continue
              </b-button>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item
          label="Challenge details"
          :visible="newChallenge.title != '' && newChallenge.description !== ''"
        >
          <div style="min-height: 110px">
            <label class="label">Preview</label>
            <SingleChallenge :item="newChallenge" style="margin-bottom: 15px" />
          </div>
          <div style="min-height: 400px">
            <b-field label="Challenge Length(Days)">
              <van-stepper v-model="newChallenge.days" step="1" max="60"
                >/></van-stepper
              >
            </b-field>
            <div v-if="newChallenge.days" style="margin-bottom: 15px">
              <label class="label">Daily Habit/Task Scheduling:</label>
              <SheduleType
                v-model="newChallenge.schedule"
                :initial="newChallenge.schedule"
                @updated="setSchedule"
              />
            </div>
            <div
              v-if="
                newChallenge.schedule === 'same' &&
                newChallenge.schedule_options
              "
            >
              <label class="label">Habit/Task</label>
              <div
                v-for="(habit, index2) in newChallenge.schedule_options[0]
                  .habits"
                :key="'single' + '-habit' + index2"
                style="margin-bottom: 10px"
              >
                <HabitInputBlock
                  v-model="newChallenge.schedule_options[0].habits[index2]"
                  :initial="habit"
                />
              </div>
              <div style="text-align: left">
                <b-button
                  type="is-text"
                  size="is-small"
                  @click="addNewHabit(0)"
                  v-show="newChallenge.schedule_options[0].habits.length < 3"
                >
                  <i class="far fa-plus"></i> Add another Habit
                </b-button>
              </div>
            </div>
            <div v-if="newChallenge.schedule === 'days'">
              <div
                v-for="(item, index) in newChallenge.schedule_options"
                :key="'das' + index"
                class="singleBlock"
                :class="{ evenBlock: index % 2 == 0 ? true : false }"
              >
                <label class="label">Select Days</label>
                <ChallengeDaysToggle
                  :totalDays="newChallenge.days"
                  :selectedDays="item.days"
                  :allUsedDays="daysUsed"
                  @updated="updatedDaysBlock($event, index)"
                />
                <label class="label" style="margin-top: 28px"
                  >Habit/Task for {{ item.days.join(',') }} day(s)</label
                >
                <div
                  v-for="(habit, index2) in item.habits"
                  :key="index + '-habit' + index2"
                  style="margin-bottom: 10px"
                >
                  <HabitInputBlock
                    v-model="item.habits[index2]"
                    :initial="habit"
                  />
                </div>
                <div style="text-align: left">
                  <b-button
                    type="is-text"
                    size="is-small"
                    @click="addNewHabit(index)"
                    v-show="item.habits.length < 3"
                  >
                    <i class="far fa-plus"></i> Add another Habit
                  </b-button>
                </div>
              </div>
            </div>
            <div
              v-if="newChallenge.schedule === 'weekday'"
              class
              style="padding: 0px"
            >
              <div
                v-for="(item, indexWeekday) in newChallenge.schedule_options"
                :key="'sh' + indexWeekday"
                class="singleBlock"
                :class="{ evenBlock: indexWeekday % 2 == 0 ? true : false }"
              >
                <label class="label">Select Weekday</label>
                <WeekdaysSelection
                  :selected="item.weekday"
                  :disabled="item.disabled"
                  @onSelected="updateWeekdaysBelow($event, indexWeekday)"
                />
                <label class="label">Habit/Task days above</label>
                <div
                  v-for="(habit, index2) in item.habits"
                  :key="indexWeekday + '-habit' + index2"
                  style="margin-bottom: 10px"
                >
                  <HabitInputBlock
                    v-model="item.habits[index2]"
                    :initial="habit"
                  />
                </div>
                <div style="text-align: left">
                  <b-button
                    type="is-text"
                    size="is-small"
                    @click="addNewHabit(indexWeekday)"
                    v-show="item.habits.length < 3"
                  >
                    <i class="far fa-plus"></i> Add another Habit
                  </b-button>
                </div>
              </div>
            </div>
            <div>
              <hr />
              <b-button type="is-primary" @click="validateAndSubmit">
                <i class="far fa-plus"></i> Save Challenge
              </b-button>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import IconPicker from '@/components/IconPicker'
import SwatchesColors from '@/constants/colors.json'
import SingleChallenge from '@/components/SingleChallenge.vue'
import WeekdaysSelection from '@/components/WeekdaysSelection.vue'
import ChallengeDaysToggle from '@/components/ChallengeDaysToggle.vue'
import HabitInputBlock from '@/components/HabitInputBlock.vue'
import SheduleType from '@/components/SheduleType.vue'
import ApiService from '@/services/ApiService'
export default {
  name: 'ChallengeCreationModal',
  components: {
    VSwatches,
    SingleChallenge,
    WeekdaysSelection,
    HabitInputBlock,
    ChallengeDaysToggle,
    SheduleType,
    IconPicker,
  },
  props: {
    id: Number,
    title: String,
    users: Number,
    messages: Number,
  },
  data() {
    return {
      items: [],
      swatches: SwatchesColors,
      group_challenge_id: 0,
      showPicker: false,
      iconSelected: 'fas fa-check',
      selection: 'All',
      options: ['All', 'General', 'Health', 'Meditation'],
      sections: [
        { text: 'All', value: 'all' },
        { text: 'General', value: 'general' },
        { text: 'Style', value: 'style' },
        { text: 'Health', value: 'health' },
        { text: 'Workouts', value: 'workouts' },
        { text: 'Meditation', value: 'meditation' },
        { text: 'Self Development', value: 'self_development' },
      ],
      groupChallenges: [[]],
      page: 0,
      revealed: {},
      daysUsed: [],
      newChallenge: {
        tab: 0,
        title: '',
        section: 'general',
        description: '',
        public: 1,
        color: '#3d556e',
        background: '#e6fff6',
        icon: 'fas fa-check',
        schedule: '',
        days: 7,
        schedule_options: [],
      },
    }
  },
  mounted() {
    console.log('PROPS', this.title)
  },
  methods: {
    setSchedule(type) {
      this.newChallenge.schedule = type
      if (type === 'same') {
        this.newChallenge.schedule_options = [
          {
            habits: [{}],
          },
        ]
      } else if (type === 'day_range') {
        this.newChallenge.schedule_options = [
          {
            from: 1,
            max: parseInt(this.newChallenge.days),
            to: 1,
            habits: [{}],
          },
        ]
      } else if (type === 'weekday') {
        this.newChallenge.schedule_options = [
          {
            weekday: [],
            disabled: [],
            habits: [{}],
          },
        ]
      } else if (type === 'days') {
        this.newChallenge.schedule_options = [
          {
            days: [],
            habits: [{ text: '' }],
          },
        ]
        this.daysUsed = []
      }
    },
    selectIcon(newIcon) {
      this.newChallenge.icon = newIcon
    },
    updatedDaysBlock(data, index) {
      this.daysUsed = data.allUsedDays
      this.$set(
        this.newChallenge.schedule_options[index],
        'days',
        data.selectedDays.sort((a, b) => a - b)
      )
      const belowArray = this.newChallenge.schedule_options[index + 1]
        ? true
        : false
      if (!belowArray && data.allUsedDays.length < this.newChallenge.days) {
        this.newChallenge.schedule_options.push({
          days: [],
          habits: [{ text: '' }],
        })
      } else if (data.allUsedDays.length === this.newChallenge.days) {
        if (belowArray) {
          this.newChallenge.schedule_options.splice(index + 1, 1)
        }
      }
    },
    updateWeekdaysBelow(weekday, index) {
      const weekdayIndex =
        this.newChallenge.schedule_options[index].weekday.indexOf(weekday)
      if (weekdayIndex === -1) {
        this.newChallenge.schedule_options[index].weekday = [
          ...this.newChallenge.schedule_options[index].weekday,
          weekday,
        ]
      } else {
        this.newChallenge.schedule_options[index].weekday.splice(
          weekdayIndex,
          1
        )
      }

      let selectedDays = []
      this.newChallenge.schedule_options.forEach((item, optionsIndex) => {
        if (index >= optionsIndex) {
          selectedDays = [...selectedDays, ...item.weekday]
        }
      })
      if (this.newChallenge.schedule_options[index + 1]) {
        if (selectedDays.length !== 7) {
          // update one blow
          this.newChallenge.schedule_options[index + 1].weekday = []
          this.newChallenge.schedule_options[index + 1].disabled = selectedDays
        } else {
          // remove array
          const items_to_remove =
            this.newChallenge.schedule_options.length - (index + 1)
          this.newChallenge.schedule_options.splice(index + 1, items_to_remove)
        }
      } else {
        if (
          selectedDays.length != 7 &&
          this.newChallenge.schedule_options[index].weekday.length != 0
        ) {
          this.newChallenge.schedule_options.push({
            weekday: [],
            disabled: selectedDays,
            habits: [{ text: '' }],
          })
        }
      }
      console.log(selectedDays)
    },
    saveNewChallenge() {
      ApiService.challenges
        .saveNewChallenge(this.newChallenge)
        .then(() => {
          this.$notify({ type: 'success', message: 'Saved' })
          this.$emit('close');
        })
        .catch((err) => {
          alert(err)
        })
    },
    addNewHabit(index) {
      this.newChallenge.schedule_options[index].habits.push({})
      console.log(this.newChallenge.schedule_options[index])
      this.$forceUpdate()
    },
    titleEmoji(emoji) {
      this.newChallenge.title += emoji.data
    },
    messageEmoji(emoji) {
      this.newChallenge.message += emoji.data
    },
    onConfirm(value) {
      this.selection = value.text
    },
    parent(icon) {
      if (icon.styles.indexOf('regular') > -1) {
        return 'fa'
      } else if (icon.styles.indexOf('solid') > -1) {
        return 'fas'
      } else if (icon.styles.indexOf('brands') > -1) {
        return 'fab'
      }
      return ''
    },
    dayFormat(day) {
      if (day == 1) {
        return day + 'st'
      } else if (day == 2) {
        return day + 'nd'
      } else if (day === 3) {
        return day + 'rd'
      } else {
        return day + 'th'
      }
    },
    validateAndSubmit() {
      let isValid = true
      if (this.newChallenge.schedule === 'same') {
        this.newChallenge.schedule_options[0].habits.forEach((habit) => {
          if (habit.text == '') {
            isValid = false
          }
        })
      } else if (this.newChallenge.schedule === 'days') {
        if (this.newChallenge.days != this.daysUsed.length) {
          isValid = false
        }
        this.newChallenge.schedule_options.forEach((block) => {
          block.habits.forEach((habit) => {
            if (habit.text == '') {
              isValid = false
            }
          })
        })
      } else if (this.newChallenge.schedule === 'weekday') {
        this.newChallenge.schedule_options.forEach((block) => {
          block.habits.forEach((habit) => {
            if (habit.text == '') {
              isValid = false
            }
          })
        })
      }
      console.log('New challenge', this.newChallenge)
      if(isValid){
          this.saveNewChallenge()
      }else{
          this.$notify({ type: 'warning', message: 'Some fields are empty.' })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.column .label {
  text-align: center;
}
div.singleBlock {
  padding: 2px 10px;
  /* margin-bottom: 4px; */
  background: #efefef;
  border-bottom: 1px solid #ccc;
}
div.singleBlock.evenBlock {
  background: #e2eeff;
}
</style>
