import dbConnection from '../dbConnection'
const posts = {
  getPosts: function(page) {
    return dbConnection.get('members/posts/all/' + page)
  },
  getPostsCategory: function(category, page) {
    return dbConnection.get('members/posts/' + category + '/' + page)
  },
  getPostsSubCategory: function(subcat, page) {
    return dbConnection.get('members/posts/subcat/' + subcat + '/' + page)
  },
  getPostExtraInfo: function(id) {
    return dbConnection.get('members/posts/single/' + id)
  },
  like: function(id) {
    return dbConnection.post('members/posts/like', { id })
  }
}
export default posts