<template>
  <div class="dueTime"><i class="far fa-clock"></i> <small>Feb 2nd</small></div>
</template>

<script>
export default {
  name: 'DueTime',
  props: {
    due_time: String,
  },
}
</script>
<style scoped lang="scss">
div.dueTime {
  display: inline-block;
  color: red;
  margin-right: 5px;
}
</style>
