<template>
  <div class="wrapper">
    <div
      v-for="day in days"
      :key="'day' + day.id"
      class="dayblock"
      :class="{'disabled': disabled.indexOf(day.id) != -1, 'selected': selected.indexOf(day.id) != -1 }"
      @click="toggleDay(day)"
    >{{ day.label }}</div>
  </div>
</template>

<script>
export default {
  name: 'WeekdaySelection',
  props: {
    selected: Array,
    disabled: Array
  },
  data() {
    return {
      days: [
        { id: 1, label: 'Mo' },
        { id: 2, label: 'Tu' },
        { id: 3, label: 'We' },
        { id: 4, label: 'Th' },
        { id: 5, label: 'Fr' },
        { id: 6, label: 'Sa' },
        { id: 7, label: 'Su' }
      ]
    }
  },
  methods: {
    toggleDay(day) {
      if (this.disabled.indexOf(day.id) > -1) {
        return
      }
      const dayIndex = this.selected.indexOf(day.id)
      if (dayIndex > -1) {
        // this.selected.splice(dayIndex, 1)
      } else {
        // this.selected.push(day.id)
        // console.log(this.selected)
      }
      this.$forceUpdate()
      this.$emit('onSelected', day.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.wrapper {
}
div.dayblock {
  display: inline-block;
  width: 38px;
  height: 34px;
  border-radius: 17px;
  // border: 1px solid #ccc;
  box-shadow: 0px 0px 10px #cccccc;
  background: rgba($color: #ffffff, $alpha: 0.5);

  cursor: pointer;
  transition: all 0.25s ease;
  line-height: 34px;
  margin-right: 8px;
  font-size: 12px;
  &.selected {
    background: #f7b875;
    color: white;
    box-shadow: none;
  }
  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
