import dbConnection from '../dbConnection'
const workouts = {
  getExercises: function (ids) {
    return dbConnection.get('members/workouts/exercises' + ((ids != undefined) ? '?ids=' + ids : ''))
  },
  getWorkouts: function () {
    return dbConnection.get('members/workouts/')
  },
  save: function (obj) {
    return dbConnection.post('members/workouts/save', obj)
  },
  saveExercise: function (obj) {
    return dbConnection.post('members/workouts/saveExercise', obj)
  },
  getSingleWorkout: function (id) {
    return dbConnection.get('members/workouts/single/' + id + '/workout')
  },
  getWorkoutsCategory: function (category) {
    return dbConnection.get('members/workouts/category/' + category)
  }
}
export default workouts;
