<template>
  <div id="screenPage" class="container" style="background: #ffffff">
    <div>
      <b-button
        type="is-text"
        class="is-pulled-right newButton"
        size="is-small"
        @click="openNewHowToModal"
      >
        <i class="far fa-plus"></i> <b>New</b> Tips
      </b-button>
    </div>
    <div class="hero">
      <h4 class="title is-4" @click="showPicker = true">
        Tips
        <van-popover
          v-model="showPicker"
          trigger="click"
          :actions="sections"
          @select="onConfirm"
        >
          <template #reference>
            <small class="headerSmall">
              {{ selection || 'All' }}
              <i class="fas fa-caret-down"></i>
            </small>
          </template>
        </van-popover>
      </h4>
    </div>
    <section class="section2" style="clear: both">
      <div style="margin-top: 30px; clear: both">
        <transition-group
          name="list"
          tag="div"
          class="columns is-centered is-multiline contentBlocks"
        >
          <SingleHowTo
            v-for="item in filtered"
            :key="item.id"
            :item="item"
            class="column is-half howtoItem"
            @click.native="openModal(item)"
          />
        </transition-group>
      </div>
    </section>
    <b-modal
      id="Modal"
      class
      animation="fade"
      :active.sync="isNewHowToModalActive"
      :width="540"
      scroll="keep"
    >
      <div
        class="card is-clearfix modalInside modalBg"
        style="padding-top: 1px; padding-bottom: 20px"
      >
        <div class>
          <div class="hero" style="padding-top: 1px">
            <h4 class="title is-4">
              New <small class="headerSmall">Tips</small>
            </h4>
          </div>
          <b-field label="Tips Name">
            <b-input
              v-model="newHowto.title"
              placeholder="How to make Heels comfortable"
              required
              maxlength="40"
            ></b-input>
          </b-field>
          <b-field label="Section">
            <van-dropdown-menu
              class="height34dropdown"
              style="margin-bottom: 15px"
              required
            >
              <van-dropdown-item
                v-model="newHowto.section"
                :options="sections"
              />
            </van-dropdown-menu>
          </b-field>
          <b-field label="Image" style="text-align: left">
            <Uploader :max-images="1" @uploaded="uploadedFiles" />
          </b-field>

          <b-field label="Description">
            <b-input
              v-model="newHowto.description"
              placeholder="Description"
              required
              type="textarea"
              rows="2"
              maxlength="80"
            ></b-input>
          </b-field>
          <div style="min-height: 100px">
            <div>
              <hr />
              <b-button
                :disabled="!newHowto.title"
                type="is-primary is-light"
                @click="saveNewHowTo"
              >
                <i class="fas fa-archive"></i> Save Tips
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import anime from 'animejs'
import ApiService from '@/services/ApiService'
import SingleHowTo from '@/components/SingleHowTo'
import HowToModal from '@/components/modals/HowToModal'
import Uploader from '@/components/Uploader'
export default {
  name: 'BeautyStyleHowTo',
  components: {
    // MembersTemplate
    SingleHowTo,
    Uploader,
  },
  data() {
    return {
      items: [],
      isNewHowToModalActive: false,
      options: ['All', 'General', 'Beauty', 'Style'],
      sections: [
        { text: 'All', value: 'all' },
        { text: 'General', value: 'general' },
        { text: 'Style', value: 'style' },
        { text: 'Beauty', value: 'beauty' },
      ],
      showPicker: false,
      selection: null,
      newHowto: {
        description: '',
      },
    }
  },
  created() {
    this.getHowTos()
  },
  computed: {
    filtered() {
      this.animate();
      if (!this.selection || this.selection === 'All') {
        return this.items
      } else {
        return this.items.filter(
          (item) => item.section == this.selection.toLowerCase()
        )
      }
    },
  },
  methods: {
    openNewHowToModal() {
      this.isNewHowToModalActive = true
    },

    openModal(item) {
      let modalOptions = {
        parent: this,
        hasModalCard: true,
        customClass: 'custom-class',
        width: 800,
        props: { item },
        animation: 'fade',
        component: HowToModal,
        events: {
          uploadedFileEvent: (value) => {
            console.log(value)
          },
        },
      }

      this.$buefy.modal.open(modalOptions)
    },
    getHowTos() {
      ApiService.howto.getHowto().then((resp) => {
        console.log('howots', resp.data)
        this.items = resp.data
        this.animate();
      })
    },
    animate() {
      setTimeout(() => {
        this.$nextTick(() => {
          anime({
            targets: '.howtoItem',
            opacity: 1,
            delay: anime.stagger(30),
          })
        })
      }, 150)
    },
    saveNewHowTo() {
      ApiService.howto.saveHowto(this.newHowto).then((resp) => {
        if (resp.status === 200) {
          this.isNewHowToModalActive = false
          this.$notify({
            message: 'Saved! Awaiting approval.',
            type: 'success',
          })
          this.getHowTos()
        }
      })
    },
    uploadedFiles(file) {
      if (file) {
        this.newHowto.attachment_id = file.id
      }
    },
    onConfirm(obj) {
      this.selection = obj.text
    },
  },
}
</script>
<style lang="scss" scoped>
.howtoItem {
  opacity: 0;
  cursor: pointer;
}
</style>