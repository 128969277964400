<template>
  <div class="box" :style="{ background: background }">
    <div class="iconDiv">
      <i v-if="icon" :class="icon" :style="{ color: iconColor }"></i>
    </div>
    <div class="text">
      <strong>{{ text }}</strong>
    </div>
    <i class="abs" :class="icon"></i>
  </div>
</template>

<script>
export default {
  name: 'HabitsSection',
  props: {
    text: String,
    icon: String,
    iconColor: String,
    background: String,
  },
  computed: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box {
  padding: 0px;
  position: relative;
  margin-right: 14px;
  width: 100%;
  max-height: 60px;
  min-height: 60px;
  margin-bottom: 8px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  display: flex;
  border: 1px solid #b6b6b6;
  /* align-content: center; */
  align-items: center;
  transition: 0.4s all;
  &:hover {
    filter: saturate(1.7);
  }
  div.iconDiv {
    width: 80px;
    /* line-height: 70px; */
    border-right: 1px solid #ccc;
    i {
      font-size: 25px;
      /* width: 100%; */
    }
  }
  .text {
    white-space: break-spaces;
    /* text-align: left; */
    /* width: 100%; */
    padding: 10px;
    font-weight: 500;
    flex: 1;
    strong {
      font-weight: 500;
      font-size: 20px;
    }
  }
  div.bg {
    height: 170px;
  }
  i.abs {
    font-size: 255px;
    position: absolute;
    /* width: 100%; */
    opacity: 0.25;
    left: 100px;
    color: #ffffff;
  }
}
</style>
