<template>
  <div class="wrap">
    <b-input
      v-model="text"
      placeholder="Habit/Task name"
      class="singleHabit"
      required
      maxlength="40"
      @input="onInput('text', $event)"
    ></b-input>
    <!-- <div class="options">
      <b-button class="typeSelection" type="is-text" size="is-micro" @click="showPicker = true">
        {{ type || 'No Goal' }}
        <i class="fas fa-caret-down"></i>
      </b-button>
      <div v-if="type" style="display: inline-block;">
        <van-stepper v-model="value" step="1" style="display: inline-block;" @input="onInput('value', $event)">/></van-stepper>
      </div>
      <span v-if="type === 'Reps Goal'" class="smtext">times</span>
      <span v-if="type === 'Time Goal'" class="smtext">mins</span>
      <div v-if="type" style="display: inline-block;">
        <b-button
          class="typeSelection"
          style="margin-left: 20px;"
          type="is-text"
          size="is-micro"
          @click="showIncreasePicker = true"
        >
          {{ increase || 'Same each time' }}
          <i class="fas fa-caret-down"></i>
        </b-button>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          confirm-button-text="Confirm"
          cancel-button-text="Cancel"
          :columns="options"
          @confirm="onInput('type', $event)"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-popup v-model="showIncreasePicker" position="bottom">
        <van-picker
          show-toolbar
          confirm-button-text="Confirm"
          cancel-button-text="Cancel"
          :columns="increaseOptions"
          @confirm="onInput('increase', $event)"
          @cancel="showIncreasePicker = false"
        />
      </van-popup>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HabitInputBlock',
  props: {
    initial: Object,
  },
  data() {
    return {
      text: this.initial.text,
      type: this.initial.type,
      value: this.initial.value,
      increase: this.initial.increase,
      reps: this.initial.reps,
      minutes: this.initial.minutes,
      showPicker: false,
      showIncreasePicker: false,
      options: ['No Goal', 'Time Goal', 'Reps Goal'],
      increaseOptions: [
        'Same each time',
        'Increase by 1% everyday',
        'Increase by 2% everyday',
        'Increase by 5% everyday',
        'Increase by 10% everyday',
        'Increase by 15% everyday',
        'Increase by 20% everyday',
        'Increase by 25% everyday',
      ],
    }
  },
  methods: {
    onInput(key, val) {
      this[key] = val
      if (key == 'type' && val == 'No Goal') {
        this.increase = null
        this.type = null
      }
      this.showPicker = false
      this.showIncreasePicker = false
      this.sendEmit()
    },
    sendEmit() {
      const obj = {
        text: this.text,
        // type: this.type,
        // value: this.value,
        // increase: this.increase,
      }
      this.$emit('input', obj)
    },
  },
}
</script>
<style scoped lang="scss">
div.options {
  text-align: left;
}
.typeSelection {
  text-decoration: none;
  padding: 5px;
  height: 30px;
  font-size: 12px;
  margin-top: 4px;
}
.smtext {
  font-size: 12px;
  padding-left: 5px;
  line-height: 38px;
  vertical-align: top;
}
</style>
<style>
.singleHabit.control .help.counter {
  float: right;
  margin-left: 0.5em;
  margin-top: -55px;
  z-index: 99;
}
</style>
