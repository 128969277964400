<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero">
      <h4 class="title is-4">Latest <small class="headerSmall">Health & Wellbeing</small></h4>
    </div>
    <section>
      <div>
        <!-- <div class="column">top</div> -->
        <transition-group
          name="list"
          tag="div"
          class="columns is-centered is-multiline contentBlocks"
        >
          <Tile
            v-for="(item, index) in items"
            :key="item.id"
            :item="item"
            :index="index"
            class="column is-one-third"
            @click.native="$parent.openModal(item)"
          />
        </transition-group>
      </div>
    </section>
  </div>
</template>

<script>
import Tile from '@/components/tiles/Tile'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'

export default {
  name: 'HealthWellbeing',
  components: {
    Tile
  },
  data() {
    return {
      items: [],
      currentPage: 0,
      category: 'health_wellbeing'
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    getPosts() {
      ApiService.posts.getPostsCategory(this.category, this.currentPage).then(response => {
        var items = response.data.slice(0, 20)
        items = updatePosts(items)
        this.items = items
      })
    },
  }
}
</script>
