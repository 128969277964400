<template>
  <div id="limitedMainDiv" style="background: #ffffff; max-width: 1400px;">
    <div class="hero">
      <h4 class="title is-4">Onboarding <small class="headerSmall"></small></h4>
    </div>
    <section id="onboarding">
      <div v-view.once="onceHandler" id="overview" class="slide">
        <div>
          <h1 class="title">
            Finally, a project for growth and happiness!
          </h1>
          <p>
            Since lots of big social platforms are almost unusable and growth
            platforms are expensive and not flexible enough. <br><br><strong>London Elegance Club</strong> is something else:
            <ul>
              <li>No Ads</li>
              <li>No Monthly subscriptions</li>
              <li>No Spamming</li>
              <li>No Fees for members</li>
              <li>You own your data</li>
              <li>Focuses on Happiness & Wellbeing with a bit of style</li>
              <li>We are independent & self funded</li>
              <li>We share only great content</li>
              <li>We don't replace your real social life</li>
              <li>It's for doers!</li>
            </ul>
          </p>
        </div>
      </div>
      <div class="slide">
        <div style="position: relative; height: 350px;">
          <h1 class="title">Works on almost everything!</h1>
          <img src="@/assets/mac.png" class="abs" id="mac" alt />
          <img src="@/assets/laptop.png" class="abs" id="laptop" alt />
          <img src="@/assets/tablet.png" class="abs" id="tablet" alt />
          <img src="@/assets/phone.png" class="abs" id="phone" alt />
          <div v-view.once="onceHandler" id="devices" class="trigger"></div>
        </div>
      </div>
      <div class="slide">
        <div style="position: relative; height: 350px;">
          <h1 class="title">It's growing, fast!</h1>
          <p>The journey has just began<br>
          We're adding lots of new features weekly!</p>
          <img src="@/assets/tree.webp" class="abs is-hidden-mobile" id="tree" alt />
          <img src="@/assets/tree.webp" class="abs is-hidden-mobile" id="tree1" alt />
          <img src="@/assets/tree.webp" class="abs " id="tree2" alt />
          <img src="@/assets/tree.webp" class="abs is-hidden-mobile" id="tree3" alt />
          <img src="@/assets/tree.webp" class="abs is-hidden-mobile" id="tree4" alt />
          <div v-view.once="onceHandler" id="tree" class="trigger"></div>
        </div>
      </div>
      <div class="slide">
        <div>
          <h1 class="title">Knowledge worth sharing</h1>
          <p>We all experience information overload.<br>
          We post/share the best content we can find so you are not overwhelmed.</p>
        </div>
        <img src="@/assets/overload.webp" class="abs" id="food" alt />
        <div v-view.once="onceHandler" id="knowledge" class="trigger"></div>
        <!-- <img src="@/assets/handbag_black.png" class="abs" id="handbag" alt /> -->
      </div>
      <div  class="slide">
        <div>
          <h1 class="title">It's all about good habits</h1>
          <p>
            Life is habit. Or rather life is a succession of habits. - S. Beckett
          </p>
          <p>Create your own habits, set preferences, goals.</p>
          <img src="@/assets/habits.webp" class="abs" id="habit" alt />
          <div v-view.once="onceHandler" id="habits" class="trigger"></div>
        </div>  
      </div>
      <div class="slide">
        <div>
          <h1 class="title">The mighty Journal</h1>
          <p>
            One of the most flexible and powerful journals out there!<br> Whether it's a grattitude journal, progress, food, you can make it your own way. 
          </p>
          <img src="@/assets/journal.webp" class="abs" id="diary" alt />
          <div v-view.once="onceHandler" id="journal" class="trigger"></div>
        </div>  
      </div>
      <div class="slide">
        <div>
          <h1 class="title">Meditations</h1>
          <p>Reduce stress, relax and enjoy a meditation from our library or listen from other sources.</p>
        </div>
        <img src="@/assets/balance.webp" class="abs" id="balance" alt />
        <div v-view.once="onceHandler" id="meditations" class="trigger"></div>
      </div>
      <div class="slide">
        <div>
          <h1 class="title">Workouts</h1>
          <p>Choose one of our workouts or create your own.<br>Exercising is known to help against depression and stimulates your brain.</p>
        </div>
        <img src="@/assets/sneakers.png" class="abs" id="sneakers" alt />
        <div v-view.once="onceHandler" id="workouts" class="trigger"></div>
      </div>
      <div id="social"  class="slide">
        <div>
          <h1 class="title">Social</h1>
          <p class="is-size-5">
            Wheter it's a forum or a challange, with help you are going to find what fits you best.
          </p>
        </div> 
      </div>
    </section>
  </div>
</template>

<script>
import anime from 'animejs'
export default {
  name: 'onboarding',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {
    onceHandler(e) {
      console.log(e)
      try {
        const id = e.target.element.id
        if (id === 'devices') {
          this.animateDevices()
        } else if (id === 'knowledge') {
          this.animateFood()
        } else if (id === 'meditations') {
          this.animateMeditations()
        } else if (id === 'workouts') {
          this.animateWorkout()
        } else if (id === 'journal') {
          this.animateJournal()
        } else if (id === 'habits') {
          this.animateHabits()
        } else if (id === 'tree') {
          this.animateTrees()
        }
      } catch (error) {
        alert(e.target.element.id)
      }
    },
    animateDevices() {
      anime({
        targets: '#tablet',
        left: '83%',
        duration: 1000,
        easing: 'easeInOutExpo',
        delay: 1500,
      })
      anime({
        targets: '#laptop',
        left: '50%',
        duration: 1000,
        easing: 'easeInOutExpo',
        delay: 1000,
      })
      anime({
        targets: '#phone',
        left: '5%',
        duration: 1500,
        easing: 'easeInOutExpo',
      })
      anime({
        targets: '#mac',
        left: '20%',
        duration: 1500,
        easing: 'easeInOutExpo',
        delay: 500,
      })
    },
    animateFood() {
      anime({
        targets: '#food',
        // translateX: 250,
        rotate: '0deg',
        bottom: 0,
        easing: 'easeInOutExpo',
        duration: 2000,
      })
    },
    animateHabits() {
      anime({
        targets: '#habit',
        // translateX: 250,
        rotate: '2deg',
        left: '50%',
        easing: 'easeInOutExpo',
        duration: 2000,
      })
    },
    animateJournal() {
      anime({
        targets: '#diary',
        // translateX: 250,
        // rotate: '25deg',
        right: '0%',
        easing: 'easeInOutExpo',
        duration: 2000,
      })
    },
    animateMeditations() {
      anime({
        targets: '#balance',
        // translateX: 250,
        // rotate: '-60deg',
        // left: '60%',
        bottom: -100,
        easing: 'easeInOutExpo',
        duration: 3000,
      })
    },
    animateWorkout() {
      anime({
        targets: '#sneakers',
        // translateX: 250,
        // rotate: '-60deg',
        // left: '60%',
        bottom: -140,
        easing: 'easeInOutExpo',
        duration: 2000,
      })
    },
    animateTrees() {
      anime({
        targets: '#tree',
        scale: 2,
        easing: 'easeInOutExpo',
        duration: 3000,
      })
      anime({
        targets: '#tree1',
        scale: 7,
        easing: 'easeInOutExpo',
        duration: 3900,
      })
      anime({
        targets: '#tree2',
        scale: 3,
        easing: 'easeInOutExpo',
        duration: 4000,
      })
      anime({
        targets: '#tree3',
        scale: 3,
        easing: 'easeInOutExpo',
        duration: 3500,
      })
      anime({
        targets: '#tree4',
        scale: 5,
        easing: 'easeInOutExpo',
        duration: 5000,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
section {
  p {
    max-width: 500px;
    margin: auto;
  }
  h1 {
    letter-spacing: 0.5px;
    padding-top: 30px;
    margin-bottom: 35px !important;
  }
}
div {
  /* font-weight: 200; */
}
ul {
  list-style: disc;
  list-style-type: disc;
  max-width: 500px;
  margin: auto;
  padding-bottom: 40px;
  li {
    margin-left: 50px;
    text-align: left;
    font-weight: 200;
    color: #1fa6e8;
  }
}
.slide {
  background: #f7f7f7;
  min-height: 350px;
  position: relative;
  border-bottom: 1px solid #e4e4e4;
  overflow: hidden;
  &:nth-child(odd) {
    background: white;
  }
}
img.abs {
  position: absolute;
}
#tablet {
  height: 150px;
  bottom: -1px;
  left: 100%;
}
#laptop {
  height: 240px;
  bottom: -50px;
  left: 100%;
}
#phone {
  height: 140px;
  bottom: -5px;
  left: 100%;
}
#mac {
  height: 260px;
  bottom: -21px;
  left: 100%;
}
#tree {
  height: 60px;
  bottom: 0px;
  left: 3%;
}
#tree1 {
  height: 40px;
  bottom: -15px;
  left: 80%;
}
#tree2 {
  height: 70px;
  bottom: -15px;
  left: 40%;
}
#tree3 {
  height: 100px;
  bottom: -15px;
  left: 20%;
}
#tree4 {
  height: 30px;
  bottom: -15px;
  left: 60%;
}
#food {
  height: 180px;
  margin-left: -200px;
  bottom: -300px;
}
#handbag {
  height: 250px;
  margin-left: -300px;
}
#diary {
  height: 300px;
  right: -100%;
  bottom: -40px;
}
#habit {
  height: 200px;
  left: 100%;
}
#sneakers {
  height: 350px;
  bottom: -300px;
}
#dumbell {
  height: 200px;
  margin-left: -100px;
}
#balance {
  height: 300px;
  bottom: -300px;
}
#social {
  background: url('~@/assets/womenBg.jpg') repeat center top;
  color: white;
  /* animation-name: MOVE-BG;
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; */
  text-shadow: 0 0 5px black;
  h1 {
    color: white;
  }
  p {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    padding: 5px 10px;
  }
  @keyframes MOVE-BG {
    from {
      background-position: 0 0px;
    }
    to {
      background-position: 0 200px;
    }
  }
}
div.trigger {
  position: absolute;
  bottom: 0;
  height: 4px;
}
</style>
