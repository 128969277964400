<template>
  <div class="post">
    <div class="contentBlock box">
      <div
        v-if="item.type !== 'competition' && item.type !== 'add_to_competition'"
        class="top is-clearfix"
      >
        <h3 v-if="item.title" class="titleBlock">{{ item.title }}</h3>
        <!-- <span class="is-pulled-left is-small">John Makenroy</span> -->
        <span class="tileDate is-size-7">
          <timeago :datetime="item.created_date"></timeago>
        </span>
      </div>
      <img v-if="index < 7" :src="item.image" />
      <van-image
        v-if="index >= 7"
        :src="item.image"
        lazy-load
        class="mainImage"
      ></van-image>
      <div v-if="item.type === 'chat'">
        <Chat :likes="item.likes" />
      </div>
      <div v-if="item.type === 'competition'" class="is-vcentered">
        <Competition data="item" />
      </div>
      <div v-if="item.type === 'article'">
        <Article data="item" />
      </div>
      <div v-if="item.type === 'post'">
        <Post :id="item.id" :likes="item.likes" />
      </div>
      <div v-if="item.type === 'add_to_competition'">
        <AddToCompetition data="item" />
      </div>
      <div v-if="item.type === 'poll'">
        <Poll data="item" />
      </div>
      <div class="is-pulled-right actionIcons">
        <i class="fas fa-comments"></i>
        <i v-if="!bookmarked" class="far fa-star" @click.stop="bookmark"></i>
        <i v-if="bookmarked" class="fas fa-star"></i>
        <i class="far fa-thumbs-up" @click.stop="like">
          <b v-if="likes || item.likes">{{ likes || item.likes }}</b>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import Post from './Post'
import Article from './Article'
import Competition from './Competition'
import AddToCompetition from './AddToCompetition'
import Poll from './Poll'
import Chat from './Chat'
export default {
  name: 'PostTile',
  components: {
    Post,
    Article,
    Competition,
    AddToCompetition,
    Poll,
    Chat,
  },
  props: {
    item: Object,
    index: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      dropFiles: null,
      bookmarked: false,
      likes: null,
    }
  },
  methods: {
    like() {
      if (this.disableLike) {
        return
      }
      ApiService.posts.like(this.item.id).then((resp) => {
        this.likes = resp.data.likes
        this.disableLike = true
      })
    },
    bookmark() {
      ApiService.bookmarks
        .saveBookmark(this.item.id, this.item.type)
        .then(() => {
          this.bookmarked = true
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tileDate {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  color: white;
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.titleBlock {
  position: absolute;
  text-align: center;
  left: 0px;
  right: 0px;
  bottom: 31px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 2px 5px 2px;
  z-index: 9;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
div.contentBlock img {
  min-height: 200px;
  min-width: 240px;
  /* background: #cccccc; */
}
.actionIcons {
  padding-right: 5px;
}
.actionIcons i {
  margin-left: 10px;
  opacity: 0.5;
}
.actionIcons .fa-star {
  color: #d77000;
}
.actionIcons .fa-thumbs-up {
  color: #d77000;
}
.actionIcons .fa-star {
  color: #d77000;
}
.actionIcons .fa-comments {
  color: #d77000;
}
.mainImage {
  min-height: 200px;
}
</style>
