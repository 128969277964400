<template>
  <div class="wrap" :class="{ completed: item.completed }">
    <div
      class="iconContainer"
      :style="{
        background: item.options.background,
        'border-radius': item.radius || 10 + 'px',
      }"
    >
      <i :class="item.options.icon" :style="{ color: item.options.color }"></i>
    </div>
    <div class="circleAbs">
      <van-circle
        v-model="currentRate"
        :class="{ active: showCircle }"
        :rate="progress"
        :speed="40"
        size="50px"
        :color="item.options.color"
        :layer-color="item.options.background"
        :text="text"
      />
    </div>
    <div class="habitTitle">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'SingleHabit',
  data() {
    return {
      currentRate: 0,
      showCircle: false,
      progress: 0,
    }
  },
  watch: {
    'item.completed': function () {
      this.progress = parseInt(this.item.level) * 2

      if (this.item.completed) {
        this.showCircle = true
      }
    },
  },
  computed: {
    text() {
      return this.item.level + ' lvl'
    },
  },
  props: {
    item: Object,
  },
  mounted() {
    if (this.item.completed) {
      this.showCircle = true
      this.streak = parseInt(this.item.streak)
    }
  },
}
</script>
<style scoped lang="scss">
.iconContainer {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  transition: 0.3s ease-in-out;
  border: 1px solid #dedede;
  box-shadow: 0px 0px 10px #d6d6d6;
  /* filter: grayscale(0); */
  opacity: 1;
  i {
    font-size: 25px;
    line-height: 50px;
  }
}
.circleAbs {
  position: absolute;
  top: 5px;
  left: 25px;
  transition: 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: scale(0.5) rotate(180deg);
  opacity: 0;
}
.habitTitle {
  font-weight: bold;
  color: #616161;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  max-width: 100px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.normalText {
  color: #d6d6d6;
  text-align: left;
}
div.wrap {
  padding: 5px;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  transition: 0.2s border cubic-bezier(0.39, 0.575, 0.565, 1);
  text-align: center;
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
.wrap.completed {
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f5fff5;
  border: 1px solid #c2ecc2;
  .iconContainer {
    transform: scale(1.1) rotate(-180deg);
    /* filter: grayscale(1); */
    opacity: 0;
    border-radius: 25px !important;
  }
  .circleAbs {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
</style>
