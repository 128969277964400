const updatePosts = items => {
  items.forEach(element => {
    element.type = 'post'
    if (element.options) {
      element.options = JSON.parse(element.options)
      console.log(element.options.youtube_id)
      if (element.options.image) {
        element.image = element.options.image
      } else if (element.options.youtube_id) {
        element.image =
          'https://img.youtube.com/vi_webp/' + element.options.youtube_id + '/0.webp'
      } else {
        element.image = 'https://elegance.eu-central-1.linodeobjects.com/post_blank.webp'
      }
    } else {
      element.image = element.options.image
        ? element.options.image
        : 'https://elegance.eu-central-1.linodeobjects.com/post_blank.webp'
      element.showTitle = true
    }
  })
  return items
}
export { updatePosts }
