import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
// import Feed from '../views/onboarding/Feed.vue'
import Home from '../views/Home.vue'
import MembersTemplate from '../views/MembersTemplate.vue'

import Feed from '../views/main/Feed.vue'
import BeautyLatest from '../views/beauty/BeautyLatest.vue'
import BeautyBeauty from '../views/beauty/BeautyBeauty.vue'
import BeautyStyle from '../views/beauty/BeautyStyle.vue'
import BeautyHowTo from '../views/beauty/BeautyHowTo.vue'
import BeautyOther from '../views/beauty/BeautyOther.vue'

import Health from '../views/health/Health.vue'
import Workouts from '../views/health/Workouts.vue'
import WorkoutCategory from '../views/health/WorkoutCategory.vue'
import SingleWorkout from '../views/health/SingleWorkout.vue'
import HealthyEating from '../views/health/HealthyEating.vue'
import HealthHabits from '../views/health/HealthHabits.vue'
import HealthEasyWins from '../views/health/HealthEasyWins.vue'
import Breathing from '../views/health/Breathing.vue'
import Meditations from '../views/health/Meditations.vue'
import SingleMeditation from '../views/health/SingleMeditation.vue'
import Selfcare from '../views/health/Selfcare.vue'
import Other from '../views/health/Other.vue'

import SelfDevLatest from '../views/self_development/SelfDevLatest.vue'
import SelfDevPersonal from '../views/self_development/SelfDevPersonal.vue'
import SelfDevRelationship from '../views/self_development/SelfDevRelationship.vue'
import SelfDevFinance from '../views/self_development/SelfDevFinance.vue'
import SelfDevOther from '../views/self_development/SelfDevOther.vue'

import Habits from '../views/habits/Habits.vue'
import Goals from '../views/habits/Goals.vue'
import Todo from '../views/habits/Todo.vue'
import Journal from '../views/habits/Journal.vue'
import HabitsOther from '../views/habits/HabitsOther.vue'

import Messages from '../views/main/Messages.vue'
import Courses from '../views/main/Courses.vue'
import Together from '../views/main/Together.vue'

import Competitions from '../views/main/Competitions.vue'
import Bookmarks from '../views/main/Bookmarks.vue'
import Likes from '../views/main/Likes.vue'
import Friends from '../views/main/Friends.vue'
import Onboarding from '../views/main/Onboarding.vue'
import NotFound from '../views/main/404.vue';
import Logout from '../views/Logout.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/forgot_password',
    name: 'forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/members',
    name: 'membersTemplate',
    component: MembersTemplate,
    children: [
      {
        path: 'main/',
        name: 'main',
        component: Feed
      },
      {
        path: 'main/together/:section?',
        name: 'together',
        component: Together
      },
      // {
      //   path: 'main/challenge/:id?',
      //   name: 'challenge',
      //   component: SingleChallenge
      // },
      // {
      //   path: 'main/forum/:topicId/:topic?',
      //   name: 'forum',
      //   component: ForumTopic
      // },
      {
        path: 'main/courses',
        name: 'courses',
        component: Courses
      },
      {
        path: 'main/onboarding',
        name: 'onboarding',
        component: Onboarding
      },
      {
        path: 'messages',
        name: 'messages',
        component: Messages
      },
      {
        path: 'habits/daily',
        name: 'habits.daily',
        component: Habits
      },
      {
        path: 'habits/goals',
        name: 'habits.goals',
        component: Goals
      },
      {
        path: 'habits/todo',
        name: 'habits.todo',
        component: Todo
      },
      {
        path: 'habits/journal',
        name: 'habits.journal',
        component: Journal
      },
      {
        path: 'habits/other',
        name: 'habits.other',
        component: HabitsOther
      },
      {
        path: 'main/competitions',
        name: 'competitions',
        component: Competitions
      },
      {
        path: 'beauty_style/latest',
        name: 'beauty.latest',
        component: BeautyLatest
      },
      {
        path: 'beauty_style/beauty',
        name: 'beauty.beauty',
        component: BeautyBeauty
      },
      {
        path: 'beauty_style/style',
        name: 'beauty.style',
        component: BeautyStyle
      },
      {
        path: 'beauty_style/tips',
        name: 'beauty.tips',
        component: BeautyHowTo
      },
      {
        path: 'beauty_style/other',
        name: 'beauty.other',
        component: BeautyOther
      },
      {
        path: 'health_wellbeing/latest',
        name: 'health_wellbeing.latest',
        component: Health
      },
      {
        path: 'health_wellbeing/workouts',
        name: 'health_wellbeing.workouts',
        component: Workouts
      },
      {
        path: 'health_wellbeing/workout/:id',
        name: 'health_wellbeing.workout',
        component: SingleWorkout
      },
      {
        path: 'health_wellbeing/workout/category/:category',
        name: 'health_wellbeing.workout_category',
        component: WorkoutCategory
      },
      {
        path: 'health_wellbeing/healthy_eating',
        name: 'health_wellbeing.healthy_eating',
        component: HealthyEating
      },
      {
        path: 'health_wellbeing/healthy_eating/habits',
        name: 'health_wellbeing.healthy_eating.habits',
        component: HealthHabits
      },
      {
        path: 'health_wellbeing/healthy_eating/easy_wins',
        name: 'health_wellbeing.healthy_eating.easy_wins',
        component: HealthEasyWins
      },
      {
        path: 'health_wellbeing/breathing',
        name: 'health_wellbeing.breathing',
        component: Breathing
      },
      {
        path: 'health_wellbeing/selfcare',
        name: 'health_wellbeing.selfcare',
        component: Selfcare
      },
      {
        path: 'health_wellbeing/meditations',
        name: 'health_wellbeing.meditations',
        component: Meditations
      },
      {
        path: 'health_wellbeing/meditations/:category/:id',
        name: 'health_wellbeing.singleMeditation',
        component: SingleMeditation
      },
      {
        path: 'health_wellbeing/other',
        name: 'health_wellbeing.other',
        component: Other
      },
      {
        path: 'health_wellbeing/bmi_calculator',
        name: 'health_wellbeing.bmi_calculator',
        component: () =>
          import(
            /* webpackChunkName: 'other' */ '../views/health/BmiCalculator.vue'
          )
      },
      {
        path: 'health_wellbeing/bmr_calculator',
        name: 'health_wellbeing.bmr_calculator',
        component: () =>
          import(
            /* webpackChunkName: 'other' */ '../views/health/BmrCalculator.vue'
          )
      },
      {
        path: 'health_wellbeing/cbc_calculator',
        name: 'health_wellbeing.cbc_calculator',
        component: () =>
          import(
            /* webpackChunkName: 'other' */ '../views/health/CbcCalculator.vue'
          )
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () =>
          import(/* webpackChunkName: 'other' */ '../views/Feedback.vue')
      },
      {
        path: 'self_development/latest',
        name: 'self_development.latest',
        component: SelfDevLatest
      },
      {
        path: 'self_development/personal',
        name: 'self_development.personal',
        component: SelfDevPersonal
      },
      {
        path: 'self_development/relationship',
        name: 'self_development.relationship',
        component: SelfDevRelationship
      },
      {
        path: 'self_development/finance',
        name: 'self_development.finance',
        component: SelfDevFinance
      },
      {
        path: 'self_development/other',
        name: 'self_development.other',
        component: SelfDevOther
      },
      {
        path: 'self_development/other',
        name: 'self_development.other',
        component: SelfDevOther
      },
      {
        path: 'self_development/music',
        name: 'self_development.music',
        component: () =>
          import(/* webpackChunkName: 'other' */ '../views/self_development/Music.vue')
      },
      {
        path: 'self_development/books',
        name: 'self_development.books',
        component: () =>
          import(/* webpackChunkName: 'other' */ '../views/self_development/Books.vue')
      },
      {
        path: 'self_development/movies',
        name: 'self_development.movies',
        component: () =>
          import(/* webpackChunkName: 'other' */ '../views/self_development/Movies.vue')
      },
      {
        path: 'bookmarks',
        name: 'bookmarks',
        component: Bookmarks
      },
      {
        path: 'liked',
        name: 'liked',
        component: Likes
      },
      {
        path: 'my_profile',
        name: 'my_profile',
        component: () =>
          import(
            /* webpackChunkName: 'about' */ '../views/settings/Profile.vue'
          )
      },
      {
        path: 'avatar',
        name: 'avatar',
        component: () =>
          import(
            /* webpackChunkName: 'about' */ '../views/settings/Avatar.vue'
          )
      },
      {
        path: 'friends',
        name: 'friends',
        component: Friends
      },
      {
        path: 'about_us',
        name: 'about_us',
        component: () =>
          import(/* webpackChunkName: 'about' */ '../views/About.vue')
      },
      {
        path: 'contact_us',
        name: 'contact_us',
        component: () =>
          import(/* webpackChunkName: 'about' */ '../views/ContactUs.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        component: () =>
          import(/* webpackChunkName: 'about' */ '../views/FAQ.vue')
      }
    ]
  },
  {
    path: '/feed',
    name: 'feed',
    component: Feed
  },
  {
    path: '/beauty_style',
    name: 'beauty_style',
    component: BeautyStyle
  },

  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: Settings
  // },
  {
    path: '/home',
    name: 'home',
    component: Home
  },

  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: 'about' */ '../views/About.vue')
  },
  {
    path: '/code/:code/:code2',
    name: 'invitation_code',
    component: () =>
      import(
        /* webpackChunkName: 'invitation' */ '../views/onboarding/Invitation.vue'
      )
  },
  {
    path: '/invitation_completed',
    name: 'invitation_completed',
    component: () =>
      import(
        /* webpackChunkName: 'invitation' */ '../views/onboarding/InvitationCompleted.vue'
      )
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () =>
      import(
        /* webpackChunkName: 'invitation' */ '../views/onboarding/Registration.vue'
      )
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/admin',
    name: 'admin_template',
    component: () =>
      import(
        /* webpackChunkName: 'admin' */ '../views/admin/AdminTemplate.vue'
      ),
    children: [
      {
        path: 'dashboard/:page?',
        name: 'admin.dashboard',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminDashboard.vue'
          )
      },
      {
        path: 'posts/:page?',
        name: 'admin.posts',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminPosts.vue'
          )
      },
      {
        path: 'post/:id?',
        name: 'admin.edit_post',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminPostEdit.vue'
          )
      },
      // {
      //   path: 'posts/:page?',
      //   name: 'admin.posts',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'admin' */ '../views/admin/AdminPosts.vue'
      //     )
      // },
      {
        path: 'users/:page?',
        name: 'admin.users',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminUsers.vue'
          )
      },
      {
        path: 'other/',
        name: 'admin.other',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminOther.vue'
          )
      },
      {
        path: 'other/shopify',
        name: 'admin.shopify',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminShopify.vue'
          )
      },
      {
        path: 'competitions/:page?',
        name: 'admin.competitions',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminCompetitions.vue'
          )
      },
      {
        path: 'comments/:page?',
        name: 'admin.comments',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminComments.vue'
          )
      },
      {
        path: 'quotes/:page?',
        name: 'admin.quotes',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminQuotes.vue'
          )
      },
      {
        path: 'polls/:page?',
        name: 'admin.polls',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminPolls.vue'
          )
      },
      {
        path: 'invites',
        name: 'admin.invites',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminInvites.vue'
          )
      },
      {
        path: 'invites/generate',
        name: 'admin.invites_generate_new',
        component: () =>
          import(
            /* webpackChunkName: 'admin' */ '../views/admin/AdminInvitesGenerate.vue'
          )
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return setTimeout(() => {
        // return { x: 0, y: 0 }
      }, 300)
    }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
