<template>
  <div class="container">
    <div class="herosmall">
      <h4 class="title is-5">
        {{ item.title }} <van-tag v-if="!item.value" type="warning">Empty</van-tag>
      </h4>
      <i class="stats far fa-chart-bar" @click="action('stats')"></i>
      <i class="options fas fa-ellipsis-v" @click="action('menu')"></i>
    </div>
    <div class="columns is-center is-mobile">
      <div class="column">
        <div class="radioGroup">
          <span @click="select(3)"><i
            :class="{
              'fas fa-check-circle': item.value === '3',
              'far fa-circle': item.value !== '3',
            }"
          ></i>
            Yes</span>
          <span @click="select(1)"><i
            :class="{
              'fas fa-check-circle': item.value === '1',
              'far fa-circle': item.value !== '1',
            }"
          ></i>
            No</span>
        </div>
      </div>
    </div>
    <transition-group name="slide">
      <b-button key="button" size="is-dotdot" @click="toggleShowText">...</b-button>
      <div v-if="showingText || item.text" :key="1" class="optText columns">
        <div class="column is-paddingless">
          <b-input
            v-model="text"
            maxlength="200"
            type="textarea"
            rows="2"
            placeholder="Type here"
          ></b-input>
        </div>
      </div>
    </transition-group>
    <hr class="small" />
  </div>
</template>
<script>
export default {
  name: 'JournalYesNo',
  components: {},
  props: ['item'],
  data() {
    return {
      showingText: false,
      text: '',
    }
  },
  watch: {
    text(val, oldVal) {
      if (!oldVal) return
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.$emit('update', { field: 'value', value: this.text })
      }, 500)
    },
  },
  mounted() {
    if (this.item.text) {
      this.text = this.item.text
    }
  },
  methods: {
    select(value) {
      value = String(value)
      this.$emit('update', { field: 'value', value })
    },
    textKeyPress() {
      this.$emit('update', { field: 'text', value: this.text })
    },
    toggleShowText() {
      this.showingText = !this.showingText
    },
    action(type) {
      this.$emit('action', { title: this.item.title, type })
    },
    uploadedFile(file) {
      // only single image is allowed
      if (file) {
        this.$emit('update', {
          field: 'image',
          value: file.filename,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.herosmall {
  margin-bottom: 25px;
}
div.radioGroup {
  color: #058aff;
  transform: scale(1.15);
  display: inline-block;
  cursor: pointer;
  div {
    margin: auto;
    max-width: 200px;
  }
  span {
    padding-right: 20px;
  }
}
div.optText {
  max-width: 570px;
  margin: auto;
  i {
    font-size: 50px;
    transition: all ease 0.3s;
    &.fas {
      // font-size: 60px;
      transform: scale(1.2);
    }
  }
  .lbl {
    font-size: 12px;
    color: #7e7e7e;
  }
}
</style>
