<template>
  <b-button @click="$router.go(-1)" type="is-text" class="backButton"
    ><i class="fas fa-angle-left"></i
  ></b-button>
</template>

<script>
export default {
  name: 'Backbutton',
//   props: {
//     icon: String,
//     title: String,
//     color: String,
//     background: String,
//   },
}
</script>
<style scoped>
button{
  padding-left: 4px;
  padding-right: 4px;
}
</style>