<template>
  <div class="iconWrapper">
    <b-button class="previewButton" @click="isModalActive = true">
      <i :class="currentIcon" style="font-size: 24px; min-width: 30px;margin: 0;"></i>
    </b-button>
    <van-popup v-model="isModalActive" style="border-radius: 10px;">
      <div
        v-if="isModalActive"
        style="min-height: 400px; min-width: 300px; padding: 5px; border-radius: 15px;"
      >
        <i
          :class="newSelection.pack + ' ' + newSelection.name"
          style="font-size: 30px"
        ></i>
        <b-field label="Search for Icon">
          <b-autocomplete
            id="autocompl"
            v-model="searchFontInput"
            rounded
            :data="filteredDataArray"
            placeholder="e.g. Check"
            icon="fas fa-search"
            icon-pack="fas"
            :open-on-focus="true"
            :clearable="true"
            @select="
              (option) => {
                selected = option
              }
            "
          >
            <template slot="empty">No results found</template>
            <template slot-scope="props">
              <div class="columns is-mobile">
                <div class="column" @click="selectIcon(props.option, 'fas')">
                  <i class="fas" :class="'fa-' + props.option"></i>
                </div>
                <div class="column" @click="selectIcon(props.option, 'far')">
                  <i class="far" :class="'fa-' + props.option"></i>
                </div>
                <div class="column" @click="selectIcon(props.option, 'fal')">
                  <i class="fal" :class="'fa-' + props.option"></i>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Icons from '../constants/icons.json'
import PopularIcons from '../constants/popularIcons.json'
export default {
  name: 'IconPicker',
  props: {
    currentIcon: String,
  },
  data() {
    return {
      isModalActive: false,
      allIcons: Icons,
      selected: null,
      searchFontInput: null,
      newSelection: {},
    }
  },
  computed: {
    filteredDataArray() {
      if (!this.searchFontInput) {
        return PopularIcons
      }
      return Icons.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.searchFontInput.toLowerCase()) >= 0
        )
      })
    },
  },
  mounted() {
    // console.log('Icons', Icons)
  },
  methods: {
    selectIcon(name, pack) {
      name = 'fa-' + name
      this.newSelection = {
        name,
        pack,
      }
      this.$emit('selectIcon', `${pack} ${name}`)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#iconButton {
}
.columns {
  margin-right: -40px;
  i {
    font-size: 20px;
  }
}
.previewButton {
  padding: 5px;
  height: auto;
  border-radius: 10px !important;
  width: 42px;
  height: 42px;
}
.autocomplete .dropdown-content{
  max-height: 300px;
}
</style>
<style>
.autocomplete .dropdown-content{
  max-height: 300px;
  min-height: 290px !important;
}
</style>
