<template>
  <div class="comments">
    <div v-for="comment in comments" :key="comment.id">
      <div style="float: right;"><timeago class="is-size-7 darkgrey" :datetime="comment.unix_timestamp"></timeago></div>
      <div class="profileImage"><i class="fas fa-image" style="font-size: 30px; color: grey; line-height: 50px;"></i></div>
      <div><small><b>{{ comment.username }}</b></small></div>
      <p class="normalText">{{ comment.comment }}</p>
      <hr style="margin: 5px 0px;">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentsListing',
  props: {
    comments: Array
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.profileImage{
  width: 50px;
  height: 50px;
  float: left;
  text-align: center;
}
.timeago{
  color: #bbbbbb !important;
  line-height: 30px !important;
}
hr{
  height: 1px;
}
</style>
