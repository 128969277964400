<template>
  <section :class="{ fullscreen: fullscreen }">
    <div style="position: relative">
      <div
        class="timer"
        v-if="currentExercise.set_type === 'time' && restIsFinished == true"
      >
        <i class="fal fa-clock"></i> {{ secsLeft }}s
        <span v-if="currentExercise.show_weight">
          <i class="fal fa-weight-hanging"></i> {{ currentSet.weight }}
        </span>
      </div>
      <div
        class="timer"
        v-if="currentExercise.set_type !== 'time' && restIsFinished == true"
      >
        <i class="fal fa-repeat"></i> {{ currentSet.value }} reps
        <span v-if="currentExercise.show_weight">
          <i class="fal fa-weight-hanging"></i> {{ currentSet.weight }}
        </span>
      </div>
      <div class="timer" v-if="restIsFinished == false">
        <i class="fal fa-clock"></i> REST: {{ restSecLeft }}s
      </div>
    </div>
    <div class="card mb-3 mt-3">
      <div class="smallhero">
        <h3 class="" style="font-weight: 400" v-if="currentExercise.name">
          {{ currentExercise.name }}
          <b-button
            @click="toggleFullscreen"
            id="toggleFullscreenbtn"
            type="is-info is-light"
            size="is-small"
            style="float: right; padding: 5px"
            ><i v-if="fullscreen" class="fas fa-compress"></i
            ><i v-if="!fullscreen" class="fas fa-expand"></i>
            <span class="is-hidden-mobile">Toggle Full screen</span></b-button
          >
        </h3>
      </div>
      <div id="videoWrapper">
        <video
          :key="currentExercise.video"
          playsinline
          style="min-height: 400px"
          v-if="currentExercise && currentExercise.video && restIsFinished == true"
          height="300"
          poster="data:image/gif,AAAA"
          muted
          controls
          loop
          autoplay
          i
        >
          <source :src="currentExercise.video" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="controls columns is-mobile mb-0">
      <div class="column">
        <b-button @click="prevExercise" class="circularBtn"
          ><i class="fas fa-angle-left"></i
        ></b-button>
      </div>
      <div class="column"><b-button class="circularBtn">Info</b-button></div>
      <div class="column">
        <b-button
          class="circularBtn"
          @click="pauseInterval"
          v-if="interval && currentExercise.set_type == 'time'"
          :disabled="currentExercise.set_type != 'time'"
          >Pause</b-button
        >
        <b-button
          @click="resumeInterval"
          class="circularBtn"
          v-if="!interval && currentExercise.set_type == 'time'"
          >Resume</b-button
        >
      </div>
      <div class="column">
        <b-button @click="nextExercise" class="circularBtn"
          ><i class="fas fa-angle-right"></i
        ></b-button>
      </div>
    </div>
    <div class="progress"></div>
  </section>
</template>
<script>
// import ApiService from '@/services/ApiService'
import { next, prev } from '@/helpers/guideViewHelper.js'
export default {
  name: 'GuideView',
  data() {
    return {
      interval: null,
      secsLeft: 0,
      restSecLeft: 0,
      restIsFinished: true,
      currentSelection: {},
      currentIndex: 0,
      currentExercise: {},
      currentSet: {},
      // currentSupersetIndex: -1,
      // currentSupersetSetIndex: -1,
      // currentExerciseName: '',
      finish: false,
      showTime: false,
      showReps: false,
      fullscreen: false,
    }
  },
  props: {
    dataBlocks: Array,
    options: Object,
  },
  created() {
    this.currentSelection = {
      exerciseIndex: 0,
      supersetIndex: this.dataBlocks[0].multi ? 0 : -1,
      setIndex: 0,
    }
    if (this.dataBlocks[0].multi) {
      this.currentExercise = this.dataBlocks[0].items[0]
    } else {
      this.currentExercise = this.dataBlocks[0]
    }
    this.currentSet = this.currentExercise.sets[this.currentSelection.setIndex]
    if (this.currentExercise.set_type == 'time') {
      this.pauseInterval()
      this.secsLeft = this.currentSet.value
      this.interval = setInterval(this.secInterval, 1000)
    } else {
      this.pauseInterval()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    },
    showWorkoutCreation(id) {
      this.workoutCreationId = id
      this.isNewWorkoutModalActive = true
    },
    secInterval() {
      if (this.secsLeft > 0) {
        this.secsLeft = this.secsLeft - 1
      } else {
        this.pauseInterval()
        this.nextExercise()
      }
    },
    restInterval() {
      if (this.restSecLeft > 0) {
        this.restSecLeft = this.restSecLeft - 1
      } else {
        this.pauseInterval()
        this.nextExercise()
      }
    },
    pauseInterval() {
      clearInterval(this.interval)
      this.interval = null
    },
    resumeInterval() {
      if (this.restIsFinished == false && this.restSecLeft > 0) {
        this.interval = setInterval(this.restInterval, 1000)
      } else {
        this.interval = setInterval(this.secInterval, 1000)
      }
    },
    nextExercise() {
      this.pauseInterval()
      const newValues = next(this.dataBlocks, this.currentSelection)
      if (newValues.rest === true && this.restIsFinished == true) {
        this.restIsFinished = false
        this.restSecLeft = this.options.default_rest
        this.interval = setInterval(this.restInterval, 1000)
        return
      }
      this.restSecLeft = 0
      this.restIsFinished = true
      console.log('newValues', newValues)
      if (newValues.finish) {
        this.$emit('whenFinished', {})
        return
      }
      this.currentSelection = newValues
      if (newValues.supersetIndex > -1) {
        this.currentExercise =
          this.dataBlocks[newValues.exerciseIndex].items[
            newValues.supersetIndex
          ]
      } else {
        this.currentExercise = this.dataBlocks[newValues.exerciseIndex]
      }
      this.currentSet =
        this.currentExercise.sets[this.currentSelection.setIndex]

      if (this.currentExercise.set_type === 'time') {
        this.secsLeft = this.currentSet.value
        this.resumeInterval()
      }
    },
    prevExercise() {
      this.pauseInterval()
      const newValues = prev(this.dataBlocks, this.currentSelection)
      console.log('newV', newValues)
      if (newValues.start) {
        this.$dialog
          .confirm({
            message: 'Would you like to leave this workout ?',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          })
          .then(() => {
            this.$router.back()
            // on cancel
          })
        return
      }
      this.currentSelection = newValues
      if (newValues.supersetIndex > -1) {
        this.currentExercise =
          this.dataBlocks[newValues.exerciseIndex].items[
            newValues.supersetIndex
          ]
      } else {
        this.currentExercise = this.dataBlocks[newValues.exerciseIndex]
      }
      this.currentSet =
        this.currentExercise.sets[this.currentSelection.setIndex]

      if (this.currentExercise.set_type === 'time') {
        this.secsLeft = this.currentSet.value
        this.resumeInterval()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#videoWrapper {
  min-height: 68vh;
  /* background: red; */
}
video {
  border-radius: 5px;
  min-height: 50% !important;
  max-height: 58vh !important;
}
@media screen and (max-width: 758px) {
  video {
    /* min-height: 30vh !important;
    max-height: 350px !important; */
  }
  div.timer {
    top: 60px !important;
    font-size: 15px !important;
  }
}
section.fullscreen {
  position: fixed;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  div.card {
    margin-top: 0px !important;
    height: calc(100% - 50px);
    overflow: hidden;
    video {
      height: 100%;
      max-height: 83vh !important;
    }
  }
  div.controls {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 10px;
    /* width: 100%; */
    /* background: white; */
    margin-bottom: 0px;
  }
}
#toggleFullscreenbtn {
  min-width: 30px;
}
@media screen and (min-width: 768px) {
  #toggleFullscreenbtn {
    float: none;
    position: absolute;
    right: 0;
  }
}
div.controls .column {
  padding-bottom: 0px;
}
section.section {
  padding-top: 0px;
}
button.circularBtn {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 30px !important;
  background: #fff8eb;
  box-shadow: 0 0 8px #afafaf;
  font-size: 12px;
  transition: 0.3s all;
  border: 1px solid #ffe4af !important;
  color: #676767;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 5px #676767;
    border: 1px solid #ffe4af;
    background: #ffe8be;
  }
  i {
    font-size: 20px;
    margin: 0;
  }
}
div.timer {
  display: inline-block;
  position: absolute;
  z-index: 999;
  top: 30px;
  margin: auto;
  background: #ffc98f;
  min-width: 70px;
  max-width: 120px;
  /* height: 70px; */
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  border-radius: 5px;
  box-shadow: inset 0 0 3px #6b6b6b;
  left: 0;
  right: 0;
}
</style>