<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">Meditations</h4>
    </div>
    <section class="section2">
      <h4 class="title is-5"><strong>Our</strong> library</h4>
      <div class="horizontalSlider">
        <SingleBlock
          :key="index"
          v-for="(itm, index) in meditations.filter(
            (itm) => itm.type === 'our'
          )"
          :hue="40 + index * 30"
          :image="
            'https://elegance.eu-central-1.linodeobjects.com/' + itm.image
          "
          :text="itm.title"
          @click.native="() => goToMeditation(itm.id)"
        />
      </div>
      <h4 class="title is-5">
        <i class="fab fa-spotify" style="color: #1ad860"></i>
        <strong>Spotify</strong> meditations
      </h4>
      <div class="horizontalSlider">
        <SingleBlock
          :key="index"
          v-for="(itm, index) in meditations.filter(
            (itm) => itm.type === 'spotify'
          )"
          :hue="index * 4"
          :image="
            'https://elegance.eu-central-1.linodeobjects.com/' + itm.image
          "
          :text="itm.title"
          @click.native="() => goToMeditation(itm.id)"
        />
      </div>
      <h4 class="title is-5">
        <i class="fab fa-youtube" style="color: #fe0302"></i>
        <strong>Youtube</strong> meditations
      </h4>
      <div class="horizontalSlider">
        <SingleBlock
          :key="index"
          v-for="(itm, index) in meditations.filter(
            (itm) => itm.type === 'youtube'
          )"
          :hue="190 + index * 2"
          :image="
            'https://elegance.eu-central-1.linodeobjects.com/' + itm.image
          "
          :text="itm.title"
          @click.native="() => goToMeditation(itm.id)"
        />
      </div>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import SingleBlock from '@/components/SingleSmallBlock'
export default {
  name: 'Meditation',
  components: { SingleBlock },
  data() {
    return {
      meditations: [
        {
          type: 'our',
        },
        {
          type: 'youtube',
        },
        {
          type: 'spotify',
        },
      ],
    }
  },
  methods: {
    goToMeditation(id) {
      setTimeout(
        () => this.$router.push('/members/health_wellbeing/meditations/single/' + id),
        100
      )
    },
  },
  mounted() {
    ApiService.meditations.getMeditations(0).then((resp) => {
      this.meditations = resp.data
    })
  },
}
</script>
<style lang="scss" scoped>
section {
  /* padding: 5px; */
}
.box {
  display: inline-block;
}
section .hero {
  text-align: left;
}
.title.is-5 {
  font-weight: 400;
  margin-top: 5px;
  /* margin-bottom: 5px; */
}
.title strong {
  font-weight: 600;
}
</style>
