<template>
  <div id="limitedMainDiv" class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        Easy wins <small class="headerSmall">Health & Wellbeing</small>
      </h4>
    </div>
    <section>
      <div>
        <h5 class="title is-5">1. Get some sunshine</h5>
        <p>Sunchine is essential</p>
      </div>
      <div>
        <h5 class="title is-5">2. Drink more water</h5>
        <p>
          It's recommended at least 2 litres a day. It helps with weight loss
          and can help with better metabolism.
        </p>
      </div>
      <div>
        <h5 class="title is-5">3. No sugary drinks at home</h5>
        <p>
          This small rule/habit can help with weight loss and more energy. It's
          easy to commit to it.
        </p>
      </div>
      <div>
        <h5 class="title is-5">4. Walk enough if possible</h5>
        <p>Instead a bus, why not walk to a shop or a park.</p>
      </div>
      <div>
        <h5 class="title is-5">5. Avoid using gadgets, tv before sleep</h5>
        <p>
          It affects your sleep due to blue light and brightness of the screen.
        </p>
      </div>
      <div>
        <h5 class="title is-5">
          6. Use best alternatives/versions of junk food
        </h5>
        <p>
          Eating just healthy food is hardly possible, however it's possible to
          find great alternatives or at least pick something that's not that
          bad.
        </p>
      </div>
      <div>
        <h5 class="title is-5">
          7. Try to go to sleep around same time everyday
        </h5>
        <p>
          Having a sleep schedule will help you go to sleep faster and you won't
          have dreadful Monday mornings :).
        </p>
      </div>
      <div>
        <h5 class="title is-5">
          8. Put fruits, healthy snacks, nuts in easy to reach places, hide junk
          food, make it more difficult to access it.
        </h5>
        <p>
          Having a sleep schedule will help you go to sleep faster and you won't
          have dreadful Monday mornings :).
        </p>
      </div>
      <div>
        <h5 class="title is-5">
          9. Practice gratitude & positive affirmations
        </h5>
        <p>
          Positive affirmations combined with positive affirmations can have
          profound impact on your wellbeing. Try logging in a journal, what
          you're grateful for, why your day was special.
        </p>
      </div>
      <div>
        <h5 class="title is-5">10. Try meditation & deep breathing</h5>
        <p>
          Both Meditation and Deep breathing has profound effects on how you
          feel and helps relax too.
        </p>
      </div>
    </section>
  </div>
</template>
<style scoped>
.title.is-5 {
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 25px;
}
p {
  text-align: left;
  letter-spacing: 0.02em;
  font-weight: 200;
}
</style>
<script>
// import ApiService from '@/services/ApiService'

export default {
  name: 'HealthEasyWins',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>
