<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        <BackButton/> {{ title }} <small class="headerSmall">Meditation</small>
      </h4>
    </div>
    <section class="section2 is-text-centered">
      <v-audio-player
        v-if="type === 'our'"
        :audio="audio"
        ref="audio"
        style="margin:auto;"
      ></v-audio-player>
      <div v-if="type === 'spotify'">
        <div class="notification is-info" style="margin-bottom: 20px">
          In order to listen to Spotify you need an active account. <br>You can login to spotify.com or press inside a player to open a spotify app.
        </div>
      </div>
      <iframe
        class="spotify"
        v-if="type === 'spotify'"
        :src="source"
        width="300"
        height="80"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
      <div v-if="type === 'youtube'" id="youtubeWrapper">
        <youtube
          :video-id="source"
          player-height="300"
          :player-vars="{ autoplay: 1 }"
        ></youtube>
      </div>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import BackButton from '@/components/BackButton';
import VAudioPlayer from 'v-audio-player'
export default {
  name: 'SingleMeditation',
  components: {
    VAudioPlayer,
    BackButton
  },
  data() {
    return {
      title: '',
      type: null,
      source: null,
      audio: {
        name: 'Sometimes When We Touch',
        loop: true,
        url:
          'https://fengxianqi.github.io/v-audio-player/dist/media/sometimes_when_we_touch.010fafb2.mp3',
      },
    }
  },
  mounted() {
    ApiService.meditations.getMeditation(this.$route.params.id).then((resp) => {
      if (resp.data) {
        const meditation = resp.data;
        this.title = meditation.title
        if (meditation.type === 'our') {
          this.audio.url = meditation.source
          this.audio.name = meditation.title
        } else if (meditation.type === 'youtube') {
          this.source = meditation.source.split('?v=')[1]
        } else if (meditation.type === 'spotify') {
          this.source = meditation.source.replace(
            'open.spotify.com/',
            'open.spotify.com/embed/'
          )
        }
        this.type = meditation.type
      }
    })
  },
}
</script>
<style lang="scss" scoped>
section {
  padding: 5px;
}
.box {
  display: inline-block;
}
section .hero {
  text-align: left;
}
.title.is-5 {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
