<template>
  <div id="screenPage">
    <!-- <div class="hero">
      <h4 class="title is-4">Habbits & Tasks</h4>
    </div>-->
    <div class="hero">
      <h4 class="title is-4" style="padding-left: 10px;">Goals <small class="headerSmall">Coming soon</small></h4>
    </div>
    <!-- <div class="hero">
      <h4 class="title is-4">Goals <small class="headerSmall">{{ selected }}</small></h4>
    </div> -->

    <DaysWrapper
      :dates="dates"
      style="display: none;"
      :selected="selected"
      @selected="selectDate"
      @loadMore="loadMore"
    />
    <b-modal
      id="Modal"
      animation="fade"
      :active.sync="isHabbitModalActive"
      :width="540"
      full-screen
      scroll="keep"
    >
      <div
        class="card is-clearfix"
        style="padding-top: 1px; padding-bottom: 20px"
      >
        <div class>
          <div class="hero" style="padding-top: 1px">
            <h4 class="title is-4">New Habbit</h4>
          </div>
          <hr />
          <div class="container is-clearfix">
            <b-field label="Habbit">
              <b-input
                v-model="newHabbit.text"
                placeholder="Enter new Habbit"
              ></b-input>
            </b-field>
            <b-field label="Why you should do it(optional)">
              <b-input
                type="textarea"
                minlength="10"
                maxlength="100"
                placeholder="Short description optional"
              ></b-input>
            </b-field>
            <b-field label="Habbit Importance">
              <b-select placeholder="Importance">
                <option value="must">A must</option>
                <option value="vimportant">Very Important</option>
                <option value="important">Important</option>
              </b-select>
            </b-field>
            <b-field label="Reminder">
              <b-select placeholder="Reminder">
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
              </b-select>
            </b-field>
            <van-switch
              v-model="newHabbit.is_possitive"
              :true-value="1"
              type="is-success"
              :false-value="0"
            >{{
              newHabbit.is_possitive
                ? 'It is a possitive habbit'
                : 'It is a negative habbit'
            }}</van-switch>
            <hr />
            <div>
              <b-button type="is-primary">Save New Habbit</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DaysWrapper from '@/components/habits/DaysWrapper'
export default {
  name: 'Habits',
  components: {
    DaysWrapper,
    // SwipeOut,
    // SwipeList
  },
  data() {
    return {
      newHabbit: {},
      dates: [],
      selected: '',
      dayLabels: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      activeTab: 0,
      editHabitId: 0,
      checkbox: '',
      isHabbitModalActive: false,
      currentWeekDay: -1,
    }
  },
  computed: {
    allHabbits() {
      return this.positiveHabits.concat(this.negativeHabits)
    },
  },
  created() {
    this.addDays()
  },
  mounted() {
    const d = new Date()
    this.currentWeekDay = d.getDay()
  },
  methods: {
    addDays() {
      let now = new Date()
      let now2 = new Date()
      let dates = []

      for (var i = 0; i < 14; i++) {
        now.setDate(now.getDate() - 1) // sets the date to the previous day each time
        dates.unshift({
          fullDate: now.toISOString().split('T')[0],
          day: now.getDate(),
          month: now.getMonth(),
          label: this.dayLabels[now.getDay()],
        })
      }
      dates.push({
        fullDate: now2.toISOString().split('T')[0],
        day: now2.getDate(),
        month: now2.getMonth(),
        label: this.dayLabels[now2.getDay()],
        selected: true,
      })
      this.selected = now2.toISOString().split('T')[0]
      for (var y = 0; y < 14; y++) {
        now2.setDate(now2.getDate() + 1) // sets the date to the previous day each time
        dates.push({
          fullDate: now2.toISOString().split('T')[0],
          day: now2.getDate(),
          month: now2.getMonth(),
          label: this.dayLabels[now2.getDay()],
        })
      }
      this.dates = dates
      console.log('DATES', dates)
      this.$nextTick(() => {
        // this.animateDays()
      })
    },
    loadMore(date) {
      const splitDate = date.split('-')
      // let dates = []
      const dateObj = new Date(splitDate[0], splitDate[1] - 1, splitDate[2])
      for (var i = 0; i < 30; i++) {
        dateObj.setDate(dateObj.getDate() - 1) // sets the date to the previous day each time
        this.dates.unshift({
          fullDate: dateObj.toISOString().split('T')[0],
          day: dateObj.getDate(),
          month: dateObj.getMonth(),
          label: this.dayLabels[dateObj.getDay()],
        })
      }
      // console.log('dates', dates);
      // console.log('old dates', this.dates);
      // this.dates = this.dates.unshift(...dates);
      // console.log('new dates', this.dates);
    },
    selectDate(value) {
      this.selected = value;
    },
    addHabit() {
      this.isHabbitModalActive = true
    },
    getDayNames(year, month) {
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      var daysInMonth = new Date(year, month, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        var d = new Date(year, month, i)
        var dayName = days[d.getDay()]
        console.log(i + ' ' + dayName)
      }
    },
    toggleDailyCheck(habbitId, day) {
      if (this.habitsChecked[habbitId] && this.habitsChecked[habbitId][day]) {
        this.habitsChecked[habbitId][day] = false
      } else {
        this.habitsChecked[habbitId][day] = true
        console.log(this.habitsChecked)
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped>
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
small.is-size-9 {
  font-size: 9px;
  color: rgb(220, 220, 220);
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
figure.image {
  margin-right: 15px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}
div.dailyDiv {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
div.dailyDiv .column span.active {
  font-weight: bold;
  color: #167df0;
}
.dailyDiv .columns .column {
  border-right: 1px solid #f2f2f2;
}
div.dailyRow {
  border-bottom: 1px solid #f2f2f2;
}
.tabs li.is-active span {
  font-weight: bold;
}
.positiveHeader,
.negativeHeader {
  color: red;
  font-weight: 500;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
  margin-bottom: 10px;
  margin-bottom: 0px;
}
.positiveHeader {
  color: green;
  border-right: 1px solid #f2f2f2;
  margin-bottom: 0px;
}
.habitsWrapper > div:first-child {
  border-top: 0;
}
#positiveBlock {
  border-right: 1px solid #f2f2f2;
}
.habbitsBlock {
  position: relative;
  border-top: 1px solid #f2f2f2;
  padding: 10px;
}
.habbitsBlock button {
  display: none;
  color: red;
  font-weight: bold;
  font-size: 15px;
  background: none;
  border: none;
}
.habbitsBlock:hover button {
  display: inline;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.habbitsBlock.active button {
  display: inline;
  position: absolute;
  right: 0;
}
.container {
  padding: 0 10px;
}
.fa-check {
  color: green;
}
@media only screen and (max-width: 500px) {
  .dailyDiv {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dailyDiv .columns .column {
    padding: 15px 0px;
  }
}
.habitsWrapper .habbitsBlock {
  padding: 5px;
}
</style>
