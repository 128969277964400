import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {
  Modal,
  Icon,
  Input,
  Loading,
  Tabs,
  Button,
  Slider,
  Dropdown,
  Autocomplete,
  Tooltip,
  Select,
  Field as bField,
  Carousel
} from 'buefy'
import LongPress from 'vue-directive-long-press'
// import 'buefy/dist/buefy.min.css'
// Importing the global css file
import '@/assets/global.css'
import 'vue-swatches/dist/vue-swatches.css'
import 'vue-css-donut-chart/dist/vcdonut.css'
import BackToTop from 'vue-backtotop'
import VueYouTubeEmbed from 'vue-youtube-embed'
import InfiniteLoading from 'vue-infinite-loading'
import axios from 'axios'
// import VueTypedJs from 'vue-typed-js'
import VueTimeago from 'vue-timeago'
import Vue2TouchEvents from 'vue2-touch-events'
// import VModal from 'vue-js-modal'
import VueElementLoading from 'vue-element-loading'
import '@vant/touch-emulator'
import VueUploadComponent from 'vue-upload-component'
import Donut from 'vue-css-donut-chart'
// import VScrollLock from 'v-scroll-lock'
import EdgeCheck from 'vue-edge-check'
import checkView from 'vue-check-view'
Vue.use(checkView)
Vue.use(EdgeCheck)

Vue.directive('long-press', LongPress)
// Vue.use(VScrollLock, {
//   bodyScrollOptions: {
//     reserveScrollBarGap: true
//   }
// })
Vue.use(Donut)
import {
  Toast,
  Notify,
  SwipeCell,
  // Button,
  ShareSheet,
  Dialog,
  Uploader,
  Switch,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Empty,
  DatetimePicker,
  Form,
  Circle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  Divider,
  Lazyload,
  Cell,
  Tag,
  Progress,
  Field,
  Popover,
  Loading as VanLoading,
  Picker,
  Popup,
  Stepper,
  Image as VanImage,
  Sticky,
  ImagePreview,
  Calendar,
} from 'vant'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'

Locale.use('en-US', enUS)
import 'vant/lib/swipe-cell/index.css'
import 'vant/lib/progress/index.css'
import 'vant/lib/cell/index.css'
import 'vant/lib/field/index.css'
import 'vant/lib/popup/index.css'
import 'vant/lib/tag/index.css'
import 'vant/lib/button/index.css'
import 'vant/lib/switch/index.css'
import 'vant/lib/notify/index.css'
import 'vant/lib/image/index.css'
import 'vant/lib/picker/index.css'
import 'vant/lib/sticky/index.css'
import 'vant/lib/uploader/index.css'
import 'vant/lib/loading/index.css'
import 'vant/lib/dialog/index.css'
import 'vant/lib/stepper/index.css'
import 'vant/lib/image/index.css'
import 'vant/lib/swipe/index.css'
import 'vant/lib/circle/index.css'
import 'vant/lib/radio/index.css'
import 'vant/lib/radio-group/index.css'
import 'vant/lib/checkbox/index.css'
import 'vant/lib/checkbox-group/index.css'
import 'vant/lib/toast/index.css'
import 'vant/lib/image-preview/index.css'
import 'vant/lib/dropdown-item/index.css'
import 'vant/lib/dropdown-menu/index.css'
import 'vant/lib/calendar/index.css'
import 'vant/lib/popover/index.css'

Vue.use(Toast),
  Vue.use(Notify),
  Vue.use(Calendar),
  Vue.use(SwipeCell),
  Vue.use(Button),
  Vue.use(ShareSheet),
  Vue.use(Dialog),
  Vue.use(Uploader),
  Vue.use(Switch),
  Vue.use(Radio),
  Vue.use(RadioGroup),
  Vue.use(Checkbox),
  Vue.use(CheckboxGroup),
  Vue.use(DatetimePicker),
  Vue.use(Form),
  Vue.use(DropdownMenu),
  Vue.use(DropdownItem),
  Vue.use(Circle),
  Vue.use(Collapse),
  Vue.use(Divider),
  Vue.use(Lazyload),
  Vue.use(VanLoading),
  Vue.use(Progress),
  Vue.use(Picker),
  Vue.use(Popup),
  Vue.use(Stepper),
  Vue.use(VanImage)
  Vue.use(Sticky)
  Vue.use(ImagePreview)
  Vue.use(Empty)
  Vue.use(Popover)
  Vue.use(Tag),
  Vue.use(Cell),
  Vue.use(Field),
  Vue.use(bField),
  Vue.use(Slider),
  Vue.use(Input),
  Vue.use(Modal),
  // Vue.use(VModal, {
  //   dynamic: true,
  //   injectModalsContainer: true,
  //   dynamicDefaults: { clickToClose: true }
  // }),
  Vue.use(Vue2TouchEvents),
  Vue.use(VueElementLoading)

// import VueMatomo from 'vue-matomo
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('FileUpload', VueUploadComponent)
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en' // Default locale
})

Vue.prototype.$http = axios
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false
// Vue.use(Buefy, {
//   defaultIconPack: 'fas'
// })
Vue.use(Icon)
Vue.use(Modal)
Vue.use(Loading)
Vue.use(Tabs)
Vue.use(Dropdown)
Vue.use(Autocomplete)
Vue.use(Tooltip)
Vue.use(Carousel)
Vue.use(Select)

Vue.use(BackToTop)
Vue.use(VueYouTubeEmbed)
Vue.use(InfiniteLoading, {
  /* options */
})
// Vue.use(VueTypedJs)

new Vue({
  router,
  store,
  beforeCreate () {
    this.$store.commit('initialiseStore')
    Vue.prototype.$globalLoading = 'test'
  },
  render: h => h(App)
}).$mount('#app')
