import ApiService from '../services/ApiService';

export default{
  state: {
    posts: [],
    competitions: [],
    comments: [],
    quotes: [],
    polls: []
  },
  getters: {

  },
  mutations: {
    ['SET_ALL_POSTS'](state, payload){
      state.posts = payload
    }
  },
  actions: {
    async loadAllPosts({commit}){
      const result = await ApiService.posts.getPosts();
      commit('SET_ALL_POSTS', result.data);
    }
    
  }
}