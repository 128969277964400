<template>
  <div class="box" :style="{ background: '#' + item.b }">
    <div class="iconDiv">
      <i :class="item.i" :style="{ color: '#' + item.c }"></i>
    </div>

    <div class="text">
      <strong>{{ item.t }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HabitsSetionHabit',
  props: {
    item: Object,
  },
  computed: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box {
  padding: 0px;
  position: relative;
  margin-right: 14px;
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #b6b6b6;
  /* align-content: center; */
  align-items: center;
  div.iconDiv {
    width: 60px;
    /* line-height: 70px; */
    border-right: 1px solid #ccc;
    i {
      font-size: 20px;
      /* width: 100%; */
    }
  }
  .text {
    white-space: break-spaces;
    text-align: left;
    /* width: 100%; */
    padding: 10px;
    font-weight: 400;
    flex: 1;
    strong {
      font-weight: 400;
      font-size: 16px;
    }
  }
  div.bg {
    height: 170px;
  }
  i.abs {
    font-size: 255px;
    position: absolute;
    /* width: 100%; */
    opacity: 0.25;
    left: 100px;
    color: #ffffff;
  }
}
</style>
