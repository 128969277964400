<template>
  <div id="screenPage" class="login container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          style="display: none"
          @click="() => showWorkoutCreation('new')"
        >
          <i class="fas fa-question"></i> How to </b-button
        ><BackButton /> Workout
        <van-popover
          v-model="showPicker"
          trigger="click"
          :actions="view_choices"
          @select="onConfirm"
        >
          <template #reference>
            <small class="headerSmall is-size-6">
              {{ view || 'Guide view' }}
              <i class="fas fa-caret-down"></i>
            </small>
          </template>
        </van-popover>
      </h4>
    </div>
    <section class="section pt-0 pb-0">
      <transition name="fade">
        <div
          class="card pt-1"
          v-if="started === false"
          style="max-width: 500px; margin: auto; overflow: hidden"
        >
          <van-image
            v-if="!data.image"
            :src="workout.image"
            style="margin-top: -4px; max-height: 260px; overflow: hidden"
            lazy-load
            fit="cover"
          ></van-image>
          <div class="hero">
            <h1 class="title is-size-4">
              {{ workout.title || 'Workout title' }}
            </h1>
          </div>
          <hr class="small" />
          <div class="is-text-centered"></div>
          <div class="hero">
            <h3 class="title is-size-5">Workout details</h3>
          </div>
          <div
            style="
              line-height: 35px;
              max-width: 300px;
              margin: auto;
              text-align: left;
            "
          >
            <h4>
              <i class="fal fa-clock"></i> Time required:
              <strong>{{ workout.time_required || '10min' }}</strong>
            </h4>
            <h4>
              <i class="fal fa-bullseye"></i> Focus especially on:
              <strong>{{ workout.body_group || 'Full body' }}</strong>
            </h4>
            <h4>
              <i class="fal fa-dumbbell"></i> Equipment required:
              <strong>{{ workout.equipment || 'None' }}</strong>
            </h4>
            <!-- <h4>
              <i class="fal fa-list"></i>
              Excercises count:
              <strong>{{ (dataBlocks && dataBlocks.length) || 0 }}</strong>
            </h4> -->
          </div>
          <hr class="small" />
          <div class="hero">
            <h3 class="title is-size-6">Exercises:</h3>
          </div>
          <div style="padding: 10px; text-align: left">
            <div :key="index" v-for="(itm, index) in dataBlocks">
              <div
                v-if="itm.multi"
                style="
                  padding: 0px 0px 0px 15px;
                  background: #fff7f2;
                  margin: 0px;
                "
              >
                <van-tag type="warning">{{
                  itm.items.length > 3 ? 'Circuit' : 'Superset'
                }}</van-tag>
                <SingleExercise
                  :key="index2"
                  v-for="(itm2, index2) in itm.items"
                  :item="itm2"
                />
              </div>
              <SingleExercise v-if="!itm.multi" :item="itm" />
            </div>
          </div>
          <div class="actionDiv pb-5 pt-5">
            <b-button type="is-success" @click="start()"
              ><i class="fal fa-thumbs-up"></i> Start workout!</b-button
            >
          </div>
        </div>
      </transition>
      <GuideView
        v-if="started === true && isFinished === false && view === 'Guide view'"
        :dataBlocks="dataBlocks"
        :options="workout.options"
        @whenFinished="finished"
      />
      <CompactView
        v-if="
          started === true && isFinished === false && view === 'Compact view'
        "
        :dataBlocks="dataBlocks"
        :options="workout.options"
        @whenFinished="finished"
      />
      <transition name="fade">
        <div
          class="card pt-1"
          v-if="isFinished === true"
          style="max-width: 500px; margin: auto; overflow: hidden"
        >
          <div class="hero">
            <h1 class="title is-size-4">
              {{ workout.title || 'Workout title' }}
            </h1>
          </div>
          <hr class="small" />
          <div class="is-text-centered"></div>
          <div class="hero">
            <h3 class="title is-size-5">Overview</h3>
          </div>
          <div
            style="
              line-height: 35px;
              max-width: 300px;
              margin: auto;
              text-align: left;
            "
          ></div>
          <div style="padding: 10px; text-align: left; filter: grayscale(70%)">
            <div :key="index" v-for="(itm, index) in dataBlocks">
              <div
                v-if="itm.multi"
                style="
                  padding: 0px 0px 0px 15px;
                  background: #fff7f2;
                  margin: 0px;
                "
              >
                <van-tag type="warning">{{
                  itm.items.length > 3 ? 'Circuit' : 'Superset'
                }}</van-tag>
                <SingleExercise
                  :key="index2"
                  v-for="(itm2, index2) in itm.items"
                  :item="itm2"
                />
              </div>
              <SingleExercise v-if="!itm.multi" :item="itm" />
            </div>
          </div>
          <div class="actionDiv pb-5 pt-5">
            <b-button type="is-success" @click="start()" disabled="true"
              ><i class="fal fa-save"></i> Save to Journal!</b-button
            >
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>
<script>
import ApiService from '@/services/ApiService'
import CompactView from '@/components/workout_views/CompactView'
import GuideView from '@/components/workout_views/GuideView'
import BackButton from '@/components/BackButton'
import SingleExercise from '@/components/SingleExercise'
export default {
  name: 'SingleWorkout',
  data() {
    return {
      data: {},
      workout: {},
      dataBlocks: [],
      exercises: [],
      view: 'Guide view',
      showPicker: false,
      started: false,
      isFinished: false,
      view_choices: [{ text: 'Guide view' }, { text: 'Compact view' }],
    }
  },
  components: {
    CompactView,
    GuideView,
    BackButton,
    SingleExercise,
  },
  created() {
    ApiService.workouts.getSingleWorkout(this.$route.params.id).then((resp) => {
      this.workout = resp.data.workout
      console.log('Workout', this.workout)
      let ids = []
      this.workout.data.forEach((element) => {
        if (element.multi == true) {
          element.items.forEach((multiItem) => {
            ids.push(multiItem.id)
          })
        } else {
          ids.push(element.id)
        }
      })
      this.loadExercises(ids)
    })
  },
  mounted() {
    // this.interval = setInterval(this.secInterval, 1000)
    // this.secsLeft = 50
  },
  beforeDestroy() {},
  methods: {
    showWorkoutCreation(id) {
      this.workoutCreationId = id
      this.isNewWorkoutModalActive = true
    },
    onConfirm(value) {
      this.view = value.text
    },
    start() {
      this.started = true
    },
    loadExercises(ids) {
      ApiService.workouts.getExercises(ids).then((resp) => {
        this.exercises = resp.data.exercises
        this.convertIntoBlocks()
      })
    },
    convertIntoBlocks() {
      let exercise
      this.workout.data.forEach((element) => {
        if (element.multi == true) {
          element.items.forEach((multiItem) => {
            exercise = this.exercises.filter((itm) => itm.id === multiItem.id)
            if (exercise.length > 0) {
              multiItem.name = exercise[0].name
              if (exercise[0].video) {
                multiItem.video = exercise[0].video
              } else if (exercise[0].youtube) {
                multiItem.youtube = exercise[0].youtube
              }
              if (exercise[0].image) {
                multiItem.image = exercise[0].image
              }
              multiItem.instructions = exercise[0].instructions
              multiItem.equipment = exercise[0].equipment
            }
          })
        } else {
          exercise = this.exercises.filter((itm) => itm.id === element.id)
          if (exercise.length > 0) {
            element.name = exercise[0].name
            if (exercise[0].video) {
              element.video = exercise[0].video
            } else if (exercise[0].youtube) {
              element.youtube = exercise[0].youtube
            }
            if (exercise[0].image) {
              element.image = exercise[0].image
            }
            element.instructions = exercise[0].instructions
            element.equipment = exercise[0].equipment
          }
        }
      })
      this.dataBlocks = this.workout.data
      console.log('Data blocks', this.workout.data)
    },
    finished() {
      this.isFinished = true
    },
  },
}
</script>