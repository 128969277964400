<template>
  <div id="mainapp">
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true">
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin"
      ></b-icon>
    </b-loading>
    <div class="is-hidden-mobile is-paddingless">
      <p class="has-text-grey-light is-size-7" style="padding-right: 2px">
        Project being updated weekly. We would be grateful for your
        <router-link to="/members/feedback">feedback</router-link>!
      </p>
    </div>
    <div
      class="
        columns
        is-centered is-vcentered is-marginless is-mobile is-hidden-tablet
      "
      v-show="$route.path.includes('members/main')"
    >
      <div class="is-one-third column is-paddingless">
        <router-link to="/members/main">
          <img src="@/assets/logo.png" style="height: 60px" alt />
        </router-link>
      </div>
      <div class="column is-paddingless">
        <p class="has-text-grey-light is-size-7" style="padding-right: 2px">
          Project being updated weekly. We would be grateful for your
          <router-link to="/members/feedback">feedback</router-link>!
        </p>
      </div>
      <div id="toprightmenu">
        <button class="noStyleButton is-hidden-tablet" @click="showSearchModal">
          <i class="fas fa-search fa-2x"></i>
        </button>
        <!-- <router-link
          to="/members/habits"
          :class="{ 'router-link-exact-active' :$route.path.includes('members/habits')}"
          class="is-hidden-tablet"
        >
          <i class="fas fa-tasks fa-2x"></i>
        </router-link> -->
        <!-- <router-link
          to="/members/friends"
          :class="{ 'router-link-exact-active' :$route.path.includes('members/friends')}"
        >
          <i class="fas fa-user-friends fa-2x"></i>
        </router-link>-->
        <router-link
          to="/members/messages"
          :class="{
            'router-link-exact-active':
              $route.path.includes('members/messages'),
          }"
          class="is-hidden-tablet"
        >
          <i class="fas fa-envelope fa-2x"></i>
          <van-tag type="is-danger">1</van-tag>
        </router-link>
      </div>
    </div>
    <van-sticky id="top_menu">
      <section style="overflow: hidden">
        <div id="nav" class="columns is-mobile menuDiv">
          <div class="column is-hidden-mobile">
            <router-link
              to="/members/main"
              :class="{
                'router-link-exact-active': $route.path.includes('main'),
              }"
              id="logoLink"
            >
              <img src="@/assets/logo.png" alt />
            </router-link>
          </div>
          <div class="column">
            <router-link
              to="/members/main"
              :class="{
                'router-link-exact-active': $route.path.includes('main'),
              }"
            >
              <i class="fas fa-home fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Main</small>
            </router-link>
          </div>
          <div class="column">
            <router-link
              to="/members/habits/daily"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('members/habits'),
              }"
            >
              <i class="fas fa-tasks fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Habits & Journal</small>
            </router-link>
          </div>
          <div class="column">
            <router-link
              to="/members/beauty_style/latest"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('beauty_style'),
              }"
            >
              <i class="fas fa-female fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Beauty & Style</small>
            </router-link>
          </div>
          <div class="column">
            <router-link
              to="/members/health_wellbeing/latest"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('health_wellbeing'),
              }"
            >
              <i class="fas fa-heart fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Health & Wellbeing</small>
            </router-link>
          </div>
          <div class="column">
            <router-link
              to="/members/self_development/latest"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('self_development'),
              }"
            >
              <i class="fas fa-brain fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Self dev</small>
            </router-link>
          </div>
          <div class="column is-hidden-mobile">
            <router-link
              to="/members/messages"
              :class="{
                'router-link-exact-active':
                  $route.path.includes('members/messages'),
              }"
            >
              <i class="fas fa-envelope fa-lg icon is-medium"></i>
              <br />
              <small class="is-hidden-mobile">Messages</small>
            </router-link>
          </div>
          <div class="column">
            <button class="noStyleButton" @click="isMenuModalActive = true">
              <b-icon pack="fas" icon="ellipsis-v" size="is-medium"></b-icon>
              <br />
              <!-- {{ $route.name }} -->
              <small class="is-hidden-mobile">
                <!-- <strong>Menu</strong> -->
              </small>
            </button>
          </div>
        </div>
      </section>
      <div id="submenu">
        <transition name="fade">
          <div
            v-if="$route.path.includes('main')"
            class="columns is-mobile"
            key="main"
          >
            <div class="column is-paddingless">
              <router-link :to="{ name: 'main' }">Feed</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link
                :to="{ name: 'together' }"
                :class="{
                  'router-link-exact-active': $route.path.includes(
                    'members/main/together'
                  ),
                }"
              >
                <b>Together</b>
              </router-link>
            </div>
            <!-- <div class="column is-paddingless">
          <router-link :to="{name: 'messages'}">My messages</router-link>
          </div>-->
            <div class="column is-paddingless">
              <router-link :to="{ name: 'onboarding' }">Onboarding</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'courses' }">Courses</router-link>
            </div>

            <div class="column is-paddingless">
              <router-link :to="{ name: 'competitions' }"
                >Competitions</router-link
              >
            </div>
          </div>
          <div
            v-if="$route.path.includes('beauty_style')"
            class="columns is-mobile"
            key="beauty"
          >
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'beauty.latest' }">Latest</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'beauty.beauty' }">Beauty</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'beauty.style' }">Style</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'beauty.tips' }">Tips</router-link>
            </div>
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'beauty.other' }">Other</router-link>
            </div>
            <!-- <div class="column is-paddingless">Competitions</div> -->
            <!-- <div class="column is-paddingless">My Gallery</div> -->
          </div>
          <div
            v-if="$route.path.includes('health_wellbeing')"
            class="columns is-mobile"
            key="health"
          >
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'health_wellbeing.latest' }"
                >Latest</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'health_wellbeing.workouts' }"
                >Workouts</router-link
              >
            </div>
            <div class="column is-paddingless flex06">
              <router-link
                class="disabled"
                :to="{ name: 'health_wellbeing.healthy_eating' }"
                >Food</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'health_wellbeing.meditations' }"
                >Meditations</router-link
              >
            </div>
            <div class="column is-paddingless is-hidden-mobile">
              <router-link :to="{ name: 'health_wellbeing.breathing' }"
                >Breathing</router-link
              >
            </div>
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'health_wellbeing.other' }">
                Other
              </router-link>
            </div>
          </div>
          <div
            v-if="$route.path.includes('self_development')"
            class="columns is-mobile"
            key="self"
          >
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'self_development.latest' }"
                >Latest</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'self_development.personal' }"
                >Personal</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'self_development.relationship' }"
                >Relationship</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'self_development.finance' }"
                >Finance</router-link
              >
            </div>
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'self_development.other' }"
                >Other</router-link
              >
            </div>
            <!-- <div class="column is-paddingless">Books</div> -->
          </div>
          <div
            v-if="$route.path.includes('members/habits')"
            class="columns is-mobile"
          >
            <div class="column is-paddingless">
              <router-link :to="{ name: 'habits.daily' }">Habits</router-link>
            </div>
            <!-- <div class="column is-paddingless">
            <router-link :to="{name: 'habits'}">Habits</router-link>
          </div> -->
            <div class="column is-paddingless">
              <router-link :to="{ name: 'habits.journal' }"
                >Journal</router-link
              >
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'habits.todo' }">To-do</router-link>
            </div>
            <div class="column is-paddingless">
              <router-link :to="{ name: 'habits.goals' }">Goals</router-link>
            </div>
            <div class="column is-paddingless flex06">
              <router-link :to="{ name: 'habits.other' }">Other</router-link>
            </div>
          </div>
        </transition>
      </div>
    </van-sticky>
    <transition name="slide-fade" mode="out-in">
      <router-view
        class="slideRouterAnimation"
        :class="{
          'is-edge-back': $isEdgeLeft,
          'is-edge-forward1': $isEdgeRight,
        }"
      />
    </transition>
    <van-popup
      v-model="show"
      closeable
      close-icon="f fas fa-times"
      position="bottom"
      transition="slide-fade"
      style="min-height: 50vh"
      @closed="item = {}"
    >
      <PostModal
        v-if="item.id"
        :id="item.id"
        :content="item.content"
        :title="item.title"
        :options="item.options"
      />
    </van-popup>
    <back-to-top visibleoffset="200">
      <i class="fas fa-chevron-up"></i>
    </back-to-top>
    <b-modal
      id="menuModal"
      animation="slide-fade"
      :active.sync="isMenuModalActive"
      :width="330"
    >
      <div class="card">
        <h4 class="title is-4">Menu</h4>
        <div class>
          <div class="content">
            <!-- <router-link to="/members/main/forum">
              <b-button class="fullwidth" type="is-default" expanded>Forum</b-button>
            </router-link>-->
            <router-link to="/members/messages" @click.native="closeMenu">
              <span>My Messages</span>
            </router-link>
            <router-link to="/members/habits/daily" @click.native="closeMenu">
              <span>Habits</span>
            </router-link>
            <!-- <router-link to="/members/friends" @click.native="closeMenu">
              <b-button class="fullwidth" type="is-default" expanded>Friends</b-button>
            </router-link>-->
            <router-link to="/members/bookmarks" @click.native="closeMenu">
              <span>Bookmarks</span>
            </router-link>
            <router-link
              to="/members/liked"
              class="disabled"
              @click.native="closeMenu"
            >
              <span>Liked Items</span>
            </router-link>
            <div style="margin-top: 20px">
              <router-link
                to="/members/my_profile"
                class="disabled2"
                @click.native="closeMenu"
              >
                <span>My Profile</span>
              </router-link>
              <router-link to="/members/about_us" @click.native="closeMenu">
                <span>About us</span>
              </router-link>
              <router-link to="/members/faq" @click.native="closeMenu">
                <span>F.A.Q.</span>
              </router-link>
              <router-link to="/members/contact_us" @click.native="closeMenu">
                <span>Contact us</span>
              </router-link>
              <router-link to="/logout" @click.native="closeMenu">
                <span>Log out</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="content"></div>
        <div id="profile"></div>
        <div id="menuImage" style></div>
      </div>
    </b-modal>
    <b-modal
      id="searchModal"
      animation="zoom-in"
      :active.sync="isSearchModalActive"
      :width="330"
      :height="500"
      scroll="keep"
    >
      <div
        id="searchImage"
        class="card"
        style="min-height: 500px; padding-top: 1px"
      >
        <div class="hero">
          <h4 class="title is-4">Search</h4>
        </div>
        <div class>
          <div class="content">
            <b-field label="Find a page or a post">
              <b-autocomplete
                v-model="searchInput"
                rounded
                :data="filteredDataArray"
                placeholder="e.g. How to"
                icon="fas fa-search"
                clearable
                icon-pack="fas"
                @select="
                  (option) => {
                    selected = option
                    test(option)
                  }
                "
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>

        <div class="content"></div>
      </div>
    </b-modal>
    <footer class="footer" style="padding: 5px">
      <div class="content has-text-centered">
        <small style="font-size: 10px"
          >&copy; Copyright 2021 London Elegance Club. Some rights
          reserved.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import PostModal from '@/components/modals/PostModal.vue'
import UserModal from '@/components/modals/UserModal.vue'
export default {
  name: 'MembersTemplate',
  components: {
    PostModal,
    // HelloWorld
  },
  // eslint-disable-next-line no-unused-vars
  beforeMount() {
    if (!this.$store.state.general.token) {
      this.$notify({ type: 'warning', message: 'You need to sign in first.' })
      this.$router.push('/')
    }
  },
  data() {
    return {
      isImageModalActive: false,
      isMenuModalActive: false,
      isSearchModalActive: false,
      show: false,
      item: {},
      searchItems: [
        'Liked',
        'Self development',
        'Health',
        'Style',
        'Beauty',
        'Friends',
        'Messages',
        'Bookmarks',
        'Profile',
        'Settings',
        'Account',
        'About',
      ],
      searchInput: '',
    }
  },
  computed: {
    filteredDataArray() {
      return this.searchItems.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.searchInput.toLowerCase()) >= 0
        )
      })
    },
    isLoading() {
      return this.$store.state.general.loading
    },
  },
  created() {
    // this.$root.$on('showPost', args => {
    //   this.openModal('post', args)
    // })
  },
  methods: {
    openModal(item) {
      this.item = {}
      let modalOptions = {
        parent: this,
        hasModalCard: true,
        customClass: 'custom-class',
        width: 700,
        props: item,
        animation: 'fade',
      }

      this.item = item

      if (item.type === 'post') {
        modalOptions.component = PostModal
      } else if (item.type === 'user') {
        modalOptions.component = UserModal
      }
      // this.$buefy.modal.open(modalOptions)
      this.show = true
    },
    closeMenu() {
      this.isMenuModalActive = false
    },
    showSearchModal() {
      this.isSearchModalActive = true
    },
    test(option) {
      console.log(option)
      this.$router.push('/members/beauty_style/latest')
    },
  },
}
</script>
<style>
#nav i.fa-2x {
  font-size: 1.5em;
}
#nav i.fa-2x.fa-bars {
  font-size: 1.7em;
}
#top_menu .van-sticky--fixed {
  max-width: 1400px;
  margin: auto;
}
</style>
<style lang="scss" scoped>
$borderColor: #f7f7f7;
$menuColor: #ffbd73;
$submenuColor: lighten($menuColor, 25%);
$subMenuColor: lighten($menuColor, 15%);
#mainapp {
  background: white !important;
  min-height: calc(100vh - 300px);
}
#nav {
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 4px;
  padding-bottom: 2px;
  border-top: 1px solid $borderColor;
  /* border-bottom: 1px solid $borderColor; */
  background: #fdfdfd;
  a {
    display: inline-block;
    min-width: 100px;
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    small {
      font-size: 0.6em;
      letter-spacing: 0.5px;
      // display: none;
    }
  }
  a.router-link-exact-active {
    color: $menuColor;
    small {
      opacity: 1;
    }
  }
  #logoLink {
    height: 47px;
    overflow: hidden;
    &:hover {
      img {
        transform: translate(0px, -47px) scale(1.05);
      }
    }
    img {
      transition: 0.2s transform ease;
      height: 75px;
    }
  }
}
#toprightmenu {
  a.router-link-exact-active {
    color: $menuColor;
  }
}
#submenu {
  background: $submenuColor;
  position: relative;
  padding: 10px;
  min-height: 33px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  // border-bottom: 1px solid rgb(230, 230, 230);
  // border-right: 1px solid #dddddd;
  border: 1px solid #dddddd;
  border-top: 1px solid #eeeeee;
  overflow: hidden;
  // box-shadow: rgba(50, 50, 50, 0.2) 2px 2px 2px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    color: #616161;
    transition: all 0.35s ease;
    letter-spacing: 0.5px;
    box-shadow: inset 0px 0px 0px 0px #fff6ed;
    background: linear-gradient(180deg, #fff9f3 0%, #fff9f3 100%);
  }

  a.router-link-exact-active {
    // background: #fff1e0;
    /* background: $subMenuColor; */
    background: linear-gradient(180deg, #ffecd9 0%, #ffe1c0 100%);
    color: #616161;
    box-shadow: inset 0px -2px 2px 1px #ffdfbd;
  }
  .column {
    padding: 0;
    border-right: 1px solid #dddddd;
  }
  .columns {
    position: absolute;
    /* top: 1px;
    left: 1px;
    right: 1px; */
    width: 100%;
  }
}
hr {
  // padding: 0;
  // margin: 0;
  background-color: #dadada;
  height: 1px;
}
.menuDiv small {
  font-size: 8px;
  display: inline-block;
  line-height: 11px;
}
.noStyleButton {
  border: 0;
  padding: 3px;
  background: none;
  cursor: pointer;
  color: #616161;
}
.fullwidth {
  display: block;
  width: 100%;
}
.modal.is-active {
  z-index: 9999;
}
#menuModal {
  .modal-content {
    // used in global.css
  }
  #menuImage {
    position: absolute;
    top: 110px;
    left: 170px;
    bottom: 0px;
    width: 180px;

    // background: url('https://s3.envato.com/files/272242816/CocheBurgos-23.jpg');
    background: url('~@/assets/menubg.jpg') no-repeat center top;
    background-size: cover;
    background-position: -50px;
    box-shadow: #bbb -5px 4px 10px;
    border-radius: 5px;
  }
  .card {
    // border: 3px solid rgb(220, 220, 220);
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
    // background: url('');
    // background-position: -150px ;
    // background-size: cover;
    background-repeat: no-repeat;
    // background: rgb(255,255,255);
    // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(227,227,227,1) 51%, rgba(235,235,235,1) 100%);
    padding: 1.5rem;
    // padding-top: 0;
    padding-bottom: 20px;
    height: 100%;
    .content {
      margin-top: 80px;
      max-width: 130px;
      a {
        font-family: 'Roboto', sans-serif;
        line-height: 2.15em;
        font-weight: 200;
        padding-left: 0;
        color: #5f5f5f;
        border-bottom: 1px solid #f9f9f9;
        border-radius: 0 !important;
        display: block;
        text-align: left;
        &:hover {
          color: #2e2e2e;
        }
      }
    }
  }

  button {
    // margin-bottom: 2px;
    font-family: 'Roboto', sans-serif;
    height: 2.15em;
    // opacity: 90%;
    border: none;
    text-align: left;
    font-weight: 200;
    padding-left: 0;
    border-bottom: 1px solid #f9f9f9;
    border-radius: 0 !important;
  }
  h2 {
    font-weight: 700;
  }
}
.footer {
  margin-top: 10px;
  background: $submenuColor;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}
.flex06 {
  flex: 0.6;
}
#searchImage {
  padding: 0 10px 0 10px;
  background: rgb(255, 233, 209);
  background: linear-gradient(
    180deg,
    rgba(255, 233, 209, 1) 0%,
    rgba(255, 210, 160, 1) 100%
  );
  // background: url('~@/assets/searchSky.jpg') no-repeat center center;
  // background-size: cover;
  background-attachment: fixed;
}
#toprightmenu {
  a {
    color: #2c3e50;
    padding: 10px;
    i {
      font-size: 1.5em;
    }
    span.tag {
      border-radius: 15px;
      padding: 0px;
      padding-left: 0.25em;
      padding-right: 0.25em;
      margin-left: -5px;
      height: 1em;
    }
  }
  text-align: right;
}
.slideRouterAnimation {
  /* will-change: transform; */
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate3d(0, 4px, 0);
  opacity: 0;
}
// EXTRA: this component should have no transition with edge forward/back
.is-edge-forward.slide-fade-enter-active,
.is-edge-back.slide-fade-leave-active {
  transition: all 0s;
  opacity: 0;
}

// EXTRA: this component should not display at slide-fade-leave start
// or else it will blink
.is-edge-back.slide-fade-leave {
  transform: translate3d(0, 4px, 0);
}

// EXTRA: this component should display and not move at slide-fade-enter start
// or else it will jump
.is-edge-forward.slide-fade-enter {
  transform: translate3d(0, 0, 0);
}
</style>
