<template>
  <div id="screenPage">
    <!-- <div class="hero">
      <h4 class="title is-4">Habbits & Tasks</h4>
    </div>-->
    <div class="hero">
      <h4 class="title is-4" style="padding-left: 5px">
        <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          @click="showTaskModal"
          style="padding-right: 5px"
        >
          <i class="far fa-plus is-hidden-mobile"></i> <b>New</b> Todo/Event
        </b-button>
        Todo & Planner
        <small class="headerSmall">{{ selectedDateText }}</small>
      </h4>
    </div>

    <DaysWrapper
      :dates="dates"
      :selected="selectedDate"
      @selected="selectDate"
      @loadMoreDays="loadMoreDays"
    />
    <hr class="small" />
    <transition name="fade-slow">
      <div v-if="loadedItems">
        <div v-if="todos.length > 0" id="todoSection" class="content">
          <div v-for="(item, $index) in todos" :key="$index" class="singleItem">
            <TodoSingleTask
              :item="item"
              @toggleTask="toggleTask($index)"
              @selectItem="selectItem"
            />
          </div>
        </div>
        <div class="buttonDiv singleItem">
          <b-button size="is-dotdot" class="is-size-6" @click="showTaskModal"
            >+ Add Task</b-button
          >
        </div>
        <div
          v-if="planner.length > 0"
          id="todoSection"
          class="content"
          style="margin-top: 30px"
        >
          <div
            v-for="(item, $index) in planner"
            :key="$index"
            class="singleItem"
          >
            <TodoSingleEvent :item="item" @selectItem="selectItem" />
          </div>
        </div>
        <div class="buttonDiv singleItem">
          <b-button size="is-dotdot" class="is-size-6" @click="showTaskModal"
            >+ Add Event</b-button
          >
        </div>
      </div>
    </transition>
    <b-modal
      id="Modal"
      animation="fade"
      :active.sync="isModalActive"
      :width="540"
      full-screen
      scroll="keep"
    >
      <TodoEventModal
        v-if="isModalActive"
        :item="taskData"
        :categories="categories"
        :tags="tags"
        @close="
          isModalActive = false
          refresh()
        "
      />
    </b-modal>
    <van-popup
      v-model="showActionsPopup"
      closeable
      :overlay="true"
      close-icon="f fas fa-times"
      position="bottom"
      get-container="body"
      :style="{ height: '110px', padding: '10px', 'text-align': 'center' }"
    >
      <circular-button
        title="Edit"
        icon="fas fa-pen"
        background="#f9f9f9"
        color="#FFA500"
        @click.native="menu_action('edit')"
      />
      <circular-button
        title="Delete this entry"
        icon="fas fa-times"
        background="#f9f9f9"
        color="#f77d4d"
        @click.native="menu_action('delete')"
      />
    </van-popup>
  </div>
</template>

<script>
// import anime from 'animejs'
import ApiService from '@/services/ApiService'
import DateHelper from '@/services/dateHelper'
import DaysWrapper from '@/components/habits/DaysWrapper'
import TodoSingleEvent from '@/components/todo/TodoSingleEvent'
import TodoSingleTask from '@/components/todo/TodoSingleTask'
import TodoEventModal from '@/components/modals/TodoEventModal'
import CircularButton from '@/components/CircularButton'
export default {
  name: 'Todo',
  components: {
    DaysWrapper,
    TodoSingleTask,
    TodoSingleEvent,
    TodoEventModal,
    CircularButton,
  },
  data() {
    return {
      taskData: {},
      selectedDate: '',
      selectedDateText: '',
      dates: [],
      items: [],
      loadedItems: false,
      showActionsPopup: false,
      categories: [
        {
          id: 1,
          text: 'Home',
        },
        {
          id: 2,
          text: 'Work',
        },
      ],
      tags: [
        {
          id: 5,
          text: 'Tag1',
        },
        {
          id: 21,
          text: 'Tag 3',
        },
        {
          id: 33,
          text: 'Tag 12',
        },
      ],
      todos: [
        // {
        //   title: 'Task here',
        //   is_task: 1,
        //   comments: [],
        // },
        // {
        //   title: 'New Task here',
        //   is_task: 1,
        //   due_time: 'Feb 5',
        //   category: 'asotis',
        //   tag: 'banana',
        //   description:
        //     'this is a description here and hsdf asdfhsd asdf hsd fs sdf',
        //   comments: [],
        // },
      ],
      planner: [
        // {
        //   title: 'Event here',
        //   is_task: 0,
        //   comments: [],
        // },
        // {
        //   title: 'Event max here',
        //   is_task: 0,
        //   category: 'work',
        //   tag: 'banana',
        //   description: 'this is a desc',
        //   comments: [{ c: 'This is das it' }],
        // },
      ],
      activeTab: 0,
      isModalActive: false,
      currentWeekDay: -1,
    }
  },
  computed: {
    allHabbits() {
      return this.positiveHabits.concat(this.negativeHabits)
    },
  },
  created() {
    this.addDays()
  },
  mounted() {
    const d = new Date()
    this.currentWeekDay = d.getDay()
    setTimeout(() => {
      this.items = this.items2
    }, 500)
    this.getTodoPlannerDay(this.selectedDate)
  },
  methods: {
    confirmDeletetion() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove it?',
        onConfirm: () => this.$buefy.toast.open('Deleted'),
      })
    },
    addDays() {
      const dateObj = DateHelper.initialDates(14, 14)
      this.dates = dateObj.dates
      this.selectedDate = dateObj.selected
      this.selectedDateText = dateObj.selectedText
      this.getFilledDates(
        this.dates[0].fullDate,
        this.dates[this.dates.length - 1].fullDate
      )
    },
    loadMoreDays(date) {
      const newDates = DateHelper.addDates(date, [...this.dates])
      this.dates = newDates
      this.getFilledDates(newDates[0].fullDate, date)
    },
    toggleTask(index) {
      let current = this.todos[index]
      current.completed = current.completed == true ? false : true
      this.$set(this.todos, index, { ...current })
      console.log('all items', this.items)
      this.$forceUpdate()
    },
    showTaskModal(taskId) {
      this.isModalActive = true
      if (taskId) {
        this.taskData = {}
      } else {
        this.taskData = {}
      }
    },
    getDayNames(year, month) {
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      const daysInMonth = new Date(year, month, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        let d = new Date(year, month, i)
        let dayName = days[d.getDay()]
        console.log(i + ' ' + dayName)
      }
    },
    selectDate(dateObj) {
      this.selectedDate = dateObj.date
      this.getTodoPlannerDay(dateObj.date)
      this.selectedDateText = DateHelper.convertDateToText(dateObj.date)
    },
    getTodoPlannerDay(date) {
      this.loadedItems = false
      ApiService.todo.getDay(date).then((resp) => {
        this.todos = resp.data.todos
        this.planner = resp.data.planner
        this.loadedItems = true
      })
    },
    toggleDailyCheck(habbitId, day) {
      if (this.habitsChecked[habbitId] && this.habitsChecked[habbitId][day]) {
        this.habitsChecked[habbitId][day] = false
      } else {
        this.habitsChecked[habbitId][day] = true
        console.log(this.habitsChecked)
      }
      this.$forceUpdate()
    },
    refresh() {
      this.getTodoPlannerDay(this.selectedDate)
    },
    menu_action(type) {
      if (type === 'edit') {
        this.hideTemplate(this.items[this.selectedItemIndex].template_id)
      } else if (type === 'delete') {
        this.$dialog
          .confirm({
            message: 'Would you like to delete this item?',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          })
          .then(() => {
            this.showActionsPopup = false
            ApiService.todo.removeEntry(this.selection.id).then(() => {
              this.$notify({
                message: 'Item removed.',
                type: 'success',
              })
              this.getTodoPlannerDay(this.selectedDate)
            })
          })
      }
    },
    getFilledDates(beginningDate, endDate) {
      ApiService.todo.getFilledDates(beginningDate, endDate).then((resp) => {
        const filledDates = resp.data
        if (filledDates) {
          let newDates = JSON.parse(JSON.stringify(this.dates))
          newDates.map((itm) => {
            if (filledDates.indexOf(itm.fullDate) > -1) {
              itm.status = 3
            }
          })
          this.dates = newDates
          console.log(newDates)
        }
      })
    },
    selectItem(selection) {
      this.selection = selection
      this.showActionsPopup = true
    },
  },
}
</script>
<style scoped>
.singleItem {
  /* opacity: 0; */
  overflow: hidden;
}
div.buttonDiv {
  padding-top: 2px;
  clear: both;
  height: 40px !important;
}
div.buttonDiv button {
  display: block;
  margin: auto;
}
#todoSection {
  max-width: 800px;
  margin: auto;
}
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
small.is-size-9 {
  font-size: 9px;
  color: rgb(220, 220, 220);
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
figure.image {
  margin-right: 15px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}
div.dailyDiv {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
div.dailyDiv .column span.active {
  font-weight: bold;
  color: #167df0;
}
.dailyDiv .columns .column {
  border-right: 1px solid #f2f2f2;
}
div.dailyRow {
  border-bottom: 1px solid #f2f2f2;
}
.tabs li.is-active span {
  font-weight: bold;
}
.positiveHeader,
.negativeHeader {
  color: red;
  font-weight: 500;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
  margin-bottom: 10px;
  margin-bottom: 0px;
}
.positiveHeader {
  color: green;
  border-right: 1px solid #f2f2f2;
  margin-bottom: 0px;
}
.habitsWrapper > div:first-child {
  border-top: 0;
}
#positiveBlock {
  border-right: 1px solid #f2f2f2;
}
.habbitsBlock {
  position: relative;
  border-top: 1px solid #f2f2f2;
  padding: 10px;
}
.habbitsBlock button {
  display: none;
  color: red;
  font-weight: bold;
  font-size: 15px;
  background: none;
  border: none;
}
.habbitsBlock:hover button {
  display: inline;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.habbitsBlock.active button {
  display: inline;
  position: absolute;
  right: 0;
}
.container {
  padding: 0 10px;
}
.fa-check {
  color: green;
}
@media only screen and (max-width: 500px) {
  .dailyDiv {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dailyDiv .columns .column {
    padding: 15px 0px;
  }
}
.habitsWrapper .habbitsBlock {
  padding: 5px;
}
#displayTime span {
  min-width: 50px;
  text-align: center;
  font-size: 1rem;
}
#displayTime i {
  line-height: 1.9rem;
  font-size: 1rem;
  padding: 0 5px;
}
</style>