<template>
  <div class="feed container">
    <div style="padding: 5px">
      <div class="is-clearfix" @click="randomQuote">
        <span class="quote is-pulled-right">{{ quote.text }}</span>
      </div>
      <div class="is-clearfix">
        <span class="quoteAuthor is-pulled-right">- {{ quote.author }}</span>
      </div>
    </div>
    <section class="page">
      <!-- <HomepageDynamicBlocks /> -->
      <div>
        <div class="hero">
          <h4 class="title is-4">
            Latest <small class="headerSmall">Other Content</small>
          </h4>
        </div>
        <!-- <div class="column">top</div> -->
        <div class="columns is-centered is-multiline contentBlocks">
          <Tile
            v-for="(item, index) in items"
            :key="item.id"
            :item="item"
            :index="index"
            class="column is-one-third"
            @click.native="$parent.openModal(item)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tile from '@/components/tiles/Tile'
// import HomepageDynamicBlocks from '@/components/HomepageDynamicBlocks'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'

export default {
  name: 'Home',
  components: {
    Tile,
    // HomepageDynamicBlocks,
  },
  data() {
    return {
      items: [],
      quote: { text: '', author: '' },
      currentPage: 0,
      dropFiles: null,
      isOpen: false,
      show: false,
    }
  },
  created() {
    this.getQuote()
    this.getPosts()
  },
  mounted() {},
  methods: {
    getPosts() {
      this.items = this.$store.state.general.temp_feed_items
      ApiService.posts.getPosts(this.currentPage).then((response) => {
        let items = response.data.slice(0, 20)
        items = updatePosts(items)
        this.$store.dispatch('setTempFeed', items.slice(0, 10))
        this.items = items
      })
    },
    randomQuote() {
      const quotes = this.$store.state.general.quotes
      const indexQuote = Math.floor(Math.random() * quotes.length)
      console.log(quotes)
      this.quote = quotes[indexQuote]
    },
    async getQuote() {
      if (this.$store.state.general.quotes.length === 0) {
        const newQuotes = await ApiService.quotes.getQuotes()
        this.$store.dispatch('addQuotes', newQuotes.data)
      }

      // setTimeout(() => {
      this.randomQuote()
      // }, 200)
    },
    goto(route) {
      this.$router.push({ path: '/members/' + route })
    },
  },
}
</script>
<style scoped>
.fas.fa-plus {
  margin-top: 0px;
  margin-right: 0px;
}
.inlineBlock {
  display: inline-block;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.circButton {
  width: 35px;
  height: 35px;
  padding: 0px;
  box-shadow: 0px 0px 3px #dcdcdc;
  border-width: 0px;
  border-radius: 17px !important;
}
.circButton i {
  font-size: 12px;
  color: #ffe1c0;
}
</style>