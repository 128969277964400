<template>
  <div class="modal-content">
    <div class="card">
      <h1 class="title is-size-5" style="padding-left: 10px">{{ title }}</h1>
      <div class="section" style="padding: 0px 10px">
        <div id="topIcons">
          <i class="fas fa-users"> {{ users }}</i>
          <i class="fas fa-comments"> {{ messages }}</i>
        </div>
        <div>
          <transition-group name="slide">
            <SingleTopicMessage
              v-for="item in items.items"
              :key="item.id"
              :item="item"
            />
          </transition-group>
        </div>
        <div>
          <hr>
          <b-input maxlength="500" type="textarea" v-model="newMessage" placeholder="My reply here"></b-input>
          <b-button
            type="is-primary is-light"
            @click="save"
            :disabled="!newMessage"
            ><i class="far fa-check"></i> Send Reply</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import SingleTopicMessage from '@/components/SingleTopicMessage'
export default {
  name: 'ForumTopicModal',
  components: {
    SingleTopicMessage,
  },
  props: {
    id: Number,
    title: String,
    users: Number,
    messages: Number,
  },
  data() {
    return {
      items: [],
      newMessage: '',
    }
  },
  mounted() {
    console.log('PROPS', this.title)
    this.loadForumTopic()
  },
  methods: {
    loadForumTopic() {
      ApiService.forum.getForumTopic(this.id).then((resp) => {
        this.items = resp.data
      })
    },
    save(){
      const obj = {
        topicId: this.id,
        message: this.newMessage
      }
      ApiService.forum.saveReply(obj).then(() => {
        this.newMessage = '';
        this.loadForumTopic();
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
h1 {
  padding-top: 10px;
  padding-left: 5px;
  margin-bottom: 1.5rem;
}
div.modal-content {
  margin: 0;
}
#topIcons {
  padding: 5px;
  i {
    color: #ffbf4a;
    padding: 5px;
  }
}
</style>
