const next = (items, currentSelection) => {
    const lastExerciseIndex = items.length - 1;

    const currentExercise = items[currentSelection.exerciseIndex];

    if (currentExercise.multi) {
        const currentSupersetExercise = currentExercise.items[currentSelection.supersetIndex];
        const lastSupersetIndex = currentExercise.items.length - 1;
        const lastSetIndex = currentSupersetExercise.sets.length - 1;

        // last set and last supersetIndex
        if (currentSelection.supersetIndex == lastSupersetIndex && currentSelection.setIndex === lastSetIndex) {
            if (lastExerciseIndex == currentSelection.exerciseIndex) {
                return {
                    finish: true
                }
            } else {
                let nextExercise = items[currentSelection.exerciseIndex + 1];
                if (nextExercise.multi) {
                    return {
                        exerciseIndex: currentSelection.exerciseIndex + 1,
                        supersetIndex: 0,
                        setIndex: 0,
                        newSet: true,
                        rest: true
                    }
                } else {
                    return {
                        exerciseIndex: currentSelection.exerciseIndex + 1,
                        supersetIndex: -1,
                        setIndex: 0,
                        newSet: true,
                        rest: true
                    }
                }
            }
            // last exercise completed
        } else if (currentSelection.supersetIndex == lastSupersetIndex && currentSelection.setIndex < lastSetIndex) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: 0,
                setIndex: currentSelection.setIndex + 1,
                newSet: true,
                rest: true
            }
        } else if (currentSelection.supersetIndex < lastSupersetIndex) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: currentSelection.supersetIndex + 1,
                setIndex: currentSelection.setIndex,
            }
        }
        // Simple exercise
    } else {
        const lastSetIndex = currentExercise.sets.length - 1;
        if (currentSelection.exerciseIndex === lastExerciseIndex && currentSelection.setIndex === lastSetIndex) {
            return {
                finish: true
            }
        } else if (currentSelection.setIndex < lastSetIndex) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: -1,
                setIndex: currentSelection.setIndex + 1,
                newSet: true,
                rest: true
            }
        } else if (currentSelection.setIndex === lastSetIndex) {
            let nextExercise = items[currentSelection.exerciseIndex + 1];
            if (nextExercise.multi) {
                return {
                    exerciseIndex: currentSelection.exerciseIndex + 1,
                    supersetIndex: 0,
                    setIndex: 0,
                    newSet: true,
                    rest: true
                }
            } else {
                return {
                    exerciseIndex: currentSelection.exerciseIndex + 1,
                    setIndex: 0,
                    supersetIndex: -1,
                    newSet: true,
                    rest: true
                }
            }
        }
    }
}

const prev = (items, currentSelection) => {
    // const lastExerciseIndex = items.length - 1;

    const currentExercise = items[currentSelection.exerciseIndex];

    if (currentSelection.exerciseIndex === 0 && currentSelection.supersetIndex === 0 && currentSelection.setIndex === 0) {
        return {
            start: true
        }
    }

    if (currentExercise.multi) {
        // const currentSupersetExercise = currentExercise.items[currentSelection.supersetIndex];
        const lastSupersetIndex = currentExercise.items.length - 1;

        // last set and last supersetIndex
        if (currentSelection.setIndex > 0 && currentSelection.supersetIndex === 0) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: lastSupersetIndex,
                setIndex: currentSelection.setIndex - 1,
            }
        } else if (currentSelection.setIndex > 0 && currentSelection.supersetIndex !== 0) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: currentSelection.supersetIndex - 1,
                setIndex: currentSelection.setIndex,
            }
        } else if (currentSelection.setIndex === 0 && currentSelection.supersetIndex === 0) {
            let prevExercise = items[currentSelection.exerciseIndex - 1];

            if (prevExercise.multi) {
                const lastSetMultiIndex = prevExercise.items[0].sets.length - 1;
                const lastSupersetMultiIndex = prevExercise.items.length - 1;
                return {
                    exerciseIndex: currentSelection.exerciseIndex - 1,
                    supersetIndex: lastSupersetMultiIndex,
                    setIndex: lastSetMultiIndex,
                }

            } else {
                const lastSetIndex = prevExercise.sets.length - 1;
                return {
                    exerciseIndex: currentSelection.exerciseIndex - 1,
                    supersetIndex: -1,
                    setIndex: lastSetIndex,
                }
            }
        } else if (currentSelection.supersetIndex > 0) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: currentSelection.supersetIndex - 1,
                setIndex: currentSelection.setIndex,
            }
        }


        // Single exercise
    } else {
        if (currentSelection.setIndex === 0 && currentSelection.exerciseIndex > 0) {
            let prevExercise = items[currentSelection.exerciseIndex - 1];
            if (prevExercise.multi) {
                const lastSetMultiIndex = prevExercise.items[0].sets.length - 1;
                const lastSupersetMultiIndex = prevExercise.items.length - 1;
                return {
                    exerciseIndex: currentSelection.exerciseIndex - 1,
                    supersetIndex: lastSupersetMultiIndex,
                    setIndex: lastSetMultiIndex,
                }

            } else {
                const lastSetIndex = prevExercise.sets.length - 1;
                return {
                    exerciseIndex: currentSelection.exerciseIndex - 1,
                    supersetIndex: -1,
                    setIndex: lastSetIndex,
                }
            }
        } else if (currentSelection.setIndex > 0) {
            return {
                exerciseIndex: currentSelection.exerciseIndex,
                supersetIndex: - 1,
                setIndex: currentSelection.setIndex - 1,
            }
        }
    }
}

export { next, prev }
