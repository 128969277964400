<template>
  <div class="login container" style="background: #ffffff">
    <video id="myVideo" autoplay muted loop>
      <source src="../assets/preview.mp4" type="video/mp4" />
    </video>
    <div class="is-centered" style="position: relative">
      <img src="../assets/logo_old.png" style="height: 150px" alt />
    </div>
    <div class="hero" style="margin-top: 50px">
      <!-- <h1 class="subtitle">Login page</h1> -->
    </div>
    <section class="section" style="position: relative">
      <div class="columns is-centered">
        <!-- <div class="column">top</div> -->
        <div id="loginform" class="is-half column" style="">
          <!-- <b-field label="Name">
            <b-input v-model="name"></b-input>
          </b-field>-->
          <label class="label">Email</label>
          <van-cell-group>
            <van-field
              v-model="email"
              label=""
              type="text"
              placeholder="Email"
              maxlength="40"
            />
          </van-cell-group>
          <label class="label">Password</label>
          <van-cell-group>
            <van-field
              v-model="password"
              label=""
              type="password"
              placeholder="Password"
              maxlength="40"
            />
          </van-cell-group>
          <van-cell-group>
            <b-button
              type="is-primary noIconMarginTop"
              icon-left="f far fa-sign-in-alt"
              :loading="isLoading"
              :disabled="!password || !email"
              style="margin-top: 5px"
              @click="login"
            >Login</b-button>
          </van-cell-group>
        </div>
        <!-- <div class="column">test</div> -->
      </div>
    </section>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
export default {
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
    }
  },
  methods: {
    login() {
      this.isLoading = true
      ApiService.authentication
        .login({ email: this.email, password: this.password })
        .then((resp) => {
          this.$store.dispatch('setToken', resp.data.token)
          this.$router.push('/members/main')
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          if (err.status == 422) {
            this.$toast({ message: 'Invalid credentials', position: 'bottom' })
          } else {
            alert(err)
          }
        })
    },
  },
  created(){
    if(this.$store.state.general.token){
      this.$router.push('/members/main')
    }
  }
}
</script>
<style lang="scss">
#loginform {
  // background-color: rgba($color: #ffffff, $alpha: 0.3);
  border-radius: 6px;
  // box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  //   0 0 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  // display: block;
  padding: 1.25rem;
  .van-cell {
    padding: 0px !important;
    .van-field__control {
      padding: 7px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
#myVideo {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0;
  // opacity: 0.8;
  bottom: 0px;
  min-height: 100%;
  min-width: 115%;
  z-index: 0;
  object-fit: cover;
}
// @media (min-aspect-ratio: 3/2) {
//   #myVideo {
//     min-width: 145%;
//   }
// }
// @media (min-aspect-ratio: 5/2) {
//   #myVideo {
//     min-width: 165%;
//   }
// }
</style>
