<template>
  <div class="wrap">
    <b-button
      type="is-default"
      v-for="index in totalDays"
      :key="index"
      @click="toggle(index)"
      class="btn"
      :class="{ active: selectedDays.indexOf(index) > -1, notAvailable: allUsedDays.indexOf(index) > -1  }"
      >{{ index }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'ChallengeDaysToggle',
  props: {
    totalDays: Number,
    allUsedDays: Array,
    selectedDays: Array,
  },
  data() {
    return {
      //   type: this.initial,
    }
  },
  methods: {
    toggle(day) {
      let newSelectedDays = []
      let newAllUsedDays = []
      if (this.selectedDays.indexOf(day) > -1) {
        let index = this.selectedDays.indexOf(day)
        let allUsedIndex = this.allUsedDays.indexOf(day)
        newSelectedDays = [...this.selectedDays]
        newAllUsedDays = [...this.allUsedDays]
        newSelectedDays.splice(index, 1)
        newAllUsedDays.splice(allUsedIndex, 1)
      } else if (this.allUsedDays.indexOf(day) > -1) {
        return true
      } else {
        newSelectedDays = [...this.selectedDays, day]
        newAllUsedDays = [...this.allUsedDays, day]
      }

      this.$emit('updated', {
        selectedDays: newSelectedDays,
        allUsedDays: newAllUsedDays,
      })
    },
  },
}
</script>
<style scoped lang="scss">
button.btn {
  width: 35px !important;
  height: 35px;
  border: 1px solid #ccc !important;
  border-radius: 18px !important;
  margin: 1px;
  font-size: 14px;
  transition: 0.3s all;
  &.active {
    background: #f6b875;
    color: white;
    opacity: 1 !important;
    cursor: pointer !important;
  }
  &.notAvailable {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
