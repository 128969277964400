<template>
  <div id="app">
    <div class="hero">
      <h4 class="title is-4">Friends</h4>
    </div>
    <swipe-list
      ref="list"
      class="card"
      :disabled="!enabled"
      :items="friends[page]"
      item-key="id"
      :revealed.sync="revealed"
      :item-disabled="({ disabled }) => disabled"
      @closed="setLastEvent('closed', $event)"
      @leftRevealed="setLastEvent('leftRevealed', $event)"
      @rightRevealed="setLastEvent('rightRevealed', $event)"
    >
      <template v-slot="{ item }">
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <div ref="content" class="card-content" @click="itemClick(item)">
          <figure class="image is-64x64 is-pulled-left">
            <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
          </figure>
          <!-- style content how ever you like -->
          <h2 class="has-text-left is-size-5">
            {{ item.name }}
          </h2>
          <!-- <p>
            <b>id:</b>
            {{ item.id }}
            <b>description:</b>
            {{ item.description }}
            <b>revealed:</b>
            {{ revealed || 'flase' }}
          </p>-->
          <p class="has-text-left">
            <small>Message:</small> <small class="is-size-85">What is your latest</small>
          </p>
          <!-- <b>index:</b>
          <span>{{ index }}</span>-->
          <!-- <input :id="`${index}disabled`" v-model="item.disabled" type="checkbox" />
          <label :for="`${index}disabled`">Disabled</label>-->
        </div>
      </template>
      <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
      <!-- remove if you dont wanna have left swipe side  -->
      <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
      <!-- remove if you dont wanna have right swipe side  -->
      <template v-slot:right="{ item }">
        <div class="swipeout-action blue">
          <!-- place icon here or what ever you want -->
          <i class="fas fa-trash has-text-danger"></i>
        </div>
        <div class="swipeout-action green">
          {{ item.id }}
          <!-- place icon here or what ever you want -->
          <i class="fa fa-star has-text-warning"></i>
        </div>
      </template>
      <template v-slot:empty>
        <div>
          <!-- change friends to an empty array to see this slot in action  -->
          list is empty ( filtered or just empty )
        </div>
      </template>
    </swipe-list>
    <div class="toolbar" style="display: none;">
      <div class="toolbar-section">
        <p>
          <button @click="revealFirstLeft">reveal 1st left</button>
          <button @click="revealFirstRight">reveal 1st right</button>
          <button @click="closeFirst">close 1st</button>
          <button @click="closeAll">close all</button>
          <button @click="page = Math.max(page - 1, 0)">prev</button>
          <button @click="page = Math.min(page + 1, 1)">next</button>
        </p>
      </div>
      <div class="toolbar-section--center" />
      <div class="toolbar-section">
        <small>revealed: {{ Object.entries(revealed).map(([index, side]) => `${index}: ${side}`).join(', ') }}</small>
      </div>
      <div class="toolbar-section--center" />
      <div class="toolbar-section">
        <small>
          last event:
          <template v-if="lastEventDescription">
            [
            <b>{{ lastEventDescription.name }}</b>
            ] index: {{ lastEventDescription.index }} id: {{ lastEventDescription.id }}
          </template>
          <span v-else>none</span>
        </small>
      </div>
    </div>
    <p style="display: none;">
      <small>
        <i>Press and hold [shift] to select text</i>
      </small>
    </p>
    <b-modal
      id="menuModal"
      animation="fade"
      :active.sync="isCardModalActive"
      :width="340"
      scroll="keep"
    >
      <div class="card">
        <div class>
          <div class="header">
            <h2 class="subtitle">Menu</h2>
          </div>
          <hr />
          <div class="content">
            <router-link to="/members/forum">
              <b-button class="fullwidth" type="is-default" expanded>Forum</b-button>
            </router-link>
            <router-link to="/members/forum">
              <b-button class="fullwidth" type="is-default" expanded>My Messages</b-button>
            </router-link>
            <router-link to="/members/habits">
              <b-button class="fullwidth" type="is-default" expanded>Habits</b-button>
            </router-link>
            <router-link to="/members/friends">
              <b-button class="fullwidth" type="is-default" expanded>Friends</b-button>
            </router-link>
            <router-link to="/members/bookmarks">
              <b-button class="fullwidth" type="is-default" expanded>Bookmarks</b-button>
            </router-link>
            <router-link to="/members/bookmarks">
              <b-button class="fullwidth" type="is-default" expanded>Liked Items</b-button>
            </router-link>
          </div>
        </div>

        <div class>
          <hr />
          <router-link to="/members/my_profile">
            <b-button class="fullwidth" type="is-default" expanded>My Profile</b-button>
          </router-link>
          <router-link to="/members/about_us">
            <b-button class="fullwidth" type="is-default" expanded>About us</b-button>
          </router-link>
          <router-link to="/members/contact_us">
            <b-button class="fullwidth" type="is-default" expanded>Contact us</b-button>
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SwipeList } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

export default {
  name: 'App',
  components: {
    // SwipeOut,
    SwipeList
  },
  data() {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      isCardModalActive: false,
      lastEventDescription: '',
      friends: [
        [
          {
            id: 'a',
            name: 'Ronald mcdonald',
            description: 'some description',
            disabled: false
          },
          {
            id: 'b',
            name: 'Steve Jobsasd',
            description: 'some description',
            disabled: false
          },
        ]
      ]
    }
  },
  mounted() {
    // ideally should be in some global handler/store
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    dd(v) {
      console.log(v.target)
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0)
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0)
    },
    closeFirst() {
      this.$refs.list.closeActions(0)
    },
    closeAll() {
      this.$refs.list.closeActions()
    },
    remove(item) {
      this.$set(
        this.friends,
        this.page,
        this.friends[this.page].filter(i => i !== item)
      )
    },
    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id
      }
    },
    itemClick(e) {
      console.log(e, 'item click')
      // alert('yeeha')
    },
    fbClick(e) {
      console.log(e, 'First Button Click')
    },
    sbClick(e) {
      console.log(e, 'Second Button Click')
    },
    // keyboard
    onKeyDown(e) {
      if (e.keyCode !== 16) return
      this.enabled = false
    },
    onKeyUp(e) {
      if (e.keyCode !== 16) return
      this.enabled = true
    }
  }
}
</script>
<style scoped>
h2.from {
  border-bottom: 1px solid #f9f9f9;
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
figure.image{
  margin-right: 15px;
      border-right: 1px solid #eaeaea;
    padding-right: 10px;
}
small {
  color: grey;
  font-size: 0.7rem;
}
</style>
