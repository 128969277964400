<template>
  <div
    class="modal-content"
    style="width: 100%; overflow-x: hidden; min-height: 65vh"
  >
    <div class="columns">
      <div class="column">
        <div class="card" :class="{ active: showContent }">
          <h1
            class="title is-size-5"
            style="padding-left: 5px; padding-right: 25px"
          >
            {{ title }}
          </h1>
          <div v-if="youtubeId" id="youtubeWrapper">
            <youtube
              :video-id="youtubeId"
              player-height="300"
              :player-vars="{ autoplay: 1 }"
            ></youtube>
          </div>
          <div v-if="externalWebsite">
            <div class="notification">
              Opens external website
              <br />
              <a
                class="has-text-info"
                :href="externalWebsite"
                target="_blank"
                >{{ externalWebsite }}</a
              >
            </div>
          </div>
          <div class="section" style="padding-top: 5px">
            <div class="contents">
              <h4 v-if="youtubeId || externalWebsite" class="sectionTitle">
                Description
              </h4>
              <div style="font-size: 14px" v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card2">
          <div class="section" style="padding-top: 5px">
            <div class="comments">
              <h4 class="sectionTitle">L.E.C. <small>Comments</small></h4>
              <CommentsListing :comments="comments" />
              <div style="position: relative">
                <!-- <van-cell-group> -->

                <van-field
                  v-model="newComment"
                  type="textarea"
                  placeholder="Enter New comment here"
                  rows="1"
                  maxlength="400"
                  autosize
                />
                <!-- </van-cell-group> -->
              </div>
              <transition name="slide-in">
                <b-button
                  v-if="newComment"
                  class="is-primary is-light"
                  @click="addComment"
                >
                  <i class="fas fa-archive"></i> Save comment
                </b-button>
              </transition>
            </div>
            <hr />
            <div class="habbits">
              <h4 class="sectionTitle">
                Habits
                <small>mentioned in this post</small>
              </h4>
              <div class="singleHabit row" style="margin-bottom: 5px">
                <span v-if="habits.length === 0" class="has-text-grey"
                  >No Habits yet, help comunity and yourself by adding!</span
                >
                <button
                  v-for="habit in habits"
                  :key="habit.id"
                  class="noborder is-default"
                  @click="showHabit(habit.id)"
                >
                  <span
                    v-if="habit.icon"
                    class="has-text-success"
                    :class="habit.icon"
                  ></span>
                  <span class="habitText">{{ habit.text }}</span>
                  <i class="fas fa-angle-down"></i>
                </button>
              </div>
              <b-button
                v-if="!showHabitForm"
                type="is-primary is-light"
                style="margin-top: 15px"
                @click="actionShowHabitForm"
              >
                <i class="far fa-plus"></i> Add New habit
              </b-button>
              <transition name="fade">
                <div v-if="showHabitForm" class="activeForm">
                  <h4 class="sectionTitle" style="margin-top: 0px">
                    New Habit
                  </h4>

                  <van-field
                    v-model="newHabit.habit"
                    maxlength="50"
                    rows="1"
                    placeholder="Keep it short"
                    type="textarea"
                    style="max-width: 400px; margin-bottom: 10px"
                    autosize
                  ></van-field>
                  <div>
                    <span
                      class="has-text-grey"
                      style="vertical-align: bottom; line-height: 40px"
                      >Icon:</span
                    ><Iconpicker
                      v-if="showHabitForm"
                      :current-icon="newHabit.icon"
                      @selectIcon="selectIcon"
                      style="display: inline-block"
                    />
                  </div>

                  <b-button
                    class="is-primary is-light"
                    style="margin-top: 20px"
                    @click="saveNewHabit"
                    :disabled="!newHabit.habit"
                  >
                    <i class="fas fa-archive"></i> Save
                  </b-button>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showActionsPopup"
      closeable
      :overlay="true"
      close-icon="f fas fa-times"
      position="bottom"
      get-container="body"
      :style="{ height: '100px', padding: '10px', 'text-align': 'center' }"
    >
      <circular-button
        title="Add to My Habits"
        icon="far fa-grin"
        background="#FFA500"
        color="#f9f9f9"
        @click.native="action('add_to_habits')"
      />
      <circular-button
        style="margin-left: 100px"
        title="Report Habit"
        icon="fas fa-times"
        background="#f9f9f9"
        color="#ffadad"
        @click.native="action('report_habit')"
      />
    </van-popup>
    <b-modal
      id="Modal"
      class
      :active.sync="isHabitObjModalActive"
      :width="440"
      scroll="keep"
    >
      <HabitModal :habitObj="habitObj" />
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import CommentsListing from '@/components/CommentsListing'
import CircularButton from '@/components/CircularButton'
import HabitModal from '@/components/modals/HabitModal'
export default {
  name: 'PostModal',
  components: {
    CommentsListing,
    CircularButton,
    HabitModal,
    Iconpicker: () => import('@/components/IconPicker'),
  },
  props: {
    id: Number,
    title: String,
    content: String,
    options: Object,
  },
  data() {
    return {
      youtubeId: false,
      externalWebsite: false,
      habits: [],
      comments: [],
      newComment: '',
      showHabitForm: '',
      newHabit: {
        icon: 'fas fa-check',
      },
      showActionsPopup: false,
      showContent: false,
      isHabitObjModalActive: false,
      habitObj: {
        options: {
          color: '#222f3d',
          background: '#f9f9f9',
          icon: 'fas fa-check',
        },
        section: 'anytime',
        schedule: 'same',
        schedule_options: 'same',
      },
      // rawHtml: '<span>This is <b>fgd</b>a delfi</span>'
    }
  },
  computed: {
    user_id: function () {
      return 5
    },
  },
  mounted() {
    if (this.options && this.options.youtube_id) {
      this.youtubeId = this.options.youtube_id
    }
    if (this.options && this.options.website) {
      this.externalWebsite = this.options.website
    }
    this.loadPostExtraInfo()
    console.log(this.options)
    setTimeout(() => {
      this.showContent = true
    }, 10)
  },
  methods: {
    loadPostExtraInfo() {
      ApiService.posts.getPostExtraInfo(this.id).then((resp) => {
        this.habits = resp.data.habits
        this.comments = resp.data.comments
      })
    },
    addComment() {
      ApiService.comments.saveComment(this.id, this.newComment).then(() => {
        this.$notify({ type: 'success', message: 'Comment Saved' })
        this.newComment = ''
        this.loadPostExtraInfo()
      })
    },
    actionShowHabitForm() {
      this.showHabitForm = true
    },
    showHabit(id) {
      this.showActionsPopup = true
      this.selectedHabit = id
    },
    saveNewHabit() {
      ApiService.habits.savePostHabit(this.id, this.newHabit).then(() => {
        this.loadPostExtraInfo()
        this.showHabitForm = false
        this.newHabit = {
          icon: 'fas fa-check',
        }
        this.$notify({ type: 'success', message: 'Saved' })
      })
    },
    addHabitToYourList() {},
    commentEmoji(emoji) {
      this.newComment += emoji.data
    },
    action(type) {
      if (type === 'add_to_habits') {
        this.showActionsPopup = false
        const postHabit = this.habits.filter(
          (itm) => itm.id === this.selectedHabit
        )[0];
        this.habitObj.title = postHabit.text
        this.habitObj.options.icon = postHabit.icon
        setTimeout(() => {
          this.isHabitObjModalActive = true
        }, 200)
      } else {
        alert(type)
      }
    },
    selectIcon(icon) {
      this.newHabit.icon = icon
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.animation-content {
  width: 100% !important;
}
div.modal-content {
  margin: 0;
}
div.content {
  padding: 5px;
}
div.card {
  opacity: 0.5;
  padding-top: 15px;
  position: sticky;
  top: 0;
  /* transition: all 0.3s; */
  &.active {
    opacity: 1;
    padding-top: 0px;
  }
}
h1 {
  padding-top: 10px;
  padding-left: 5px;
}
#youtubeWrapper {
  min-height: 300px;
  z-index: 9999;
}
@media screen and (max-width: 668px) {
  h1.title {
    min-height: 40px;
  }
}
.habitText {
  padding: 3px;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 13px;
  color: #3e3e3e;
}
button.noborder {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 7px;
  span.fa-circle {
    font-size: 10px;
  }
  i {
    color: #bbb;
  }
  &:active,
  &:focus {
    background: #fcba40;
  }
}
div.activeForm {
  margin-top: 10px;
}
div.comments {
  text-align: left;
}
h4.sectionTitle {
  text-align: left;
  small {
    font-weight: 200;
    color: #363636;
  }
}
</style>
