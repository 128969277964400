<template>
  <div id="limitedMainDiv">
    <b-tabs
      id="nopaddingtab"
      v-model="activeTab"
      position="is-centered"
      animation="fade"
      @input="changeUrl"
    >
      <b-tab-item icon="f far fa-users" label="Forum"><Forum /></b-tab-item>
      <b-tab-item icon="f far fa-mountains" label="Challenges">
        <Challenges
      /></b-tab-item>
      <b-tab-item icon="f fal fa-users-class" label="Groups">
        <span style="font-weight: 200">Coming soon</span>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
// import ApiService from '@/services/ApiService'
import Forum from './Forum'
import Challenges from './Challenges'
export default {
  name: 'Together',
  components: {
    Forum,
    Challenges,
  },
  data() {
    return {
      activeTab: 0,
      numberToRoute: {
        0: 'forum',
        1: 'challenges',
        2: 'groups',
      },
    }
  },
  mounted() {
    const section = this.$route.params.section

    if (section == 'challenges') {
      this.activeTab = 1
    } else if (section == 'groups') {
      this.activeTab = 2
    }
  },
  methods: {
    changeUrl(type) {
      this.$router.push('/members/main/together/' + this.numberToRoute[type])
    },
  },
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
#limitedMainDiv #nopaddingtab .tab-content {
  padding: 0px;
}
#limitedMainDiv .titleTabs {
  font-size: 18px;
  li a {
    color: #9a9a9a;
  }
}
</style>
