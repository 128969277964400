import { render, staticRenderFns } from "./HabitsSection.vue?vue&type=template&id=2670b0f9&scoped=true&"
import script from "./HabitsSection.vue?vue&type=script&lang=js&"
export * from "./HabitsSection.vue?vue&type=script&lang=js&"
import style0 from "./HabitsSection.vue?vue&type=style&index=0&id=2670b0f9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2670b0f9",
  null
  
)

export default component.exports