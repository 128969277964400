<template>
  <div class="feed container" style="background: #ffffff">
    <div class="hero">
      <h4 class="title is-4">
        My Messages <small class="headerSmall"></small>
      </h4>
    </div>
    <section class="">
      <div class="columns">
        <div class="column is-one-third">
          <div
            class="thread"
            :key="index"
            :class="{ active: selectedIndex == index }"
            v-for="(item, index) in messages"
            @click="selectThread(index)"
          >
            <h4 class="title is-size-6">{{ item.title }}</h4>
            <!-- <strong class="is-size-7">Admin</strong> -->
            <p class="is-size-7 has-text-grey">
              {{ item.shortInfo }}
            </p>
            <div></div>
          </div>
        </div>
        <div class="column">
          <div
            id="threadMessage"
            v-if="selectedIndex != -1"
            v-html="messages[selectedIndex].content"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'messages',
  components: {},
  data() {
    return {
      selectedIndex: 0,
      messages: [
        {
          title: 'Welcome to L.E.C.!',
          shortInfo:
            'We are very glad you\'ve joined "London Elegance Club... ',
          content: `
            <div class="hero">
              <h2 class="title is-size-5">Welcome to London Elegance Club</h2>
            </div>
            <p>We are very glad you've joined <strong>London Elegance Club</strong> 🎉.<br> For a very long time we wanted to create a project without subscription, ads, hidden fees, 
            a project where you can relax, set your own rules, learn, grow(if you want), find a better balance and be a part of a friendly community. 
            We've worked in an industry for a long time and most of the time it's just about money, not about value, people. 
            <br><br>With an overload of information and constant distraction there had to be a better way to learn, relax, discuss and grow. 
            We built it for ourselves first, we hope you'll enjoy it too :) We'll continue to update based on community feedback, to make it even better!</p>
          `,
        },
      ],
    }
  },
  mounted() {},
  methods: {
    selectThread(index) {
      this.selectedIndex = index
      this.$forceUpdate()
    },
  },
}
</script>
<style lang="scss" scoped>
div.thread {
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-bottom: 0px;
  text-align: left;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
  h4 {
    margin-bottom: 2px;
    color: #616161;
    font-weight: 500;
  }
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &.active {
    opacity: 1;
    background: rgb(255, 245, 229);
    background: linear-gradient(
      180deg,
      rgba(255, 245, 229, 1) 0%,
      rgba(255, 237, 208, 1) 61%
    );
  }
}
#threadMessage {
  border: 1px solid #e6e6e6;
  min-height: 60vh;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #f1f1f1;
  padding: 0 15px;
  padding-bottom: 30px;
  text-align: left;
}
ul {
  list-style: disc;
  list-style-type: circle;
}
</style>
