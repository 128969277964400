<template>
  <div class="circularButton">
    <div class="inside" :style="{ background: background }">
      <i :class="icon" :style="{ color: color }"></i>
    </div>
    <div class="label">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'Circularbutton',
  props: {
    icon: String,
    title: String,
    color: String,
    background: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.circularButton {
  display: inline-block;
  padding-top: 10px;
  max-width: 100px;
  min-width: 60px;
  margin-left: 10px;
  text-align: center;
  cursor: pointer;
  vertical-align: top;

  .inside {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 0px #ccc;
    // transition: all 0.2s;
    &:active {
      box-shadow: 0px 0px 3px #949494;
    }
  }
  i {
    font-size: 28px;
    line-height: 49px;
  }
  .label {
    font-size: 10px;
    // width: 60px;
    text-align: center;
  }
}
</style>
