import dbConnection from '../dbConnection'
const forum = {
  getForumTopics: function() {
    return dbConnection.get('members/forum/')
  },
  getForumTopic: function(id) {
    return dbConnection.get('members/forum/single/' + id)
  },
  saveNewTopic: function(obj) {
    return dbConnection.post('members/forum/save', obj)
  },
  saveReply: function(obj){
    return dbConnection.post('members/forum/saveMessage', obj);
  }
}
export default forum