import { render, staticRenderFns } from "./JournalNumber.vue?vue&type=template&id=3bdf825c&scoped=true&"
import script from "./JournalNumber.vue?vue&type=script&lang=js&"
export * from "./JournalNumber.vue?vue&type=script&lang=js&"
import style0 from "./JournalNumber.vue?vue&type=style&index=0&id=3bdf825c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bdf825c",
  null
  
)

export default component.exports