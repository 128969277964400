<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4">
        <b-button
          type="is-text"
          class="is-pulled-right newButton"
          size="is-small"
          @click="openNewTopicModal"
        >
          <i class="far fa-plus"></i> <b>New</b> Topic
        </b-button>
        Forum
        <van-popover
          v-model="showPicker"
          trigger="click"
          :actions="forum_sections"
          @select="onConfirm"
        >
          <template #reference>
            <small class="headerSmall">
              {{ selection || 'All' }}
              <i class="fas fa-caret-down"></i>
            </small>
          </template>
        </van-popover>
      </h4>
    </div>
    <div class="bgWrapper">
      <!-- <van-loading v-if="loading" class="loadingAbsolute" color="#1989fa" /> -->
      <van-swipe-cell
        v-for="item in forumItems.filter(
          (itm) => itm.section === selection.toLowerCase() || selection == 'All'
        )"
        :key="item.id"
        :stop-propagation="true"
      >
        <div
          class="forumContentBlock"
          :style="{ 'border-left': '4px solid ' + item.color }"
          @click="itemClick(item)"
        >
          <h2 class="has-text-left entryTitle is-size-6">{{ item.title }}</h2>
          <p class="has-text-left bottomIcons">
            <span>
              <i class="fas fa-user fa-lg icon is-small"></i>
              {{ item.users }}
            </span>
            <span>
              <i class="fas fa-comments fa-lg icon is-small"></i>
              {{ item.messages }}
            </span>
            <span class="timeago is-pulled-right">
              <timeago :datetime="item.updated_at"></timeago>&nbsp;by
              <b>{{ item.updated_by }}</b>
            </span>
          </p>
        </div>
        <!-- <van-cell :border="false" title="Cell" value="Cell Content" /> -->
        <template #right>
          <b-button class="pinButton"
            ><i class="fas fa-thumbtack"></i
          ></b-button>
        </template>
      </van-swipe-cell>
    </div>
    <!-- <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :visible-item-count="10"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        :columns="sections"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup> -->
    <b-modal
      id="Modal"
      class
      animation="fade"
      :active.sync="isNewTopicModalActive"
      :width="440"
      scroll="keep"
    >
      <div
        class="card is-clearfix modalInside modalBg"
        style="padding-top: 1px; padding-bottom: 20px"
      >
        <div class>
          <div class="hero" style="padding-top: 1px">
            <h4 class="title is-4">
              New <small class="headerSmall">Topic</small>
            </h4>
          </div>
          <hr />
          <div class="container is-clearfix">
            <b-field label="Topic" style="display: block">
              <b-input
                v-model="newTopic.title"
                placeholder="Enter new Topic"
                required
                maxlength="80"
                expanded
                class="inputWithIcon"
              ></b-input>
            </b-field>
            <label class="label">Section</label>
            <van-dropdown-menu class="height42dropdown">
              <van-dropdown-item
                v-model="newTopic.section"
                :options="forum_sections"
              />
            </van-dropdown-menu>
            <b-field label="Message" style="display: block; position: relative">
              <b-input
                v-model="newTopic.message"
                type="textarea"
                minlength="10"
                maxlength="800"
                placeholder="Message"
              ></b-input>
            </b-field>
            <label class="label">Visibility</label>
            <div style="text-align: left">
              <van-switch
                v-model="newTopic.public"
                active-color="#07c160"
                inactive-color="#ccc"
                :active-value="1"
                type="is-info"
                :inactive-value="0"
              ></van-switch>
              <span
                class="is-size-7"
                style="
                  line-height: 20px;
                  line-height: 35px;
                  vertical-align: top;
                  padding-left: 10px;
                "
                >{{
                  !newTopic.public
                    ? 'Show only to my Friends'
                    : 'Visible to everyone'
                }}</span
              >
            </div>
            <div>
              <label class="label">Color</label>
              <div style="text-align: left">
                <v-swatches
                  v-model="newTopic.color"
                  popover-x="right"
                  style="text-align: left"
                ></v-swatches>
              </div>
            </div>

            <hr />
            <div>
              <b-button type="is-primary is-light" @click="saveNewTopic">
                <i class="far fa-check"></i> Save New Topic
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="forumTopic"
      animation="fade"
      :active.sync="isForumTopicModalActive"
      :width="540"
      full-screen
      scroll="keep"
    >
      <ForumModalTopic :id="forum_topic_id" />
    </b-modal>
    <p style="display: none">
      <small>
        <i>Press and hold [shift] to select text</i>
      </small>
    </p>
  </div>
</template>

<script>
import anime from 'animejs'
import ApiService from '@/services/ApiService'
import ForumTopicModal from '@/components/modals/ForumTopicModal.vue'
import VSwatches from 'vue-swatches'

export default {
  name: 'Forum',
  components: {
    // SwipeOut,
    // SwipeList,
    VSwatches,
  },
  data() {
    return {
      selection: 'All',
      showPicker: false,
      enabled: true,
      page: 0,
      loading: false,
      revealed: {},
      lastEventDescription: '',
      isNewTopicModalActive: false,
      isForumTopicModalActive: false,
      forum_topic_id: 0,
      sections: [
        'All',
        'General',
        'Style',
        'Health',
        'Workouts',
        'Self Development',
      ],
      forum_sections: [
        { text: 'All', value: 'all' },
        { text: 'General', value: 'general' },
        { text: 'Style', value: 'style' },
        { text: 'Health', value: 'health' },
        { text: 'Workouts', value: 'workouts' },
        { text: 'Self Development', value: 'self_development' },
      ],
      forumItems: [],
      newTopic: {
        title: '',
        section: 'general',
        description: '',
        public: 1,
      },
    }
  },
  mounted() {
    this.forumItems = this.$store.state.general.temp_forum_items
    this.getForumTopics()
    // ideally should be in some global handler/store
    // window.addEventListener('keydown', this.onKeyDown)
    // window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    // window.removeEventListener('keydown', this.onKeyDown)
    // window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    getForumTopics() {
      this.loading = true
      ApiService.forum.getForumTopics().then((resp) => {
        this.forumItems = resp.data
        this.$nextTick(() => {
          anime({
            targets: '.forumContentBlock',
            opacity: 1,
            delay: anime.stagger(40),
          })
        })

        this.loading = false
        this.$store.dispatch('setTempForum', resp.data.slice(0, 15))
      })
    },
    saveNewTopic() {
      ApiService.forum
        .saveNewTopic(this.newTopic)
        .then(() => {
          this.$notify({
            message: 'Saved!',
            type: 'success',
          })
          this.isNewTopicModalActive = false
          this.getForumTopics()
          this.newTopic = {
            title: '',
            section: 'general',
            description: '',
            public: 1,
          }
        })
        .catch((err) => {
          alert(err)
        })
    },
    onConfirm(value) {
      this.selection = value.text
      this.$nextTick(() => {
        anime({
          targets: '.forumContentBlock',
          opacity: 1,
          delay: anime.stagger(40),
        })
      })
    },
    remove(item) {
      this.$set(
        this.forumItems,
        this.page,
        this.forumItems[this.page].filter((i) => i !== item)
      )
    },
    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id,
      }
    },
    titleEmoji(emoji) {
      this.newTopic.title += emoji.data
    },
    itemClick(item) {
      console.log('teim', item)
      let modalOptions = {
        parent: this,
        hasModalCard: true,
        customClass: 'custom-class',
        width: 500,
        props: item,
        animation: 'fade',
        component: ForumTopicModal,
      }
      this.$buefy.modal.open(modalOptions)
    },
    openNewTopicModal() {
      this.isNewTopicModalActive = true
    },
    // keyboard
  },
}
</script>
<style scoped>
.forumContentBlock {
  padding: 8px;
  padding-left: 7px;
  /* margin-bottom: 1px; */
  margin-left: 2px;
  margin-right: 2px;
  background: white;
  opacity: 0;
  /* border-bottom: 1px solid #ddd; */
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.forumContentBlock:last-child {
  border-bottom: 1px solid #eeeeee;
}
.bottomIcons span {
  padding-right: 10px;
  color: #acb1ba;
  /* opacity: 0.7; */
  font-size: 0.75rem;
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #f0f0f0;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.5rem;
  border: 1px solid #f4f4f4;
  /* margin-bottom: 5px; */
}
.entryTitle {
  font-weight: 400 !important;
  font-size: 14.5px !important;
}
.pinButton {
  margin: 5px;
  padding: 10px;
  color: #616161;
  height: 65px;
  border: none;
}
.pinButton i {
  font-size: 22px;
}
.timeago {
  color: #bbbbbb !important;
  line-height: 28px !important;
}
hr {
  height: 1px;
  opacity: 0.5;
}
div.bgWrapper {
  /* background: #ededed; */
  border-bottom: 1px solid #eeeeee;
}
</style>
