<template>
  <div
    class="card is-clearfix modalInside modalBg"
    style="padding-top: 1px; padding-bottom: 20px; opacity: 1"
  >
    <div class="hero" style="padding-top: 1px">
      <h4 class="title is-4"><span v-if="!tempHabitObj.id">New</span><span v-if="tempHabitObj.id">Edit</span> <small class="headerSmall">Habit</small></h4>
    </div>
    <div v-if="!showDetails" style="height: 82vh">
      <div style="height: 90%; overflow-y: auto">
        <div style="padding: 10px">
          <b-input v-model="search" placeholder="Search habits"></b-input>
        </div>
        <div>
          <HabitsSection
            text="Mind"
            icon="fas fa-brain"
            background="#ffffff"
            v-show="!showSection || showSection == 'mind'"
            @click.native="toggleSection('mind')"
          />
          <HabitsSection
            text="Health"
            icon="fas fa-heart"
            background="#ffd1c6"
            iconColor="#ff4c21"
            v-show="!showSection || showSection == 'health'"
            @click.native="toggleSection('health')"
          />
          <HabitsSection
            text="Productivity"
            icon="fas fa-cogs"
            background="#c6ffd0"
            iconColor="#29983d"
            v-show="!showSection || showSection == 'productivity'"
            @click.native="toggleSection('productivity')"
          />
          <HabitsSection
            text="Relationships"
            icon="fas fa-user-friends"
            background="#f9c6ff"
            iconColor="#a431af"
            v-show="!showSection || showSection == 'relationships'"
            @click.native="toggleSection('relationships')"
          />
          <HabitsSection
            text="Money & Career"
            icon="fas fa-coins"
            background="#fffdc6"
            iconColor="#ff9900"
            v-show="!showSection || showSection == 'money & career'"
            @click.native="toggleSection('money & career')"
          />
        </div>
        <div v-if="showSection">
          <HabitsSetionHabit
            v-for="(item, index) in sectionHabits"
            :item="item"
            :key="index"
            @click.native="prefillHabit(item)"
          />
        </div>
      </div>
      <div style="height: 10%; padding-top: 20px; border-top: 1px solid #ccc">
        <b-button
          type="is-warning is-light"
          style="padding: 20px 30px"
          @click="showDetails = true"
          ><i class="fas fa-plus"></i> Create New Habit</b-button
        >
      </div>
    </div>
    <div class v-if="showDetails">
      <div id="topSelections" class="columns is-mobile">
        <div class="column">
          <label class="label">Icon color</label>
          <v-swatches
            v-model="tempHabitObj.options.color"
            popover-x="right"
          ></v-swatches>
        </div>
        <div class="column">
          <label class="label">Icon</label>
          <IconPicker
            :current-icon="tempHabitObj.options.icon"
            @selectIcon="selectIcon"
          />
        </div>
        <div class="column">
          <label class="label">Background</label>
          <v-swatches
            v-model="tempHabitObj.options.background"
            :swatches="swatches"
            popover-x="left"
          ></v-swatches>
        </div>
      </div>

      <div class="is-clearfix">
        <b-field label="Habit Name" style="display: block">
          <b-input
            v-model="tempHabitObj.title"
            placeholder="Habit name"
            required
            maxlength="30"
          ></b-input>
        </b-field>
        <div>
          <label class="label">Preview</label>
          <SingleHabit :item="tempHabitObj" />
        </div>
      </div>
      <label class="label">Time of day</label>
      <van-dropdown-menu class="height42dropdown">
        <van-dropdown-item
          v-model="tempHabitObj.section"
          :options="habit_sections"
        />
      </van-dropdown-menu>
      <b-field
        label="About the Habit"
        style="display: block; position: relative"
      >
        <b-input
          v-model="tempHabitObj.description"
          type="text"
          maxlength="50"
          placeholder="Short Description"
        ></b-input>
        <!-- <b-input
                v-model="tempHabitObj.description"
                type="textarea"
                minlength="50"
                maxlength="50"
                placeholder="Description"
                style="min-height: 50px;"
                  ></b-input>-->
      </b-field>
      <div style="min-height: 400px">
        <div style="margin-bottom: 15px">
          <label class="label">Repeat</label>
          <SheduleType
            v-model="tempHabitObj.schedule"
            :hideRange="true"
            :initial="tempHabitObj.schedule"
            @updated="setSchedule"
          />
        </div>
        <div
          v-if="tempHabitObj.schedule === 'weekday'"
          class
          style="padding: 0px"
        >
          <label class="label">Select Weekday</label>
          <WeekdaysSelection
            :selected="tempHabitObj.schedule_options.weekdays"
            :disabled="[]"
            @onSelected="updateWeekdays($event)"
          />
        </div>
        <div>
          <hr />
          <b-button
            type="is-primary is-light"
            :disabled="!tempHabitObj.title"
            @click="saveHabit"
          >
            <i class="fas fa-check"></i> Save Habit
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VSwatches from 'vue-swatches'
import IconPicker from '@/components/IconPicker'
import SheduleType from '@/components/SheduleType.vue'
import WeekdaysSelection from '@/components/WeekdaysSelection.vue'
import ApiService from '@/services/ApiService'
import SwatchesColors from '@/constants/colors.json'
import SingleHabit from '@/components/habits/SingleHabit'

import HabitsSection from '@/components/HabitsSection'
import HabitsSetionHabit from '@/components/HabitsSetionHabit'
import HabitsList from '@/constants/habits.json'

export default {
  name: 'HabitModal',
  components: {
    VSwatches,
    IconPicker,
    SheduleType,
    SingleHabit,
    HabitsSection,
    WeekdaysSelection,
    HabitsSetionHabit,
  },
  props: {
    habitObj: Object,
  },
  data() {
    return {
      tempHabitObj: {
        options: {
          color: '#222f3d',
          background: '#f9f9f9',
          icon: 'fas fa-check',
        },
        section: 'anytime',
        schedule: 'same',
        schedule_options: 'same',
      },
      habit_sections: [
        { text: 'Anytime', value: 'anytime' },
        { text: 'Morning', value: 'morning' },
        { text: 'Afternoon', value: 'afternoon' },
        { text: 'Evening', value: 'evening' },
      ],
      search: '',
      selectedWeekday: null,
      showDetails: false,
      showSection: '',
      swatches: SwatchesColors,
    }
  },
  watch: {
    habitObj: function () {
      this.tempHabitObj = this.habitObj
    },
  },
  mounted() {
    this.tempHabitObj = this.habitObj
    if(this.tempHabitObj.id){
      this.showDetails = true;
    }
  },
  computed: {
    sectionHabits: function () {
      if (!this.showSection) {
        return []
      } else {
        const sections = HabitsList.filter(
          (itm) => itm.section === this.showSection
        )
        return sections[0].habits
      }
    },
  },
  methods: {
    updateWeekdays(selection) {
      const index =
        this.tempHabitObj.schedule_options.weekdays.indexOf(selection)
      if (index === -1) {
        this.tempHabitObj.schedule_options.weekdays = [
          ...this.tempHabitObj.schedule_options.weekdays,
          selection,
        ]
      } else {
        this.tempHabitObj.schedule_options.weekdays.splice(index, 1)
      }
      this.$forceUpdate()
    },
    setSchedule(type) {
      this.tempHabitObj.schedule = type
      if (type === 'same') {
        this.tempHabitObj.schedule_options = 'same'
      } else if (type === 'weekday') {
        this.tempHabitObj.schedule_options = {
          weekdays: [],
        }
      }
    },
    selectIcon(icon) {
      this.tempHabitObj.options.icon = icon
    },
    saveHabit() {
      console.log('hab', this.tempHabitObj)
      ApiService.habits.saveUserHabit(this.tempHabitObj).then(() => {
        this.$emit('close')
      })
    },
    toggleSection(newSection) {
      if (newSection == this.showSection) {
        this.showSection = ''
      } else {
        this.showSection = newSection
      }
    },
    prefillHabit(item) {
      this.tempHabitObj.title = item.t
      this.tempHabitObj.options.icon = item.i
      this.showDetails = true
    },
  },
}
</script>