import dbConnection from '../dbConnection'
const admin = {
  getPosts: function () {
    return dbConnection.get('admin/posts/all')
  },
  createPost: function (payload) {
    return dbConnection.post('admin/posts/create', payload)
  },
  getType: function (type) {
    return dbConnection.get('admin/get/' + type + '/all')
  }
}
export default admin
