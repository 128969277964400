import { render, staticRenderFns } from "./PostModal.vue?vue&type=template&id=03a5f8d3&scoped=true&"
import script from "./PostModal.vue?vue&type=script&lang=js&"
export * from "./PostModal.vue?vue&type=script&lang=js&"
import style0 from "./PostModal.vue?vue&type=style&index=0&id=03a5f8d3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a5f8d3",
  null
  
)

export default component.exports