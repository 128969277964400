<template>
  <section
    style="max-width: 500px; margin: auto; box-shadow: #cccccc 0px 0px 12px"
  >
    <div style="padding: 10px; text-align: left">
      <div :key="index" v-for="(itm, index) in dataBlocks">
        <div
          v-if="itm.multi"
          style="padding: 0px 0px 0px 15px; background: #fff7f2; margin: 0px"
        >
          <van-tag type="warning">{{
            itm.items.length > 3 ? 'Circuit' : 'Superset'
          }}</van-tag>
          <SingleExercise
            :key="index2"
            v-for="(itm2, index2) in itm.items"
            :item="itm2"
            :showCheckbox="true"
            @toggleComplete="toggleComplete(index, index2)"
            :completed="completed[index] && completed[index][index2]"
          />
        </div>
        <SingleExercise
          v-if="!itm.multi"
          :item="itm"
          :showCheckbox="true"
          :completed="completed[index]"
          @toggleComplete="toggleComplete(index)"
        />
      </div>
    </div>
    <div class="actionDiv pb-5 pt-5">
      <b-button type="is-success" @click="finish()"
        ><i class="fal fa-thumbs-up"></i> Finish workout!</b-button
      >
    </div>
  </section>
</template>
<script>
// import ApiService from '@/services/ApiService'
import SingleExercise from '@/components/SingleExercise'
export default {
  name: 'CompactView',
  data() {
    return {
      completed: {},
    }
  },
  components: {
    SingleExercise,
  },
  props: {
    dataBlocks: Array,
  },
  mounted() {},

  methods: {
    onConfirm(value) {
      this.view = value.text
    },
    toggleComplete(index, index2) {
      if (index2 == undefined) {
        this.completed[index] = this.completed[index] ? false : true
      } else {
        if (!this.completed[index]) {
          this.completed[index] = {}
        }
        this.completed[index][index2] = this.completed[index][index2]
          ? false
          : true
      }
      this.$forceUpdate()
    },
    finish() {
      this.$emit('whenFinished', {})
      return
    },
  },
}
</script>