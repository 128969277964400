<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero">
      <h4 class="title is-4">Helpful Habits  <small class="headerSmall">Health & Wellbeing</small></h4>
    </div>
    <section>
      <div>
          
      </div>
    </section>
  </div>
</template>

<script>
// import ApiService from '@/services/ApiService'

export default {
  name: 'HealthHabits',
  components: {
      
  },
  data() {
    return {
        
    }
  },
  created() {
      
  },
  methods: {
      
  }
}
</script>
