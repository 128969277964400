<template>
  <div class="container" style="overflow: hidden">
    <div class="hero">
      <h4 class="title is-4">
        {{ selectedStats.title }} <small class="headerSmall">Stats</small>
      </h4>
    </div>

    <div class="columns is-centered addSection">
      <div class="column is-paddingless">
        <vue-frappe
          v-if="data.length > 0"
          id="test"
          :labels="labels"
          :type="chartType"
          :height="240"
          :colors="['purple', '#ffa3ef', 'light-blue']"
          :dataSets="this.data"
          :axisOptions="{ xIsSeries: true }"
        >
        </vue-frappe>
        <!-- <div id="chart" :style="{ width: chartWidth + 'px' }"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
// import 'frappe-charts/dist/frappe-charts.min.css'
import ApiService from '@/services/ApiService'
import { VueFrappe } from 'vue2-frappe'
export default {
  name: 'JournalStatsSection',
  props: {
    selectedStats: {
      type: Object,
      default: function () {
        return { title: '' }
      },
    },
  },
  components: {
    VueFrappe,
  },
  data() {
    return {
      selected: null,
      newTitle: '',
      chartWidth: 200,
      chartType: 'pie',
      labels: [],
      data: [],
    }
  },
  mounted() {
    setTimeout(() => {
      this.getData()
    }, 500)
  },
  methods: {
    getData() {
      ApiService.journal
        .getStats(this.selectedStats.template_id, this.selectedStats.type)
        .then((resp) => {
          const data = resp.data
          if (this.selectedStats.type === 'mood') {
            this.labels = ['Awful', 'Bad', 'Meh', 'Good', 'Great']
            this.data = [
              {
                name: 'Data',
                chartType: 'pie',
                values: [0, 0, 0, 0, 0],
              },
            ]
            for (let index = 0; index < data.length; index++) {
              this.data[0].values[parseInt(data[index].value) - 1] =
                data[index].count
            }
            this.$forceUpdate()
            console.log('dat', this.data)
          } else if (this.selectedStats.type === 'yesno') {
            this.labels = ['Yes', 'No']
            this.data = [
              {
                name: 'Data',
                chartType: 'pie',
                values: [0, 0],
              },
            ]
            if (data.length > 0) {
              if (data[0].value == '1') {
                this.data[0].values[1] = data[0].count
              } else {
                this.data[0].values[0] = data[0].count
              }
              if (data.length > 1 && data[1].value == '3') {
                this.data[0].values[0] = data[1].count
              } else {
                this.data[0].values[1] = data[1].count
              }
              this.$forceUpdate()
            }
          } else if (this.selectedStats.type === 'number') {
            let labels = []
            let values = []
            let tempDate = ''
            data.forEach((element) => {
              tempDate = element.date.split('-')
              labels.push(tempDate[2] + '-' + tempDate[1])
              values.push(element.value)
            })
            this.labels = labels
            this.chartType = 'line'
            this.data = [
              {
                name: this.selectedStats.title,
                // chartType: 'line',
                values: values,
                axisOptions: { hideDots: 1 },
              },
            ]
          }
          console.log(resp.data)
        })
      console.log('grs', this.selectedStats)
      // this.labels = ['12am-3am', '3am-6am', '6am-9am', '9am-12pm']
      // this.data = [
      //   {
      //     name: 'Yes',
      //     chartType: 'bar',
      //     values: [25, 40, 30, 35],
      //   },
      //   {
      //     name: 'No',
      //     chartType: 'bar',
      //     values: [15, 20, 10, 25],
      //   },
      // ]

      // this.chartWidth = Math.floor(Math.random() * 400) + 200
      this.$nextTick(() => {
        // this.updateChart(data)
      })
    },
    updateChart() {
      // this.chart = new Chart('#chart', {
      //   // or a DOM element,
      //   // new Chart() in case of ES6 module with above usage
      //   data: data,
      //   type: 'pie', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
      //   height: 250,
      //   colors: ['#7cd6fd', '#743ee2'],
      // })
    },
    select(type) {
      this.selected = type
    },
  },
}
</script>
<style lang="scss" scoped>
#chart {
  min-width: 100%;
}
</style>
