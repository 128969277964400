<template>
  <div class="wrap">
    <b-button
      type="is-default"
      :class="{ active: type == 'same' }"
      @click="setSchedule('same')"
      >Same Every Day</b-button
    >
    <!-- <b-button
      v-if="!hideRange"
      type="is-default"
      :class="{ active: type == 'day_range' }"
      @click="setSchedule('day_range')"
      >Day Range</b-button
    > -->
    <b-button
      v-if="!hideRange"
      type="is-default"
      :class="{ active: type == 'days' }"
      @click="setSchedule('days')"
      >Days</b-button
    >
    <b-button
      type="is-default"
      :class="{ active: type == 'weekday' }"
      @click="setSchedule('weekday')"
      >By Weekday</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'SheduleType',
  props: {
    initial: String,
    hideRange: Boolean,
  },
  data() {
    return {
      type: this.initial,
    }
  },
  methods: {
    setSchedule(type) {
      if (this.type === type) {
        return
      } else if (this.type && !this.hideRange) {
        this.$dialog
          .confirm({
            message:
              'Switching to different type might clear entered habits below. Continue ?',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          })
          .then(() => {
            this.type = type
            this.sendEmit()
            // on cancel
          })
        return
      }
      this.type = type
      this.sendEmit()
    },
    sendEmit() {
      this.$emit('input', this.type)
      this.$emit('updated', this.type)
    },
  },
}
</script>
<style scoped lang="scss">
button.is-default {
  font-size: 12px;
  padding: 15px;
}
button.is-default.active {
  background: #f7b875;
  color: white;
}
</style>
