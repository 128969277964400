<template>
  <div id="screenPage">
    <div class="hero">
      <h4 class="title is-4" style="padding-left: 10px">
        Journal <small class="headerSmall">{{ selectedText }}</small>
        <van-tag
          v-if="filter"
          class=""
          style="vertical-align: middle; margin-left: 4px"
          closeable
          size="medium"
          type="primary"
          @click="clearFilter"
          ><i class="fas fa-filter" style="margin-right: 7px"></i>
          {{ filter }}
        </van-tag>
      </h4>
    </div>

    <DaysWrapper
      :dates="dates"
      :selected="selected"
      @selected="selectDate"
      @loadMoreDays="loadMoreDays"
      :filter="filter"
      type="pastDatesOnly"
    />
    <hr class="small" />
    <transition name="fade-slow">
      <div
        v-if="loadedItems"
        class="content"
        style="max-width: 1000px; margin: auto"
      >
        <div
          v-for="(item, $index) in items"
          :key="$index"
          class="singleItem"
          :class="{ empty: !item.value && !item.text && !item.image }"
        >
          <JournalMood
            v-if="item.type === 'mood' || item.type === 'emoji'"
            :item="item"
            @update="update($event, $index)"
            @action="action($event, $index)"
          />
          <JournalYesNo
            v-if="item.type === 'yesno'"
            :item="item"
            @update="update($event, $index)"
            @action="action($event, $index)"
          />
          <JournalText
            v-if="item.type === 'text'"
            :item="item"
            @update="update($event, $index)"
            @action="action($event, $index)"
          />
          <JournalImage
            v-if="item.type === 'image'"
            :item="item"
            @update="update($event, $index)"
            @action="action($event, $index)"
          />
          <JournalNumber
            v-if="item.type === 'number'"
            :item="item"
            @update="update($event, $index)"
            @action="action($event, $index)"
          />
        </div>
        <br />
        <b-button size="is-dotdot" class="is-size-6" @click="showAddNew = true"
          >+ Add a section/module!</b-button
        >
      </div>
    </transition>
    <van-popup
      v-model="showStats"
      closeable
      close-icon="f fas fa-times"
      position="bottom"
      :style="{ height: '300px' }"
    >
      <JournalStatsSection v-if="showStats" :selected-stats="selectedStats" />
    </van-popup>
    <van-popup
      v-model="showActionsPopup"
      closeable
      :overlay="true"
      close-icon="f fas fa-times"
      position="bottom"
      get-container="body"
      :style="{ height: '110px', padding: '10px', 'text-align': 'center' }"
    >
      <circular-button
        title="Show previous entries"
        icon="fas fa-filter"
        background="#f9f9f9"
        color="#FFA500"
        @click.native="menu_action('show_prev_entries')"
      />
      <circular-button
        v-if="items[selectedItemIndex] && items[selectedItemIndex].id"
        title="Remove this entry"
        icon="fas fa-times"
        background="#f9f9f9"
        color="#f77d4d"
        @click.native="menu_action('remove_entry')"
      />
      <circular-button
        title="Hide it from future entries"
        icon="fas fa-eye-slash"
        background="#f9f9f9"
        color="#000000"
        @click.native="menu_action('hide')"
      />
    </van-popup>
    <van-popup
      v-model="showAddNew"
      closeable
      close-icon="f fas fa-times"
      position="bottom"
      :style="{ height: windowWidth > 600 ? '250px' : '60%' }"
    >
      <JournalAddSection @addSection="addSection" />
    </van-popup>
  </div>
</template>

<script>
import DateHelper from '@/services/dateHelper'
import ApiService from '@/services/ApiService'
import DaysWrapper from '@/components/habits/DaysWrapper'
import JournalMood from '@/components/journal/JournalMood'
import JournalYesNo from '@/components/journal/JournalYesNo'
import JournalText from '@/components/journal/JournalText'
import JournalNumber from '@/components/journal/JournalNumber'
import JournalImage from '@/components/journal/JournalImage'
import JournalAddSection from '@/components/journal/JournalAddSection'
import JournalStatsSection from '@/components/journal/JournalStatsSection'
import CircularButton from '@/components/CircularButton'
export default {
  name: 'Habits',
  components: {
    DaysWrapper,
    JournalMood,
    JournalYesNo,
    JournalText,
    JournalAddSection,
    JournalNumber,
    JournalImage,
    CircularButton,
    JournalStatsSection,
    // SwipeOut,
    // SwipeList
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      await this.saveJournalDay()
      next()
    } else {
      next()
    }
  },
  data() {
    return {
      newHabbit: {},
      dates: [],
      items: [
        { type: 'mood', value: 1, title: 'How are you feeling today ?' },
        { type: 'yesno', value: 1, title: 'Was it a good day ?' },
        { type: 'text', value: 'wtf', title: 'About this day ?' },
        { type: 'number', value: 76, title: 'About this number ?' },
        { type: 'image', value: 'wtf', title: 'About this day ?', image: '' },
      ],
      showAddNew: false,
      showStats: false,
      filter: null,
      loadedItems: false,
      showActionsPopup: false,
      selected: '',
      selectedText: '',
      selectedStats: {},
      dayLabels: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      activeTab: 0,
      editHabitId: 0,
      checkbox: '',
      dirty: false,
      selectedItemIndex: -1,
      // currentWeekDay: -1,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    allHabbits() {
      return this.positiveHabits.concat(this.negativeHabits)
    },
  },
  created() {
    this.addDays()
  },
  mounted() {
    this.getJournalDay(this.selected)
  },
  methods: {
    addDays() {
      const dateObj = DateHelper.initialDates(21, 1)
      this.dates = dateObj.dates
      this.selected = dateObj.selected
      this.selectedText = dateObj.selectedText
      this.getFilledDates(
        this.dates[0].fullDate,
        this.dates[this.dates.length - 1].fullDate
      )
    },
    getFilledDates(beginningDate, endDate) {
      ApiService.journal.getFilledDates(beginningDate, endDate).then((resp) => {
        const filledDates = resp.data
        if (filledDates) {
          let newDates = JSON.parse(JSON.stringify(this.dates))
          newDates.map((itm) => {
            if (filledDates.indexOf(itm.fullDate) > -1) {
              itm.status = 3
            }
          })
          this.dates = newDates
        }
      })
    },
    loadMoreDays(date) {
      const newDates = DateHelper.addDates(date, [...this.dates])
      this.dates = newDates
      this.getFilledDates(newDates[0].fullDate, date)
    },
    addFilteredDays(dates) {
      this.dates = []
      const dateObj = DateHelper.filteredDays(dates)
      console.log('ham', dateObj)
      this.dates = dateObj.dates
      this.selected = dateObj.selected
      this.selectedText = dateObj.selectedText
      this.getJournalDay(this.selected)
    },
    selectDate(dateObj) {
      if (this.dirty) {
        this.saveJournalDay(dateObj.date)
      } else {
        this.selected = dateObj.date
        this.selectedText = DateHelper.convertDateToText(dateObj.date)
        this.getJournalDay(dateObj.date)
      }
    },
    getDayNames(year, month) {
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      var daysInMonth = new Date(year, month, 0).getDate()
      for (let i = 1; i <= daysInMonth; i++) {
        var d = new Date(year, month, i)
        var dayName = days[d.getDay()]
        console.log(i + ' ' + dayName)
      }
    },
    toggleDailyCheck(habbitId, day) {
      if (this.habitsChecked[habbitId] && this.habitsChecked[habbitId][day]) {
        this.habitsChecked[habbitId][day] = false
      } else {
        this.habitsChecked[habbitId][day] = true
        console.log(this.habitsChecked)
      }
      this.$forceUpdate()
    },
    update(obj, index) {
      console.log('OBJ', obj)
      let singleObj = this.items[index]
      singleObj[obj.field] = obj.value
      singleObj.dirty = true
      this.$set(this.items, index, singleObj, true)
      this.dirty = true
    },
    action(selection, index) {
      if (selection.type && selection.type === 'menu') {
        this.showActionsPopup = true
        this.selectedItemIndex = index
      } else if (selection.type && selection.type === 'stats') {
        console.log('Selection', selection)
        console.log('Selection item', this.items[index])
        this.selectedStats = this.items[index]
        this.showStats = true
      }
    },
    menu_action(type) {
      if (type === 'hide') {
        this.hideTemplate(this.items[this.selectedItemIndex].template_id)
      } else if (type === 'remove_entry') {
        this.removeEntry(this.items[this.selectedItemIndex].id)
      } else if (type === 'show_prev_entries') {
        this.filterDays(this.items[this.selectedItemIndex].template_id)
      }
    },
    getJournalDay(date) {
      this.items = []
      this.loadedItems = false
      ApiService.journal
        .getJournalDay(date)
        .then((resp) => {
          let combined = resp.data.data
          let templates = resp.data.templates
          templates.forEach((tmpl) => {
            if (
              combined.filter((itm) => itm.template_id == tmpl.id).length == 0
            ) {
              tmpl.value = null
              tmpl.text = null
              tmpl.image = null
              tmpl.template_id = tmpl.id
              tmpl.id = null
              combined.push(tmpl)
            }
          })
          console.log('Combined', combined)
          this.items = combined
          this.loadedItems = true
        })
        .catch((err) => {
          console.log(err)
          this.loadedItems = true
        })
    },
    saveJournalDay(newDate) {
      this.$store.dispatch('loading', true)
      ApiService.journal
        .saveJournalDay(this.items, this.selected)
        .then(() => {
          this.dirty = false
          this.$store.dispatch('loading', false)
          this.$notify({ type: 'success', message: 'Saved', duration: 1000 })
          if (newDate) {
            this.selected = newDate
            this.getJournalDay(newDate)
          }
        })
        .catch(() => this.$store.dispatch('loading', false))
    },
    removeEntry(id) {
      this.showActionsPopup = false
      this.$store.dispatch('loading', true)
      ApiService.journal
        .removeEntry(id)
        .then(() => {
          this.getJournalDay(this.selected)
        })
        .catch(() => this.$store.dispatch('loading', false))
    },
    hideTemplate(templateId) {
      ApiService.journal.hideJournalTemplate(templateId).then(() => {
        this.$notify({ type: 'success', message: 'Removed', duration: 1000 })
        this.getJournalDay(this.selected)
      })
    },
    addSection(obj) {
      console.log('new Section', obj)
      ApiService.journal.saveNewJournalTemplate(obj).then(() => {
        obj.value = null
        obj.text = null
        obj.image = null
        this.items.push(obj)
      })
    },
    filterDays(templateId) {
      ApiService.journal.filterDays(templateId).then((resp) => {
        this.showActionsPopup = false
        if (resp.data.length > 0) {
          this.filter = this.items[this.selectedItemIndex].title
          setTimeout(() => {
            this.addFilteredDays(resp.data)
          }, 400)
        } else {
          this.$notify({
            type: 'warning',
            message: "You don't have any content yet.",
            duration: 2000,
          })
        }
      })
    },
    clearFilter() {
      this.addDays()
      this.filter = null
      this.getJournalDay(this.selected)
    },
  },
}
</script>
<style scoped>
div.singleItem {
  transition: all 0.3;
  opacity: 1;
}
div.singleItem.empty {
  opacity: 0.6;
  filter: grayscale(50%);
}

h2.from {
  border-bottom: 1px solid #f9f9f9;
}
small.is-size-9 {
  font-size: 9px;
  color: rgb(220, 220, 220);
}
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  left: 0;
  border: 1px solid #bbb;
  border-left: 0px;
  background: #fafafa;
}
.card-content {
  padding: 0.75rem;
  border: 1px solid #e3e3e3;
  /* margin-bottom: 5px; */
}
figure.image {
  margin-right: 15px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}
div.dailyDiv {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
div.dailyDiv .column span.active {
  font-weight: bold;
  color: #167df0;
}
.dailyDiv .columns .column {
  border-right: 1px solid #f2f2f2;
}
div.dailyRow {
  border-bottom: 1px solid #f2f2f2;
}
.tabs li.is-active span {
  font-weight: bold;
}
.positiveHeader,
.negativeHeader {
  color: red;
  font-weight: 500;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
  margin-bottom: 10px;
  margin-bottom: 0px;
}
.positiveHeader {
  color: green;
  border-right: 1px solid #f2f2f2;
  margin-bottom: 0px;
}
.habitsWrapper > div:first-child {
  border-top: 0;
}
#positiveBlock {
  border-right: 1px solid #f2f2f2;
}
.habbitsBlock {
  position: relative;
  border-top: 1px solid #f2f2f2;
  padding: 10px;
}
.habbitsBlock button {
  display: none;
  color: red;
  font-weight: bold;
  font-size: 15px;
  background: none;
  border: none;
}
.habbitsBlock:hover button {
  display: inline;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.habbitsBlock.active button {
  display: inline;
  position: absolute;
  right: 0;
}
.container {
  padding: 0 10px;
}
.fa-check {
  color: green;
}
@media only screen and (max-width: 500px) {
  .dailyDiv {
    margin-left: -5px;
    margin-right: -5px;
  }
  .dailyDiv .columns .column {
    padding: 15px 0px;
  }
}
.habitsWrapper .habbitsBlock {
  padding: 5px;
}
.icons i {
  font-size: 48px;
}
</style>
