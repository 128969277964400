<template>
  <div class="feed container" style="background: #FFFFFF">
    <div class="hero"><h4 class="title is-4">Personal</h4></div>
    <section>
      <div>
        <transition-group
          name="fade"
          mode="out-in"
          tag="div"
          class="columns is-centered is-multiline contentBlocks"
        >
          <Tile
            v-for="item in items"
            :key="item.id"
            :item="item"
            class="column is-one-third"
            @click.native="$parent.openModal(item)"
          />
        </transition-group>
      </div>
    </section>
  </div>
</template>
<script>
import Tile from '@/components/tiles/Tile'
import ApiService from '@/services/ApiService'
import { updatePosts } from '@/helpers/PostHelper'

export default {
  name: 'Personal',
  components: {
    Tile
    // MembersTemplate
  },
  data() {
    return {
      items: [],
      currentPage: 0,
      subcategory: 'personal'
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    getPosts() {
      ApiService.posts
        .getPostsSubCategory(this.subcategory, this.currentPage)
        .then((response) => {
          var items = response.data.slice(0, 20)
          items = updatePosts(items)
          this.items = items
        })
    },
  },
}
</script>
