const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const initialDates = (daysBefore, daysAhead) => {
  let now = new Date()
  let now2 = new Date()
  let dates = []

  for (var i = 0; i < daysBefore; i++) {
    now.setDate(now.getDate() - 1) // sets the date to the previous day each time
    dates.unshift({
      fullDate: now.toISOString().split('T')[0],
      day: now.getDate(),
      wday: now.getDay(),
      month: now.getMonth(),
      label: dayLabels[now.getDay()]
    })
  }
  dates.push({
    fullDate: now2.toISOString().split('T')[0],
    day: now2.getDate(),
    wday: now2.getDay(),
    month: now2.getMonth(),
    label: dayLabels[now2.getDay()],
    selected: true,
    today: true
  })
  const selected = now2.toISOString().split('T')[0]
  const selectedWeekday = now2.getDay();
  for (var y = 0; y < daysAhead; y++) {
    now2.setDate(now2.getDate() + 1) // sets the date to the previous day each time
    dates.push({
      fullDate: now2.toISOString().split('T')[0],
      day: now2.getDate(),
      wday: now2.getDay(),
      month: now2.getMonth(),
      label: dayLabels[now2.getDay()]
    })
  }
  const selectedText = new Date().toLocaleString('en-GB', { month: 'long', day: 'numeric' })

  return {
    dates,
    selected,
    selectedText,
    selectedWeekday
  }
}

const filteredDays = (datesArray) => {
  let now = new Date()
  let dates = []

  for (var i = 0; i < datesArray.length; i++) {
    now = new Date(datesArray[i]) // sets the date to the previous day each time
    dates.unshift({
      fullDate: now.toISOString().split('T')[0],
      day: now.getDate(),
      wday: now.getDay(),
      month: now.getMonth(),
      label: dayLabels[now.getDay()]
    })
  }
  const selected = dates.length > 0 ? dates[dates.length - 1].fullDate : ''
  const selectedWeekday = dates.length > 0 ? dates[dates.length - 1].wday : ''

  const selectedText = dates.length > 0 ? new Date(dates[dates.length - 1].fullDate).toLocaleString('en-GB', { month: 'long', day: 'numeric' }) : ''


  return {
    dates,
    selected,
    selectedText,
    selectedWeekday
  }
}

const convertDateToText = (date) => {
  return new Date(date).toLocaleString('en-GB', { month: 'long', day: 'numeric' })
}

const addDates = (lastDate, allDates) => {
  const splitDate = lastDate.split('-')
  const dateObj = new Date(splitDate[0], splitDate[1] - 1, splitDate[2], 15)
  for (var i = 0; i < 30; i++) {
    dateObj.setDate(dateObj.getDate() - 1) // sets the date to the previous day each time
    allDates.unshift({
      fullDate: dateObj.toISOString().split('T')[0],
      day: dateObj.getDate(),
      wday: dateObj.getDay(),
      month: dateObj.getMonth(),
      label: dayLabels[dateObj.getDay()]
    })
  }
  return allDates
}

export default { initialDates, addDates, convertDateToText, filteredDays }
